import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getFeedback } from "../../../services/feedback.service";
import "./feedback-container.scss";

const Feedback = () => {
	const [feedback, setFeedback] = useState<any[]>([]);
	const [filteredFeedback, setFilteredFeedback] = useState<any[]>([]);
	const [users, setUsers] = useState<any[]>([]);
	const [sections, setSections] = useState<any[]>([]);
	const [selectedUserFilter, setSelectedUserFilter] = useState("");
	const [selectedSectionFilter, setSelectedSectionFilter] = useState("");

	const navigate = useNavigate();

	useEffect(() => {
		getFeedback(null).then((res: any) => {
			setFeedback(res.data);
			setFilteredFeedback(res.data);

			const distinctSections = new Set(res.data.map((item: any) => item.sectionName));
			setSections(Array.from(distinctSections));

			const distinctUsers = Array.from(
				new Map(res.data.map((item: any) => [item.user.id, item.user])).values()
			);
			setUsers(distinctUsers);
		});
	}, []);

	useEffect(() => {
		let filtered = feedback;

		if (selectedUserFilter) {
			filtered = filtered.filter((item: any) => item.user.id === selectedUserFilter);
		}

		if (selectedSectionFilter) {
			filtered = filtered.filter((item: any) => item.sectionName === selectedSectionFilter);
		}

		setFilteredFeedback(filtered);
	}, [selectedUserFilter, selectedSectionFilter, feedback]);

	const handleOpenTab = (reportId: string) => {
		window.open(`/reports/v2/${reportId}`, "_blank", "noopener,noreferrer");
	};

	return (
		<>
			<div className="table-container">
				<button className="back-btn" onClick={() => navigate("/admin")}>
					Back
				</button>

				<div className="header-controls">
					<div className="filter-section">
						<label>User</label>
						<select
							className="filter-select"
							value={selectedUserFilter}
							onChange={(e: any) => setSelectedUserFilter(e.target.value)}
						>
							<option value="">All</option>
							{users
								?.sort((a: any, b: any) => a.firstName.localeCompare(b.firstName))
								.map((x: any) => (
									<option key={x.id} value={x.id}>
										{x.firstName} {x.lastName}
									</option>
								))}
						</select>
					</div>

					<div className="filter-section">
						<label>Section</label>
						<select
							className="filter-select"
							value={selectedSectionFilter}
							onChange={(e: any) => setSelectedSectionFilter(e.target.value)}
						>
							<option value="">All</option>
							{sections
								?.sort((a: any, b: any) => a.localeCompare(b))
								.map((section: any) => (
									<option key={section} value={section}>
										{section}
									</option>
								))}
						</select>
					</div>
				</div>

				<table className="styled-table">
					<thead>
						<tr>
							<th>Created On</th>
							<th>User</th>
							<th>Report Id</th>
							<th>Params</th>
							<th>Section</th>
							<th>Feedback</th>
							<th></th>
						</tr>
					</thead>
					<tbody>
						{filteredFeedback
							?.sort(
								(a: any, b: any) =>
									new Date(b.createdOn).getTime() - new Date(a.createdOn).getTime()
							)
							.map((row: any) => (
								<tr key={row.id}>
									<td>{new Date(row.createdOn + "Z").toLocaleString()}</td>
									<td>
										{row.user.firstName} {row.user.lastName}
									</td>
									<td>{row.reportId}</td>
									<td>
										{row.userParams
											.filter((x: any) => x.selected !== "All")
											.map((item: any) => `${item.name}: ${item.selected}`)
											.join(", ")}
									</td>
									<td>{row.sectionName}</td>
									<td>{row.feedbackValue}</td>
									<td>
										<button onClick={() => handleOpenTab(row.reportId)}>Go to Report</button>
									</td>
								</tr>
							))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Feedback;
