import { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import DetailsContainer from "../../../components/DetailsContainer/";
import DriverBlock from "../../../components/DriverBlock";
import ExecSumParagraph from "../../../components/ExecSumParagraph";
import FeedbackComponent from "../../../components/FeedbackComponent";
import Loader from "../../../components/Loader";
import ParentAccordion from "../../../components/ParentAccordion";
import ReportTitle from "../../../components/ReportTitle";
import SectionTitle from "../../../components/SectionTitle";
import Sources from "../../../components/Sources";
import SsrmTreeDataTable from "../../../components/SsrmTreeDataTable/SsrmTreeDataTable";
import SubsectionBlock from "../../../components/SubsectionBlock";
import SummarySentance from "../../../components/SummarySentance";
import SummarySentanceBullet from "../../../components/SummarySentanceBullet";
import {
	IFeedback,
	IFeedbackItem,
	IReportBubble,
	IReportData,
	IUserParams,
} from "../../../Interfaces/reports";
import {
	ITabularDataMetadata,
	ITransformedTableData,
} from "../../../Interfaces/tabular-data";
import { addEvent } from "../../../services/event.service";
import { addReportFeedback } from "../../../services/feedback.service";
import {
	getReportChildrenDataApi,
	getReportData,
} from "../../../services/report.service";
import {
	transformColumnDefinitions,
	transformRows,
} from "../../../utils/ag-grid/ag-grid-transformers";
import "./report-container.scss";

const Report = () => {
	const { reportId } = useParams();
	const navigate = useNavigate();

	const [showAnalysis, setShowAnalysis] = useState(false);
	const [showMasterDataTable, setShowMasterDataTable] = useState(false);
	const [userParamsFilterBubbles, setUserParamsFilterBubbles] = useState<
		IReportBubble[]
	>([]);

	// Feedback boxes array
	const [feedbackValues, setFeedbackValues] = useState<IFeedbackItem[]>([
		{
			section: "overall_summary",
			value: "",
			clicked: false,
		},
		{
			section: "sales_gp_summary",
			value: "",
			clicked: false,
		},
		{
			section: "sales_waterfall",
			value: "",
			clicked: false,
		},
		{
			section: "sales_table",
			value: "",
			clicked: false,
		},
		{
			section: "gp_waterfall",
			value: "",
			clicked: false,
		},
		{
			section: "gp_table",
			value: "",
			clicked: false,
		},
		{
			section: "performance_comp_summary",
			value: "",
			clicked: false,
		},
		{
			section: "performance_comparison_table",
			value: "",
			clicked: false,
		},
		{
			section: "breakdown_comp_summary",
			value: "",
			clicked: false,
		},
		{
			section: "breakdown_table_summary",
			value: "",
			clicked: false,
		},
		{
			section: "product_and_store_block",
			value: "",
			clicked: false,
		},
		{
			section: "promotions",
			value: "",
			clicked: false,
		},
		{
			section: "promotions_table",
			value: "",
			clicked: false,
		},
		{
			section: "new/deleted_skus",
			value: "",
			clicked: false,
		},
		{
			section: "new/deleted_skus_table",
			value: "",
			clicked: false,
		},
		{
			section: "store_ranging",
			value: "",
			clicked: false,
		},
		{
			section: "store_ranging_table",
			value: "",
			clicked: false,
		},
		{
			section: "new/closed_stores",
			value: "",
			clicked: false,
		},
		{
			section: "new/closed_stores_table",
			value: "",
			clicked: false,
		},
		{
			section: "stock",
			value: "",
			clicked: false,
		},
		{
			section: "stock_table",
			value: "",
			clicked: false,
		},
		{
			section: "channel_and_customer_block",
			value: "",
			clicked: false,
		},
		{
			section: "channel",
			value: "",
			clicked: false,
		},
		{
			section: "channel_table",
			value: "",
			clicked: false,
		},
		{
			section: "customer",
			value: "",
			clicked: false,
		},
		{
			section: "customer_table",
			value: "",
			clicked: false,
		},
		{
			section: "master_data_table",
			value: "",
			clicked: false,
		},
	]);

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [titleSection, setTitleSection] = useState<IReportData | null>(null);
	const [summarySection, setSummarySection] = useState<IReportData | null>(
		null
	);
	const [pvmSection, setPVMSection] = useState<any>(null);
	const [upwardComparisonSection, setUpwardComparisonSection] = useState<{
		title: string;
		section: IReportData | null;
		pin: boolean | null;
		showDetails: boolean | null;
		details: ITransformedTableData | null;
		metadata: ITabularDataMetadata | null;
		tableTitle: string | null;
	} | null>(null);
	const [breakdownSection, setBreakdownSection] = useState<any>(null);
	const [performanceDriversSection, setPerformanceDriversSection] =
		useState<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				if (!reportId || reportId == null) {
					navigate("/");
					return;
				}

				const response = await getReportData(reportId);
				const result = response.reportData;

				buildHeaderBubbles(response.userParams);
				setTitleSection(getSection(result, "Title"));
				setSummarySection(getSection(result, "SummarySection"));
				buildPVMSection(result);

				setUpwardComparisonSection({
					title: "Performance Comparison",
					section: getSection(result, "UpwardComparison"),
					showDetails: false,
					pin: false,
					details: buildTableData(result, "comparison"),
					metadata: getSection(result, "comparison")?.metadata ?? null,
					tableTitle: getTextContentSection(
						getSection(result, "comparison"),
						"TableTitle"
					)?.value,
				});

				let breakdownHeadline = getTextContentSection(
					getSection(result, "Breakdown"),
					"Headline"
				).value;
				setBreakdownSection({
					title: "Breakdown", // + (breakdownHeadline != null ? ` (${breakdownHeadline})` : ''),
					headline: breakdownHeadline,
					section: getSection(result, "Breakdown"),
					pin: false,
					showDetails: false,
					details: buildTableData(result, "breakdown_section"),
					metadata: getSection(result, "breakdown_section")?.metadata ?? null,
					tableTitle: getTextContentSection(
						getSection(result, "breakdown_section"),
						"TableTitle"
					)?.value,
					breakdownUserParam: response.userParams.map((param: any) => {
						return param.name === "Breakdown" ? param.selected : null;
					}),
				});

				buildPerformanceDriversSection(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, []);

	// Helper function to render feedback boxes
	const renderFeedbackBoxes = (section: string) => {
		return feedbackValues
			.filter((feedback: any) => feedback.section === section)
			.map((feedback: any) => {
				const { section: feedbackSection, value, clicked } = feedback;

				if (section === feedbackSection) {
					return (
						<FeedbackComponent
							key={section}
							onClick={() => {
								handleFeedbackSubmit({
									section: section,
									feedback: value,
								});
								setFeedbackValues((prev: any) =>
									prev.map((fb: any) =>
										fb.section === section ? { ...fb, clicked: true } : fb
									)
								);
							}}
							textState={value}
							textOnChange={(newValue: string) =>
								setFeedbackValues((prev: any) =>
									prev.map((fb: any) =>
										fb.section === section ? { ...fb, value: newValue } : fb
									)
								)
							}
							clicked={clicked}
							className={
								section === "overall_summary" ? "feedback_parent" : "feedback"
							}
						/>
					);
				}

				return;
			});
	};

	const buildHeaderBubbles = (userParams: IUserParams[]) => {
		const bubbles: IReportBubble[] = [];
		userParams
			.filter(
				(param) =>
					param.selected.toLocaleLowerCase() !== "all" &&
					param.name.toLocaleLowerCase() !== "breakdown" &&
					param.name.toLocaleLowerCase() !== "to_week" &&
					param.name.toLocaleLowerCase() !== "from_week" &&
					param.name.toLocaleLowerCase() !== "ref_from_week" &&
					param.name.toLocaleLowerCase() !== "ref_to_week" &&
					param.name.toLocaleLowerCase() !== "ref_year" &&
					param.name.toLocaleLowerCase() !== "year"
			)
			.forEach((param) => {
				bubbles.push({
					label: param.name,
					text: param.selected,
					icon: "analyse",
				});
			});

		const refFromWeekBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "ref_from_week"
		);

		const refToWeekBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "ref_to_week"
		);

		const refYearBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "ref_year"
		);

		if (refFromWeekBubble && refToWeekBubble && refYearBubble) {
			const transformedYear =
				refYearBubble.selected === "TY" ? "This Year" : "Last Year";

			bubbles.push({
				label: "Time",
				text: `Week ${refFromWeekBubble.selected} - Week ${refToWeekBubble.selected} ${transformedYear}`,
				icon: "time",
			});
		}

		const fromWeekBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "from_week"
		);

		const toWeekBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "to_week"
		);

		const yearBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "year"
		);

		if (fromWeekBubble && toWeekBubble && yearBubble) {
			const transformedYear =
				yearBubble.selected === "TY" ? "This Year" : "Last Year";

			bubbles.push({
				label: "To",
				text: `Week ${fromWeekBubble.selected} - Week ${toWeekBubble.selected} ${transformedYear}`,
				icon: "time",
			});
		}

		const breakdownBubble = userParams.find(
			(param) => param.name.toLocaleLowerCase() === "breakdown"
		);

		if (breakdownBubble) {
			bubbles.push({
				label: breakdownBubble.name,
				text: breakdownBubble.selected,
				icon: "breakdown",
			});
		}

		setUserParamsFilterBubbles(bubbles);
	};

	const getReportChildrenData = async (parentId: string) => {
		try {
			const response = await getReportChildrenDataApi(reportId!, parentId);
			return response;
		} catch (error) {
			console.error("Error fetching children:", error);
			return [];
		}
	};

	const handleFeedbackSubmit = async (params: {
		section: string;
		feedback: string;
	}) => {
		const feedback: IFeedback = {
			reportId: reportId!,
			sectionName: params.section,
			feedbackValue: params.feedback,
		};

		await addReportFeedback(feedback).then(() => {
			toast.success("Feedback submitted successfully!");
		});
	};

	const getSection = (result: IReportData[], sectionName: string) => {
		return result == null
			? null
			: result.filter((x: IReportData) => x.name === sectionName) != null
			? result.filter((x: IReportData) => x.name === sectionName)[0]
			: null;
	};

	const getTextContentSection = (item: any, section: string) => {
		return item?.textContent?.filter((x: any) => x.key == section)[0];
	};

	const buildPVMSection = (result: IReportData[]) => {
		let section = getSection(result, "PVMSection");
		let salesData = JSON.parse(
			getTextContentSection(section, "Sales KPIs Data").value.replaceAll(
				"'",
				'"'
			)
		);
		let gpData = JSON.parse(
			getTextContentSection(section, "GP KPIs Data").value.replaceAll("'", '"')
		);
		let builtPVMSection = [
			{
				metrics: [
					{
						...getTextContentSection(section, "Sales KPIs[0]"),
						variation:
							salesData.left.value - salesData.right.value < 0 ? "+" : "-",
					},
					{
						...getTextContentSection(section, "Sales KPIs[1]"),
						variation: salesData.asp.value > 0 ? "+" : "-",
					},
					{
						...getTextContentSection(section, "Sales KPIs[2]"),
						variation: salesData.volume.value > 0 ? "+" : "-",
					},
				],
				bullets: [getTextContentSection(section, "Sales KPIs Body").value],
				title: "Sales",
				heading: getTextContentSection(section, "Sales KPIs Headline").value,
				sectionSubtitle: "Sales Breakdown (comparison period to focus period)",
				showDetails: false,
				pin: false,
				dataTitle: salesData.title,
				chart: "sales",
				topData: [
					buildPVMChartBar(salesData.left, "white", "grey"),
					buildPVMChartBar(salesData.asp),
					buildPVMChartBar(salesData.volume, "red"),
					buildPVMChartBar(salesData.right, "white", "grey"),
				],
				bottomData: [
					buildPVMChartBar(salesData.left, "white", "grey"),
					buildPVMChartBar(
						salesData.asp.breakdown.price,
						"white",
						null,
						getSection(result, "waterfall_sales_price")
					),
					buildPVMChartBar(
						salesData.asp.breakdown.mix,
						"white",
						null,
						getSection(result, "waterfall_sales_mix")
					),
					buildPVMChartBar(
						salesData.volume,
						"black",
						null,
						getSection(result, "waterfall_sales_volume")
					),
					buildPVMChartBar(salesData.right, "white", "grey"),
				],
			},
			{
				metrics: [
					{
						...getTextContentSection(section, "GP KPIs[0]"),
						variation:
							gpData.left.value - salesData.right.value < 0 ? "+" : "-",
					},
					{
						...getTextContentSection(section, "GP KPIs[1]"),
						variation: gpData.margin.breakdown.cogs.value > 0 ? "+" : "-",
					},
					{
						...getTextContentSection(section, "GP KPIs[2]"),
						variation: gpData.margin.value > 0 ? "+" : "-",
					},
				],
				bullets: [getTextContentSection(section, "GP KPIs Body").value],
				title: "GP",
				heading: getTextContentSection(section, "GP KPIs Headline").value,
				sectionSubtitle: "GP$ Breakdown (comparison period to focus period)",
				showDetails: false,
				pin: false,
				dataTitle: gpData.title,
				topData: [
					buildPVMChartBar(gpData.left, "white", "grey"),
					buildPVMChartBar(gpData.sales),
					buildPVMChartBar(gpData.margin),
					buildPVMChartBar(gpData.right, "white", "grey"),
				],
				data: gpData,
				chart: "gp",
				bottomData: [
					buildPVMChartBar(gpData.left, "white", "grey"),
					buildPVMChartBar(
						gpData.sales,
						"white",
						null,
						getSection(result, "waterfall_gp_sales")
					),
					buildPVMChartBar(
						gpData.margin.breakdown.cogs,
						"white",
						null,
						getSection(result, "waterfall_gp_cogs")
					),
					buildPVMChartBar(
						gpData.margin.breakdown.price,
						"black",
						null,
						getSection(result, "waterfall_gp_price")
					),
					buildPVMChartBar(
						gpData.margin.breakdown.mix,
						"black",
						null,
						getSection(result, "waterfall_gp_mix")
					),
					buildPVMChartBar(gpData.right, "white", "grey"),
				],
			},
		];

		setPVMSection(builtPVMSection);
	};

	const buildPVMChartBar = (
		chartBar: any,
		indexLabelFontColor: string | null = null,
		color: string | null = null,
		details: any = null
	) => {
		return {
			title: chartBar.title,
			subtitle: chartBar.subtitle,
			y: chartBar.value,
			indexLabel: chartBar.value_formatted,
			indexLabelFontColor: indexLabelFontColor ?? "white",
			color,
			details,
			tableTitle: getTextContentSection(details, "TableTitle")?.value,
		};
	};

	const buildPerformanceDriversSection = (result: any) => {
		let section = [
			{
				title: "Product and Store",
				blocks: [
					buildPerformanceDriversBlock(
						result,
						"PromoDeepdive",
						"promotion",
						"Promotions",
						false,
						false
					),
					buildPerformanceDriversBlock(
						result,
						"NewSKUsRangeDeepDive",
						"newdeleted_skus",
						"New/Deleted SKUs",
						false,
						false
					),
					buildPerformanceDriversBlock(
						result,
						"RangeChangeRangeDeepDive",
						"store_ranging",
						"Store Ranging",
						false,
						false
					),
					buildPerformanceDriversBlock(
						result,
						"NewStoreRangeDeepRangeDive",
						"new_closed_stores",
						"New/Closed Stores",
						false,
						false
					),
					buildPerformanceDriversBlock(
						result,
						"StockDeepDive",
						"stock",
						"Stock",
						false,
						false
					),
				],
			},
			{
				title: "Channel and Customer",
				blocks: [
					buildPerformanceDriversBlock(
						result,
						"ChannelDeepdive",
						"channel",
						"Channel",
						false,
						false
					),
					buildPerformanceDriversBlock(
						result,
						"AgeDeepdive",
						"generation",
						"Customer",
						false,
						false
					),
				],
			},
		];

		setPerformanceDriversSection(section);
	};

	const buildPerformanceDriversBlock = (
		result: any,
		textSectionName: string,
		dataSectionName: string,
		title: any = null,
		showDetails: boolean,
		pin: boolean
	) => {
		return {
			...getSection(result, textSectionName),
			title,
			details: buildTableData(result, dataSectionName),
			metadata: getSection(result, dataSectionName)?.metadata,
			tableTitle: getTextContentSection(
				getSection(result, dataSectionName),
				"TableTitle"
			)?.value,
			showDetails,
			pin,
		};
	};

	const pinPVM = (sectionIndex: number) => {
		setPVMSection((prevSections: any) =>
			prevSections.map((section: any, index: any) =>
				index === sectionIndex ? { ...section, pin: !section.pin } : section
			)
		);
	};

	const pinComparison = () => {
		setUpwardComparisonSection((prevState: any) => {
			return {
				...prevState,
				pin: !prevState?.pin,
			};
		});
	};

	const pinBreakdown = () => {
		setBreakdownSection((prevState: any) => {
			return {
				...prevState,
				pin: !prevState?.pin,
			};
		});
	};

	const pinPerfDriver = (sectionIndex: number, subSectionIndex: number) => {
		setPerformanceDriversSection((prev: any) => {
			return prev.map((section: any, index: number) => {
				if (sectionIndex === index) {
					return {
						...section,
						blocks: section.blocks.map((block: any, blockIndex: number) => {
							if (blockIndex === subSectionIndex) {
								return {
									...block,
									pin: !block.pin,
								};
							}
							return block;
						}),
					};
				}
				return section;
			});
		});
	};

	const expandFunction = (
		state: any,
		switchState: boolean,
		sectionIndex: number | null = null,
		subSectionIndex: number | null = null,
		pin: boolean | null | undefined
	) => {
		collapseReport();

		state((prevSections: any) => {
			return sectionIndex == null
				? {
						...prevSections,
						showDetails: switchState,
				  }
				: prevSections.map((section: any, index: any) => {
						return index === sectionIndex
							? subSectionIndex == null
								? {
										...section,
										showDetails: switchState,
								  }
								: {
										...section,
										blocks: section?.blocks?.map(
											(subSection: any, subIndex: any) =>
												subIndex === subSectionIndex
													? {
															...subSection,
															showDetails: switchState,
													  }
													: subSection
										),
								  }
							: section;
				  });
		});
	};

	const collapseReport = () => {
		setPVMSection((prevSections: any) => {
			return prevSections.map((section: any, index: any) => {
				return {
					...section,
					showDetails: section.pin ? section.showDetails : false,
				};
			});
		});
		setBreakdownSection((prevState: any) => ({
			...prevState,
			showDetails: prevState.pin ? prevState.showDetails : false,
		}));

		setUpwardComparisonSection((prevState: any) => ({
			...prevState,
			showDetails: prevState.pin ? prevState.showDetails : false,
		}));

		setPerformanceDriversSection((prev: any) => {
			return prev.map((section: any, sectionIndex: number) => {
				return {
					...section,
					blocks: section.blocks?.map((block: any, newBlockIndex: number) => {
						return {
							...block,
							showDetails: block.pin ? block.showDetails : false,
						};
					}),
				};
			});
		});
	};

	const buildTableData = (
		result: IReportData[],
		section: string
	): ITransformedTableData | null => {
		let data = getSection(result, section);
		return data == null
			? null
			: {
					transformedRows: transformRows(data),
					transformedColumns: transformColumnDefinitions(data),
			  };
	};

	const scrollToElementWithOffset = (element: any) => {
		if (!element) return;
		setTimeout(() => {
			const elementPosition =
				element.getBoundingClientRect().top + window.scrollY;
			const offsetPosition = elementPosition - 110;
			requestAnimationFrame(() => {
				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			});
		}, 1);
	};

	const recordEvent = (event: any) => {
		addEvent({ ...event, reportId }).then((_) => _);
	};

	return (
		<div className="report">
			{loading ? (
				<Loader size={100}></Loader>
			) : (
				<>
					<div className="report__header">
						<div className="report-demo__header-top">
							<div className="report__logo" />
							<div className="report__watermark" />
						</div>
						<div className="report__header-bottom">
							{/* {userParamsFilterBubbles.length > 0 && (
								<div className="report__header-bottom">
									{userParamsFilterBubbles.map((bubble, index) => (
										<div className="report__summary" key={index}>
											<div className="report__summary-label">{bubble.label}</div>
											<ReportNavBubble text={bubble.text} icon={bubble.icon} />
										</div>
									))}
								</div>
							)} */}
						</div>
					</div>
					<div className="report__content">
						{titleSection && titleSection != null ? (
							<ReportTitle
								text={getTextContentSection(titleSection, "Title").value}
								subtitle={getTextContentSection(titleSection, "Subtitle").value}
							/>
						) : (
							""
						)}
						<div className="report__content-exec-summary">
							{summarySection &&
							summarySection != null &&
							summarySection.textContent
								? summarySection.textContent
										.filter((x) => x.key != null || x.value != null)
										.map((para: any, index: any) => {
											return (
												<div key={"sum-" + index}>
													<ExecSumParagraph
														para={para}
														key={"sum-par-" + index}
													/>
												</div>
											);
										})
								: ""}
							<Sources number={6} />
						</div>
						{renderFeedbackBoxes("overall_summary")}

						<ParentAccordion
							onClick={() => {
								setShowAnalysis((prev) => !prev);
								const element = document.getElementById(`analysisHeading`);
								scrollToElementWithOffset(element);
							}}
							id={`analysisHeading`}
							text="Analysis"
						/>

						{showAnalysis && (
							<div className="report-analysis">
								<SectionTitle
									title={"Sales and GP$ Breakdown"}
									pinDisplay={"none"}
								/>
								<div className="report-analysis__section">
									{pvmSection && pvmSection != null
										? pvmSection.map((x: any, index: any) => {
												return (
													<SubsectionBlock
														title={x.title}
														subtitle={x.heading}
														onClick={() => {
															expandFunction(
																setPVMSection,
																!x.showDetails,
																index,
																null,
																x.pin
															);
															const element = document.getElementById(
																`pvmSection${index}`
															);
															scrollToElementWithOffset(element);
														}}
														pin={x.pin}
														id={`pvmSection${index}`}
														showDetails={x.showDetails}
														pinOnClick={() => {
															pinPVM(index);
															const element = document.getElementById(
																`pvmSection${index}`
															);
															scrollToElementWithOffset(element);
														}}
													>
														<div key={index}>
															{x.showDetails && (
																<DetailsContainer
																	title={x.dataTitle}
																	titleStyle={{ backgroundColor: "#F6F7F7" }}
																	reportId={reportId}
																	detailsHeading={x.sectionSubtitle}
																	detailsSubheading={""}
																	dataPointsTop={x.topData}
																	dataPointsBottom={x.bottomData}
																	chart={x.chart}
																	tableTitle={x.tableTitle}
																	renderFeedback={renderFeedbackBoxes}
																/>
															)}
														</div>
													</SubsectionBlock>
												);
										  })
										: ""}
									{renderFeedbackBoxes("sales_gp_summary")}
								</div>

								<SectionTitle
									title={upwardComparisonSection?.title}
									onClick={() => {
										pinComparison();
										const element =
											document.getElementById(`comparisonSection`);
										scrollToElementWithOffset(element);
									}}
									pin={
										upwardComparisonSection
											? upwardComparisonSection.pin
											: false
									}
									id={`upwardComparisonSection`}
									pinDisplay={"none"}
								/>
								<div className="report-analysis__section">
									<SubsectionBlock
										title={"Comparison"}
										style={{
											backgroundColor:
												upwardComparisonSection?.section != null
													? getTextContentSection(
															upwardComparisonSection.section,
															"Highlight Headline"
													  )?.value.toUpperCase() == "TRUE"
														? "rgba(255, 200, 0, 0.35)"
														: ""
													: "",
										}}
										subtitle={upwardComparisonSection?.section?.textContent}
										onClick={() => {
											expandFunction(
												setUpwardComparisonSection,
												!upwardComparisonSection?.showDetails,
												null,
												null,
												upwardComparisonSection?.pin
											);
											const element =
												document.getElementById(`comparisonSection`);
											scrollToElementWithOffset(element);
										}}
										pin={
											upwardComparisonSection
												? upwardComparisonSection.pin
												: false
										}
										id={`comparisonSection`}
										showDetails={upwardComparisonSection?.showDetails}
										pinOnClick={() => {
											pinComparison();
											const element =
												document.getElementById(`comparisonSection`);
											scrollToElementWithOffset(element);
										}}
									>
										{upwardComparisonSection?.showDetails &&
											upwardComparisonSection?.details &&
											upwardComparisonSection?.section && (
												<div className="table-container">
													<SsrmTreeDataTable
														title={upwardComparisonSection?.tableTitle}
														textSection={{
															title: upwardComparisonSection?.title,
															description: `${upwardComparisonSection?.section?.textContent?.map(
																(content: any, index: number) => {
																	return content.value;
																}
															)}`,
														}}
														columnDefs={
															upwardComparisonSection.details.transformedColumns
														}
														rowData={
															upwardComparisonSection.details.transformedRows
														}
														domLayout="autoHeight"
														rowModelType="serverSide"
														pagination={true}
														paginationPageSize={20}
														paginationPageSizeSelector={[10, 20, 50, 100]}
														paginateChildRows={true}
														rowGroupPanelShow="none"
														enableCharts={true}
														enableRangeSelection={true}
														serverSideSortAllLevels={true}
														suppressAggFuncInHeader={true}
														treeData={true}
														groupDisplayType="singleColumn"
														isServerSideGroup={true}
														metadata={upwardComparisonSection.metadata}
														loadChildrenApiCall={getReportChildrenData}
													/>
												</div>
											)}
										{renderFeedbackBoxes("performance_comparison_table")}
									</SubsectionBlock>
									{renderFeedbackBoxes("performance_comp_summary")}
								</div>

								<SectionTitle
									title={breakdownSection?.title}
									onClick={() => {
										pinBreakdown();
										const element = document.getElementById(`breakdownSection`);
										scrollToElementWithOffset(element);
									}}
									pin={breakdownSection ? breakdownSection.pin : false}
									id={`breakdownSection`}
									pinDisplay={"none"}
								/>
								<div className="report-analysis__section">
									<SubsectionBlock
										title={breakdownSection?.breakdownUserParam}
										subtitle={
											breakdownSection?.headline || "Breakdown Headline"
										}
										onClick={() => {
											expandFunction(
												setBreakdownSection,
												!breakdownSection.showDetails,
												null,
												null,
												breakdownSection.pin
											);
											const element =
												document.getElementById(`breakdownSection`);
											scrollToElementWithOffset(element);
										}}
										pin={breakdownSection ? breakdownSection.pin : false}
										id={`breakdownSection`}
										showDetails={breakdownSection.showDetails}
										pinOnClick={() => {
											pinBreakdown();
											const element =
												document.getElementById(`breakdownSection`);
											scrollToElementWithOffset(element);
										}}
										style={{
											backgroundColor:
												breakdownSection?.section != null
													? getTextContentSection(
															breakdownSection.section,
															"Highlight Headline"
													  )?.value.toUpperCase() == "TRUE"
														? "rgba(255, 200, 0, 0.35)"
														: ""
													: "",
										}}
									>
										{breakdownSection?.section != null &&
										breakdownSection.showDetails ? (
											<>
												{getTextContentSection(
													breakdownSection.section,
													"Body Sales"
												)?.value && (
													<h3 style={{ marginTop: "24px" }}>Sales</h3>
												)}
												<SummarySentanceBullet
													sentance={
														getTextContentSection(
															breakdownSection.section,
															"Body Sales"
														)?.value
													}
												/>
												<br />

												{getTextContentSection(
													breakdownSection.section,
													"Body GP$"
												)?.value && <h3 style={{ marginTop: "24px" }}>GP$</h3>}
												<SummarySentanceBullet
													sentance={
														getTextContentSection(
															breakdownSection.section,
															"Body GP$"
														)?.value
													}
												/>
												<br />
												{getTextContentSection(
													breakdownSection.section,
													"Body Margin"
												)?.value && <h3 style={{ marginTop: "24px" }}>GP%</h3>}
												<SummarySentanceBullet
													sentance={
														getTextContentSection(
															breakdownSection.section,
															"Body Margin"
														)?.value
													}
												/>

												{breakdownSection?.showDetails &&
													breakdownSection?.details &&
													breakdownSection?.section && (
														<div className="table-container">
															<SsrmTreeDataTable
																title={breakdownSection.tableTitle}
																textSection={{
																	title: breakdownSection?.title,
																	description: `
																	${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body Sales"
																		)?.value
																			? `<h3 style="margin-top: 24px;">Sales</h3>`
																			: ""
																	}
																	<p>${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body Sales"
																		)?.value || ""
																	}</p>
																	${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body GP$"
																		)?.value
																			? `<h3 style="margin-top: 24px;">GP$</h3>`
																			: ""
																	}
																	<p>${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body GP$"
																		)?.value || ""
																	}</p>
																	${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body Margin"
																		)?.value
																			? `<h3 style="margin-top: 24px;">GP%</h3>`
																			: ""
																	}
																	<p>${
																		getTextContentSection(
																			breakdownSection.section,
																			"Body Margin"
																		)?.value || ""
																	}</p>
																`,
																}}
																columnDefs={
																	breakdownSection.details.transformedColumns
																}
																rowData={
																	breakdownSection.details.transformedRows
																}
																domLayout="autoHeight"
																rowModelType="serverSide"
																pagination={true}
																paginationPageSize={20}
																paginationPageSizeSelector={[10, 20, 50, 100]}
																paginateChildRows={true}
																rowGroupPanelShow="none"
																enableCharts={true}
																enableRangeSelection={true}
																serverSideSortAllLevels={true}
																suppressAggFuncInHeader={true}
																treeData={true}
																groupDisplayType="singleColumn"
																isServerSideGroup={true}
																metadata={breakdownSection.metadata}
																loadChildrenApiCall={getReportChildrenData}
															/>
														</div>
													)}

												{renderFeedbackBoxes("breakdown_table_summary")}
											</>
										) : (
											""
										)}
									</SubsectionBlock>
									{renderFeedbackBoxes("breakdown_comp_summary")}
								</div>

								<SectionTitle
									title={"Performance Drivers"}
									pinDisplay={"none"}
								/>
								<br />
								{performanceDriversSection &&
									performanceDriversSection != null &&
									performanceDriversSection.map(
										(driver: any, index: number) => {
											const { title, blocks } = driver;

											return (
												<div
													key={"pdc-" + index}
													className="report-analysis__section drivers"
												>
													<div key={index}>
														<SectionTitle
															title={title}
															style={{
																marginLeft: "-25px",
																backgroundColor: "#F6F7F7",
															}}
															pinDisplay={"none"}
														/>
														<br />
														<br />
														{blocks
															.filter((x: any) => x.details != null)
															.map((block: any, blockIndex: number) => {
																var title = block?.title;
																let body = getTextContentSection(
																	block,
																	"Body"
																).value;
																let variationDirection =
																	getTextContentSection(
																		block,
																		"Highlight Headline"
																	)?.value.toUpperCase() == "TRUE"
																		? ""
																		: null;
																let subtitle = getTextContentSection(
																	block,
																	"Headline"
																).value;

																const {
																	// paragraph,
																	details,
																	showDetails,
																} = block;

																return (
																	<div
																		key={"pdc-" + index + "block-" + blockIndex}
																	>
																		<DriverBlock
																			id={
																				"pdc-" + index + "block-" + blockIndex
																			}
																			title={title}
																			subtitle={subtitle}
																			variationDirection={variationDirection}
																			showDetails={showDetails}
																			onClick={() => {
																				expandFunction(
																					setPerformanceDriversSection,
																					!block.showDetails,
																					index,
																					blockIndex,
																					block.pin
																				);
																				const element = document.getElementById(
																					"pdc-" + index + "block-" + blockIndex
																				);
																				scrollToElementWithOffset(element);
																			}}
																			pinOnClick={() => {
																				pinPerfDriver(index, blockIndex);
																				const element = document.getElementById(
																					"pdc-" + index + "block-" + blockIndex
																				);
																				scrollToElementWithOffset(element);
																			}}
																			pin={block.pin}
																		>
																			<div className="driver-block__details">
																				<SummarySentance sentance={body} />
																			</div>

																			{/* Add summary name here */}
																			{renderFeedbackBoxes(
																				`${title
																					.toLowerCase()
																					.replace(/ /g, "_")}`
																			)}

																			{block && block.details != null ? (
																				<SsrmTreeDataTable
																					title={block.tableTitle}
																					textSection={{
																						title: title,
																						description: body,
																					}}
																					columnDefs={
																						block.details.transformedColumns
																					}
																					metadata={block.metadata}
																					rowData={
																						block.details.transformedRows
																					}
																					domLayout={"autoHeight"}
																					rowModelType={"serverSide"}
																					loadChildrenApiCall={
																						getReportChildrenData
																					}
																					pagination={true}
																					paginationPageSize={20}
																					paginationPageSizeSelector={[
																						10, 20, 50, 100,
																					]}
																					paginateChildRows={true}
																					rowGroupPanelShow={"none"}
																					enableCharts={true}
																					enableRangeSelection={true}
																					serverSideSortAllLevels={true}
																					suppressAggFuncInHeader={true}
																					treeData={true}
																					groupDisplayType={"singleColumn"}
																				/>
																			) : (
																				""
																			)}

																			{renderFeedbackBoxes(
																				`${title
																					.toLowerCase()
																					.replace(/ /g, "_")}_table`
																			)}
																		</DriverBlock>
																	</div>
																);
															})}

														{renderFeedbackBoxes(
															`${title
																.toLowerCase()
																.replace(/ /g, "_")
																.replace(/,/g, "")}_block`
														)}
													</div>
												</div>
											);
										}
									)}
							</div>
						)}

						<ParentAccordion
							onClick={() => {
								setShowMasterDataTable((prev) => !prev);
								const element = document.getElementById(
									`masterDataTableHeading`
								);
								scrollToElementWithOffset(element);
							}}
							id={`masterDataTableHeading`}
							text="Master Data Table"
						/>
						{/* {showMasterDataTable && (
							<>
								<MasterTable />
								{renderFeedbackBoxes("master_data_table")}
							</>
						)} */}
						<div className="report__testing">TESTING ONLY</div>
					</div>
				</>
			)}
		</div>
	);
};

export default Report;
