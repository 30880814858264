import React, { useEffect, useState, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-hot-toast";

import "./users-container.scss";
import { getAllUsers, registerUser, switchUser, updateUser } from "../../../services/user.service";
import Popup from "../../../components/Popup";
import { getAllOrganisations } from "../../../services/organisation.service";

const Users = () => {
	const [users, setUsers]: any[] = useState();
	const [trigger, setTrigger] = useState(0);
	const [editingUser, setEditingUser] = useState<any>(null);

	useEffect(() => {
		const fetchSelectOptions = async () => {
			try {
				getAllOrganisations().then(res => {
					let options = res.data.map((x: any) => ({ label: x.name, value: x.id }));

					setFormFields((prevFields) =>
						prevFields.map((field) =>
							field.name === "organisationId"
								? { ...field, options: options, value: options.length == 1 ? options[0].value : "" }
								: field
						)
					);
				})


			} catch (error) {
				console.error("Error fetching select options:", error);
			}
		};

		fetchSelectOptions();
	}, []); // Run this effect once when the component mounts

	useEffect(() => {
		getAllUsers().then((res: any) => {
			let sorted = res.data.sort((a: any, b: any) => {
				const orgComparison = a.organisationName?.localeCompare(b.organisationName);
				if (orgComparison === 0) {
					return a.firstName.localeCompare(b.firstName);
				}
				return orgComparison;
			});
			setUsers(sorted);
		});

		return () => {
		};
	}, [trigger]);

	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [loading, setLoading] = useState(false);
	const [formFields, setFormFields] = useState([
		{ id: 1, name: "firstName", label: "First Name", type: "text", value: "", required: true },
		{ id: 2, name: "lastName", label: "Last Name", type: "text", value: "", required: true },
		{ id: 3, name: "username", label: "Email", value: "", type: "text", required: true },
		{ id: 4, name: "organisationId", label: "Organisation", value: "", type: "select", options: [], required: true },
		{ id: 5, name: "role", label: "Role", value: "User", type: "select", options: [{ label: "User", value: "User" }, { label: "Admin", value: "Admin" }], required: true },
		{ id: 6, name: "password", label: "Password", type: "text", value: "", required: false },
	]);

	const handleInputChange = (id: any, value: any) => {
		setFormFields((prevFields) =>
			prevFields.map((field) =>
				field.id === id ? { ...field, value } : field
			)
		);
	};

	const handleOnClose = () => {
		setFormFields((prevFields) =>
			prevFields.map((field) => ({ ...field, value: "" }))
		);
		setIsPopupOpen(false);
		setEditingUser(null);
	};

	const handleSubmit = async () => {
		setLoading(true);

		const missingRequiredFields = formFields
			.filter((field) => field.required && !field.value.trim())
			.map((field) => field.label);

		if (missingRequiredFields.length > 0) {
			alert(
				`Please fill in the following required fields: ${missingRequiredFields.join(
					", "
				)}`
			);
			setLoading(false);
			return;
		}

		const data = formFields.reduce(
			(acc, field) => ({ ...acc, [field.name]: field.value }),
			{}
		);

		try {
			if (editingUser) {
				await updateUser(editingUser.id, data);
				toast.success("User edited successfully!");
			} else {
				await registerUser(data);
				toast.success("User added successfully!");
			}

			setIsPopupOpen(false);
			setFormFields((prevFields) =>
				prevFields.map((field) => ({ ...field, value: "" }))
			);
			setTrigger((prev) => prev + 1);
			setEditingUser(null);
		} catch (error: any) {
			console.error("Error submitting form:", error);
			toast.error("An error occurred. Please try again.")
		} finally {
			setLoading(false);
		}
	};

	const handleSwitchUser = async (user: any) => {
		user.active = null;
		switchUser(user.id).then(res => {
			setTrigger((prev) => prev + 1);
		})
	}

	const handleEditUser = (user: any) => {
		setEditingUser(user);
		setFormFields(prevFields => prevFields.map(field => ({
			...field,
			value: field.name === "password" ? "" :
				field.name === "username" ? user.email || '' :
					user[field.name] || '',
		})));
		setIsPopupOpen(true);
	};

	const navigate = useNavigate();

	return (
		<div className="table-container">
			<div className="actions">
				<button className="back-btn" onClick={() => navigate("/admin")}>
					Back
				</button>
				<button className="add-btn" onClick={() => setIsPopupOpen(true)}>
					Add
				</button>
			</div>
			<Popup
				isOpen={isPopupOpen}
				onClose={handleOnClose}
				formFields={formFields.filter(field =>
					editingUser || field.name !== 'password'
				)}
				onInputChange={handleInputChange}
				onSubmit={handleSubmit}
				loading={loading}
				title={editingUser ? "Edit User" : "Add User"}
			/>
			{
				users != null ? (
					<table className="styled-table">
						<thead>
							<tr>
								<th>Organisation</th>
								<th>First Name</th>
								<th>Last Name</th>
								<th>Email</th>
								<th>Role</th>
								{/* <th>Phone Number</th> */}
								<th>Actions</th>
							</tr>
						</thead>
						<tbody>
							{users.map((item: any) => (
								<tr key={item.id}>
									<td>{item.organisationName}</td>
									<td>{item.firstName}</td>
									<td>{item.lastName}</td>
									<td>{item.email}</td>
									<td>{item.role}</td>
									{/* <td>{item.phoneNumber}</td> */}
									<td>
										<button className="edit-btn" onClick={() => handleEditUser(item)}>Edit</button>
										{
											item.role == 'SuperAdmin' ? ''
												:

												item.active == null ? ""
													: item.active ? (
														<button className="disable-btn" onClick={() => handleSwitchUser(item)}>Disable</button>
													) : (
														<button className="enable-btn" onClick={() => handleSwitchUser(item)}>Enable</button>
													)}
									</td>
								</tr>
							))}
						</tbody>
					</table>
				) : (
					""
				)
			}
		</div>
	);
};

export default Users;
