export interface ITabularData {
	name: string;
	type: TabularDataType;
	metadata: ITabularDataMetadata;
	initialData: Array<ITabularDataInitialData>;
}

export enum TabularDataType {
	Section = 1,
	Text = 2,
}

export interface ITabularDataMetadata {
	"Column Group 1 Heading": Array<string>;
	"Column Group 1 Heading Colour": Array<string>;
	"Column Group 1 Heading Font Size": Array<number>;
	"Visible On Load": Array<boolean>;
	"Column Heading Alias": Array<string>;

	"Column Heading Alias Colour": Array<string>;
	"Column Heading Alias Font Size": Array<number>;
	"Decimal Place": Array<number>;
	Unit: Array<string>;
	"Column Order": Array<number>;
	"Value Colour Coding": Array<boolean>;
	"Reverse Colour Coding": Array<boolean>;
	Alignment: Array<string>;
	"Font Size": Array<number>;
	"Sort By": Array<boolean>;
	Ascending: Array<boolean>;
	"Group By": Array<number>;
	Comments: Array<string>;
}

export interface ITabularDataInitialData {
	id: string;
	children: number;
	childrenValues?: Array<ITabularDataInitialData>;
	values: Array<null | string | number>;
}

export interface ITransformedRow {
	children: number;
	id: string | number;
	group?: boolean;
	hasPreLoadedChildren?: boolean;
	childrenData?: Array<ITransformedRow>;
	[key: string]: any;
}

export interface ITransformedTableData {
	transformedRows: Array<ITransformedRow>;
	transformedColumns: Array<IColumnDefinition>;
}
