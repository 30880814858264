import "./block-text-container.scss";

import pinActiveIcon from "../../assets/icons/pin-02-yellow.svg";
import pinIcon from "../../assets/icons/pin-02.svg";
import Sources from "../Sources";
import SummarySentance from "../SummarySentance";

import { TrackingEventType } from "../../Interfaces/events.d";

const BlockText = ({
	block,
	x,
	sectionIndex,
	itemIndex,
	blockIndex,
	xIndex,
	section,
	updateStateFunction,
	recordEvent,
}: any) => {
	return (
		<div
			key={`${sectionIndex}-${itemIndex}-${blockIndex}-${xIndex}`}
			className={`block-text ${section.title === "Performance Overview" ? "performance" : ""}`}
		>
			{section.title === "Performance Overview" && (
				<img
					src={!block.pin ? pinIcon : pinActiveIcon}
					className="performance-block__pin"
					alt="pin icon"
					onClick={() => {
						recordEvent({
							name: (block.pin ? "UNPIN" : "PIN") + " BUTTON CLICK",
							description: `${block.pin ? "Unpin" : "Pin"} click on ${
								block.title
							} button under section ${section.title}`,
							type: TrackingEventType.ButtonClicked,
						});
						updateStateFunction("pin", !block.pin, sectionIndex, itemIndex, blockIndex);
					}}
					title="Click to pin and keep this section open"
				/>
			)}

			<SummarySentance sentance={x.data} />
			<Sources
				onClick={() =>
					updateStateFunction("modal", !block.showModal, sectionIndex, itemIndex, blockIndex)
				}
				onModalClose={() =>
					updateStateFunction("modal", !block.showModal, sectionIndex, itemIndex, blockIndex)
				}
				isModalOpen={block.showModal}
				title={"Sources and Definitions"}
				text={block.sourcesAndDefinitions?.value}
			/>
		</div>
	);
};

export default BlockText;
