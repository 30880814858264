import React, { useState, useEffect } from "react";
import "./driver-block-container.scss";

import chevronDownIcon from "../../assets/icons/chevron-down.svg";
import pinIcon from "../../assets/icons/pin-02.svg";
import pinActiveIcon from "../../assets/icons/pin-02-yellow.svg";

const DriverBlock = ({
	title: driverTitle,
	subtitle,
	children,
	variationDirection,
	showDetails,
	onClick,
	id,
	pin,
	pinOnClick,
}: any) => {
	return (
		<div
			id={id}
			className="driver-block"
			style={
				{
					// cursor: variationDirection === null ? "" : "pointer",
					// pointerEvents: variationDirection === null ? "none" : "all",
				}
			}
		>
			<div
				className="driver-block__parent"
				style={{
					borderColor:
						variationDirection === null
							? ""
							: variationDirection === ""
							? "rgba(255, 200, 0, 0.35)"
							: variationDirection === "-"
							? "rgba(193, 37, 23, 0.16)"
							: "rgba(42, 156, 99, 0.2)",
					backgroundColor:
						variationDirection === null
							? ""
							: variationDirection === ""
							? "rgba(255, 200, 0, 0.35)"
							: variationDirection === "-"
							? "rgba(193, 37, 23, 0.08)"
							: "rgba(42, 156, 99, 0.1)",
				}}
			>
				<img
					src={!pin ? pinIcon : pinActiveIcon}
					className="driver-block__pin"
					alt={"pin icon"}
					onClick={() => pinOnClick()}
					title="Click to pin and keep this section open"
				/>

				<img
					src={chevronDownIcon}
					alt={"down arrow"}
					className={
						!showDetails ? "driver-block__arrow" : "driver-block__arrow active"
					}
					onClick={() => onClick()}
				/>

				<div className="driver-block__content" onClick={() => onClick()}>
					<div className="driver-block__heading">{driverTitle}</div>
					<div
						className="driver-block__paragraph"
						dangerouslySetInnerHTML={{
							__html: subtitle
								.replaceAll("\n", "<br />")
								.replaceAll("<li>", "<li style='list-style-type: none;'>"),
						}}
					></div>
				</div>
			</div>
			{
				// variationDirection &&
				showDetails && (
					<div className="driver-block__details-content">{children}</div>
				)
			}
		</div>
	);
};

export default DriverBlock;
