import React, { useEffect, useRef, useState } from "react";
import "./SplitLayout.scss";

interface SplitLayoutProps {
	firstContent: React.ReactNode;
	secondContent: React.ReactNode;
	initialSplit?: number; // Percentage for the first section (0-100)
	minFirstSize?: number; // Minimum size in pixels for first section
	minSecondSize?: number; // Minimum size in pixels for second section
	direction?: "horizontal" | "vertical"; // Split direction
}

const SplitLayout: React.FC<SplitLayoutProps> = ({
	firstContent,
	secondContent,
	initialSplit = 30,
	minFirstSize = 50,
	minSecondSize = 50,
	direction = "horizontal",
}) => {
	const [splitPosition, setSplitPosition] = useState(initialSplit);
	const containerRef = useRef<HTMLDivElement>(null);
	const isDraggingRef = useRef(false);
	const [isNewUser, setIsNewUser] = useState(true);

	useEffect(() => {
		if (isNewUser) {
			const timer = setTimeout(() => {
				setIsNewUser(false);
			}, 3000);
			return () => clearTimeout(timer);
		}
	}, [isNewUser]);

	const handleMouseDown = (e: React.MouseEvent) => {
		e.preventDefault();
		isDraggingRef.current = true;

		const handleMouseMove = (e: MouseEvent) => {
			if (!isDraggingRef.current || !containerRef.current) return;

			const containerRect = containerRef.current.getBoundingClientRect();

			let newSplitPercentage;
			if (direction === "horizontal") {
				const containerHeight = containerRect.height;
				const mousePositionY = e.clientY - containerRect.top;
				newSplitPercentage = (mousePositionY / containerHeight) * 100;

				const minFirstPercentage = (minFirstSize / containerHeight) * 100;
				const minSecondPercentage = (minSecondSize / containerHeight) * 100;

				newSplitPercentage = Math.max(minFirstPercentage, newSplitPercentage);
				newSplitPercentage = Math.min(
					100 - minSecondPercentage,
					newSplitPercentage
				);
			} else {
				const containerWidth = containerRect.width;
				const mousePositionX = e.clientX - containerRect.left;
				newSplitPercentage = (mousePositionX / containerWidth) * 100;

				const minFirstPercentage = (minFirstSize / containerWidth) * 100;
				const minSecondPercentage = (minSecondSize / containerWidth) * 100;

				newSplitPercentage = Math.max(minFirstPercentage, newSplitPercentage);
				newSplitPercentage = Math.min(
					100 - minSecondPercentage,
					newSplitPercentage
				);
			}

			setSplitPosition(newSplitPercentage);
		};

		const handleMouseUp = () => {
			isDraggingRef.current = false;
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const renderDividerIcon = () => {
		return (
			<div className="divider-icon">
				<span
					className={`divider-dots ${
						direction === "vertical" ? "vertical" : ""
					}`}
				>
					{direction === "horizontal" ? "⋮⋮⋮" : "⋯⋯⋯"}
				</span>
			</div>
		);
	};

	const isHorizontal = direction === "horizontal";
	const containerClass = `split-layout-container ${
		isHorizontal ? "horizontal" : "vertical"
	} ${isNewUser ? "new-user" : ""}`;

	return (
		<div className={containerClass} ref={containerRef}>
			<div
				className="split-layout-first"
				style={
					isHorizontal
						? { height: `${splitPosition}%` }
						: { width: `${splitPosition}%` }
				}
			>
				{firstContent}
			</div>
			<div
				className={`split-layout-divider ${
					isHorizontal ? "horizontal" : "vertical"
				}`}
				onMouseDown={handleMouseDown}
			>
				{renderDividerIcon()}
			</div>
			<div
				className="split-layout-second"
				style={
					isHorizontal
						? { height: `${100 - splitPosition}%` }
						: { width: `${100 - splitPosition}%` }
				}
			>
				{secondContent}
			</div>
		</div>
	);
};

export default SplitLayout;
