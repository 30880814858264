import "ag-grid-charts-enterprise";
import {
	GridReadyEvent,
	ICellRendererParams,
	IServerSideGetRowsParams,
} from "ag-grid-community";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

import { AgGridReact } from "ag-grid-react";
import { useMemo, useState } from "react";
import { IoExpand } from "react-icons/io5";
import Modal from "../Modal";
import SplitLayout from "../SplitLayout/SplitLayout";

import { IColumnDefinition } from "../../Interfaces/reports";
import {
	ITransformedRow,
	TabularDataType,
} from "../../Interfaces/tabular-data.d";
import { transformRows } from "../../utils/ag-grid/ag-grid-transformers";
import SummarySentance from "../SummarySentance";
import "./ssrm-tree-data-table.scss";

const SsrmTreeDataTable = (props: any) => {
	const {
		title,
		gridOptions,
		gridRef,
		domLayout,
		pagination = true,
		paginateChildRows = true,
		paginationPageSize = 20,
		paginationPageSizeSelector = [10, 20, 50, 100],
		columnDefs,
		defaultColDef,
		rowModelType,
		rowGroupPanelShow,
		groupDisplayType,
		serverSideSortAllLevels,
		suppressAggFuncInHeader,
		enableCharts,
		enableRangeSelection,
		sideBar,
		loadChildrenApiCall,
		treeData,
		rowData,
		metadata,
		textSection,
		onBeforeModalOpen,
		onBeforeModalClose,
		autoExpandPreloadedNodes = true,
		autoExpandMaxDepth = Infinity,
		verticalHeadings = false,
	} = props;

	const [isModalOpen, setIsModalOpen] = useState(false);

	const handleExpandClick = () => {
		// Call the parent's function if provided
		if (onBeforeModalOpen) {
			onBeforeModalOpen(); // No parentheses here when passing as a prop
		}
		setIsModalOpen(true); // Then open modal
	};

	const handleCloseModal = () => {
		// Call the parent's function if provided
		if (onBeforeModalClose) {
			onBeforeModalClose(); // No parentheses here when passing as a prop
		}
		setIsModalOpen(false); // Then close modal
	};

	let mergedGridOptions = {
		rowModelType: "serverSide",
		treeData: true,
		autoSizeStrategy: {
			type: "fitCellContents",
			skipHeader: true,
		},
		// indicate if row is a group node
		isServerSideGroup: (dataItem: any): boolean => {
			return Boolean(dataItem.group);
		},

		// populate group keys here
		getServerSideGroupKey: (dataItem: any): string | undefined => {
			// make groupKey dynamic pulling from metadata

			if (dataItem.group) {
				const firstKey = Object.keys(dataItem)[2];
				const firstValue = dataItem[firstKey];
				return String(firstValue);
			}
			return undefined;
		},
		...gridOptions,
	};

	if (verticalHeadings) {
		mergedGridOptions = {
			...mergedGridOptions,
			groupHeaderHeight: 50,
			headerHeight: 160,
		};
	}

	const modalMergedGridOptions = {
		...mergedGridOptions,
		autoSizeStrategy: {
			type: "fitGridWidth",
		},
	};

	const isServerSideGroup = (dataItem: ITransformedRow): boolean => {
		return Boolean(
			dataItem.children > 0 ||
				(dataItem.hasPreLoadedChildren &&
					dataItem.childrenData &&
					dataItem.childrenData.length > 0)
		);
	};

	const getServerSideGroupKey = (dataItem: ITransformedRow): string => {
		return String(dataItem.id);
	};

	const datasource = {
		async getRows(params: IServerSideGetRowsParams<any>) {
			try {
				let data;
				// If there are group keys, we are expanding a row (loading children)
				if (params.request.groupKeys && params.request.groupKeys.length > 0) {
					const parentId =
						params.request.groupKeys[params.request.groupKeys.length - 1];

					const parentNode = findParentNode(rowData, parentId);

					if (
						parentNode &&
						parentNode.hasPreLoadedChildren &&
						parentNode.childrenData
					) {
						// Use already loaded data
						data = parentNode.childrenData;
					} else {
						// Fetch data from API
						const childrenData = await loadChildrenApiCall(parentId);
						data = transformRows({
							initialData: childrenData,
							metadata: metadata,
							name: "Children Data",
							type: TabularDataType.Section,
						});
					}
				}
				// Initial data
				else {
					data = rowData;
				}

				const sortModel = [
					...(params.request.sortModel || []),
					...columnDefs
						.filter((col: IColumnDefinition) => col.sort)
						.map((col: IColumnDefinition) => ({
							colId: col.field,
							sort: col.sort,
						})),
				];

				if (sortModel.length > 0) {
					data = [...data].sort((a, b) => {
						for (const sort of sortModel) {
							const value1 = a[sort.colId];
							const value2 = b[sort.colId];
							const direction = sort.sort === "asc" ? 1 : -1;

							if (value1 < value2) return -1 * direction;
							if (value1 > value2) return 1 * direction;
						}
						return 0;
					});
				}

				params.success({
					rowData: data,
				});
			} catch (error) {
				console.error("Error fetching rows:", error);
				params.fail();
			}
		},
	};

	const findParentNode = (nodes: any[], nodeId: string): any => {
		if (!nodes || !Array.isArray(nodes)) {
			return null;
		}

		for (const node of nodes) {
			if (!node) continue;

			if (String(node.id) === String(nodeId)) {
				return node;
			}

			// If this node has pre-loaded children to go and search them too
			if (
				node.childrenData &&
				Array.isArray(node.childrenData) &&
				node.childrenData.length > 0
			) {
				const foundInChildren = findParentNode(node.childrenData, nodeId);
				if (foundInChildren) {
					return foundInChildren;
				}
			}
		}
		return null;
	};

	const autoGroupColumnDef = useMemo(() => {
		return {
			headerName: "Hierarchy",
			cellStyle: { fontSize: "12px" },
			cellRenderer: "agGroupCellRenderer",
			pinned: "left" as const,
			cellRendererParams: {
				suppressCount: true,
				innerRenderer: (params: ICellRendererParams) => {
					const level = params.node.level;
					const fieldToUse =
						columnDefs[level]?.field || columnDefs[0]?.field || "id";
					const value = params.data[fieldToUse] ?? "N/A";
					const childCount = params.data?.children || 0;
					if (childCount === 0) return `${value}`;
					return `${value} (${childCount})`;
				},
			},
		};
	}, [columnDefs]);

	const onGridReady = (params: GridReadyEvent<any>) => {
		params.api.setGridOption("serverSideDatasource", datasource);

		// Thats to expende the rows and the ui that has pre-loaded data
		if (autoExpandPreloadedNodes) {
			params.api.addEventListener("firstDataRendered", () => {
				expandNodesWithPreloadedChildren(params.api, rowData);
			});
		}
	};

	const expandNodesWithPreloadedChildren = (gridApi: any, nodes: any[]) => {
		if (!nodes || !Array.isArray(nodes)) return;

		gridApi.forEachNode((node: any) => {
			const data = node.data;

			if (
				data &&
				data.hasPreLoadedChildren &&
				data.childrenData &&
				data.childrenData.length > 0
			) {
				node.setExpanded(true);
				if (autoExpandMaxDepth > 1) {
					setTimeout(() => {
						expandChildrenIfNeeded(gridApi, data.childrenData, data.id, 2);
					}, 100);
				}
			}
		});
	};

	const expandChildrenIfNeeded = (
		gridApi: any,
		childNodes: any[],
		parentId: string,
		currentDepth: number = 1
	) => {
		if (!childNodes || !Array.isArray(childNodes) || childNodes.length === 0)
			return;

		if (currentDepth > autoExpandMaxDepth) return;

		childNodes.forEach((childData) => {
			if (
				childData &&
				childData.hasPreLoadedChildren &&
				childData.childrenData &&
				childData.childrenData.length > 0
			) {
				let foundNode = null;

				if (gridApi.getRowNode && typeof gridApi.getRowNode === "function") {
					foundNode = gridApi.getRowNode(String(childData.id));
				}

				if (!foundNode) {
					gridApi.forEachNode((node: any) => {
						if (node.data && String(node.data.id) === String(childData.id)) {
							foundNode = node;
						}
					});
				}

				if (foundNode) {
					foundNode.setExpanded(true);

					if (currentDepth < autoExpandMaxDepth) {
						setTimeout(() => {
							expandChildrenIfNeeded(
								gridApi,
								childData.childrenData,
								childData.id,
								currentDepth + 1
							);
						}, 100);
					}
				}
			}
		});
	};

	return (
		<div className="ssrm-tree-data-table-container">
			{verticalHeadings && (
				<style>
					{`
						.ag-header-cell-label {
							height: 100%;
							padding: 0 !important;
						}

						.ag-header-cell-label .ag-header-cell-text {
							writing-mode: vertical-rl;
							-ms-writing-mode: tb-lr;
							text-align: center;
							white-space: normal;
							word-wrap: break-word;
							overflow-wrap: break-word;
							width: 32px;
							overflow: hidden;
							padding: 4px !important;
						}
					`}
				</style>
			)}
			{title && title != null ? (
				<h4 dangerouslySetInnerHTML={{ __html: title }}></h4>
			) : (
				""
			)}
			<div className="ssrm-tree-data-table-controls">
				<button
					className="expand-view-button"
					onClick={() => handleExpandClick()}
					title="Expand View"
				>
					<IoExpand />
				</button>
			</div>

			<div className={"ag-theme-quartz"}>
				<AgGridReact
					autoGroupColumnDef={autoGroupColumnDef}
					gridOptions={mergedGridOptions}
					ref={gridRef}
					domLayout={domLayout}
					pagination={pagination}
					paginationPageSize={paginationPageSize}
					paginationPageSizeSelector={paginationPageSizeSelector}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowModelType={rowModelType}
					onGridReady={onGridReady}
					rowGroupPanelShow={rowGroupPanelShow}
					groupDisplayType={groupDisplayType}
					serverSideSortAllLevels={serverSideSortAllLevels}
					suppressAggFuncInHeader={suppressAggFuncInHeader}
					enableCharts={enableCharts}
					paginateChildRows={paginateChildRows}
					enableRangeSelection={enableRangeSelection}
					sideBar={sideBar}
					treeData={treeData}
					isServerSideGroup={isServerSideGroup}
					getServerSideGroupKey={getServerSideGroupKey}
				/>
			</div>

			<Modal
				isOpen={isModalOpen}
				onClose={() => handleCloseModal()}
				title=""
				defaultFullScreen={true}
			>
				{textSection ? (
					<SplitLayout
						direction="vertical"
						firstContent={
							<div className="description-section">
								<h2>{textSection.title}</h2>
								<SummarySentance sentance={textSection.description} />
							</div>
						}
						secondContent={
							<>
								{title && title != null ? (
									<h3
										className={"expanded-table-title"}
										dangerouslySetInnerHTML={{ __html: title }}
									></h3>
								) : (
									""
								)}
								<div className="ag-grid-container">
									<div className="ag-theme-quartz">
										<AgGridReact
											popupParent={document.body}
											autoGroupColumnDef={autoGroupColumnDef}
											gridOptions={modalMergedGridOptions}
											domLayout="normal"
											pagination={pagination}
											paginationPageSize={paginationPageSize}
											paginationPageSizeSelector={paginationPageSizeSelector}
											columnDefs={columnDefs}
											defaultColDef={defaultColDef}
											rowModelType={rowModelType}
											onGridReady={onGridReady}
											rowGroupPanelShow={rowGroupPanelShow}
											groupDisplayType={groupDisplayType}
											serverSideSortAllLevels={serverSideSortAllLevels}
											suppressAggFuncInHeader={suppressAggFuncInHeader}
											enableCharts={enableCharts}
											paginateChildRows={paginateChildRows}
											enableRangeSelection={enableRangeSelection}
											sideBar={sideBar}
											treeData={treeData}
											isServerSideGroup={isServerSideGroup}
											getServerSideGroupKey={getServerSideGroupKey}
										/>
									</div>
								</div>
							</>
						}
						initialSplit={15}
						minFirstSize={150}
						minSecondSize={300}
					/>
				) : (
					<div className="ag-grid-container">
						<div className="ag-theme-quartz">
							<AgGridReact
								popupParent={document.body}
								autoGroupColumnDef={autoGroupColumnDef}
								gridOptions={modalMergedGridOptions}
								domLayout="normal"
								pagination={pagination}
								paginationPageSize={paginationPageSize}
								paginationPageSizeSelector={paginationPageSizeSelector}
								columnDefs={columnDefs}
								defaultColDef={defaultColDef}
								rowModelType={rowModelType}
								onGridReady={onGridReady}
								rowGroupPanelShow={rowGroupPanelShow}
								groupDisplayType={groupDisplayType}
								serverSideSortAllLevels={serverSideSortAllLevels}
								suppressAggFuncInHeader={suppressAggFuncInHeader}
								enableCharts={enableCharts}
								paginateChildRows={paginateChildRows}
								enableRangeSelection={enableRangeSelection}
								sideBar={sideBar}
								treeData={treeData}
								isServerSideGroup={isServerSideGroup}
								getServerSideGroupKey={getServerSideGroupKey}
							/>
						</div>
					</div>
				)}
			</Modal>
		</div>
	);
};

export default SsrmTreeDataTable;
