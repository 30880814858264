import React, { useState } from "react";
import "./parent-accordion-container.scss";

const ParentAccordion = ({
	text,
	onClick,
	id,
	children,
	defaultOpen = false,
}: {
	text: string;
	onClick?: () => void;
	id?: string;
	children?: React.ReactNode;
	defaultOpen?: boolean;
}) => {
	const [showContent, setShowContent] = useState(defaultOpen);

	const handleClick = () => {
		setShowContent((prev) => !prev);
		if (onClick) onClick();
	};

	return (
		<div className="parent-accordion-container">
			<div
				className="parent-accordion"
				onClick={handleClick}
				id={id}
				role="button"
				aria-expanded={showContent}
				aria-controls={`content-${id}`}
			>
				<div className="parent-accordion__text">{text}</div>
				<div className={`parent-accordion__arrow ${showContent ? "active" : ""}`}>
					&rsaquo;
				</div>
			</div>
			{showContent && (
				<div className="parent-accordion__content" id={`content-${id}`}>
					{children}
				</div>
			)}
		</div>
	);
};

export default ParentAccordion;
