import { axiosRequestInterceptor } from "./api";

export const getAllUsers = async () => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.get("api/users");
	return response;
};

export const getUserById = async (id: string) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.get(`api/users/${id}`);
	return response;
};

export const registerUser = async (data: any) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.post(`api/auth/register`, data);
	return response;
};

export const switchUser = async (id: string) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.patch(`api/users/${id}`);
	return response;
};

export const updateUser = async (id: string, data: any) => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.put(`api/users/${id}`, data);
	return response;
};