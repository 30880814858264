import "./thank-you-container.scss";

const ThankYou = () => {
	return (
		<div className="container">
			<h1 className="title">
				Thank You! Your request has been submitted <span className="emoji">😀</span>
			</h1>
		</div>
	);
};

export default ThankYou;
