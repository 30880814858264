import "./admin-container.scss";
import { useNavigate } from "react-router-dom";

const Admin = () => {
	const navigate = useNavigate();

	return (
		<div className="button-container">
			<button className="btn" onClick={() => navigate("/admin/users")}>
				Users
			</button>
			<button className="btn" onClick={() => navigate("/admin/feedback")}>
				Feedback
			</button>
			<button className="btn" onClick={() => navigate("/admin/requests")}>
				Requests
			</button>
		</div>
	);
};

export default Admin;
