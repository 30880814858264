import React, { useEffect, useState, useCallback } from "react";
import { useParams } from "react-router-dom";
import * as signalR from "@microsoft/signalr";

import "./report-demo-container.scss";

import {
	execSum,
	salesMetricBlocks,
	gpMetricBlocks,
	performanceComparisonPara,
	salesDataPointsTop,
	salesDataPointsBottom,
	gpDataPointsTop,
	gpDataPointsBottom,
	performanceComparisonTable,
	performanceComparisonTableColDefs,
	skuComparisonPara,
	performanceDrivers,
	salesColDefs,
	salesTableRows,
	promotionsTableColDefs,
	promotionsTableRows,
	rangingTableColDefs,
	rangingTableRows,
} from "./reportData";

import MasterTable from "./MasterTable/MasterTable";
import { transformRows } from "../../utils/ag-grid/ag-grid-transformers";
import Sources from "../../components/Sources";
import ParentAccordion from "../../components/ParentAccordion";
import SectionTitle from "../../components/SectionTitle";
import MetricBlock from "../../components/Sources/MetricBlock";
import ViewDetails from "../../components/ViewDetails";
import SummarySentanceDemo from "../../components/SummarySentanceDemo";
import AgGridStaticTable from "../../components/AgGridStaticTable";
import BulletPoint from "../../components/BulletPoint";
import Button from "../../components/Button";
import SelectionBubble from "../../components/SelectionBubble";

import analyseIcon from "../../assets/icons/pie-chart.svg";
import ReportNavBubble from "../../components/ReportNavBubble";
import AgGridClientSide from "../../components/AgGridClientSide";
import ExecSumParagraphDemo from "../../components/ExecSumParagraphDemo";
import SalesGpSectionDemo from "../../components/SalesGpSectionDemo/input";
import DetailsContainerDemo from "../../components/DetailsContainerDemo";
import ReportTitleDemo from "../../components/ReportTitleDemo";
import DriverBlockDemo from "../../components/DriverBlockDemo";

const backend = process.env.REACT_APP_BE_URL || "";

const ReportDemo = () => {
	const [showAnalysis, setShowAnalysis] = useState(false);
	const [showMasterDataTable, setShowMasterDataTable] = useState(false);
	const [showSalesDetails, setShowSalesDetails] = useState(false);
	const [showGpDetails, setShowGpDetails] = useState(false);
	const [
		showPerformanceComparisonDetails,
		setShowPerformanceComparisonDetails,
	] = useState(false);
	const [showSkuComp, setShowSkuComp] = useState(false);

	const [perfCompColDefs, setPerfCompColDefs]: any = useState([]);
	const [salesColDefsState, setSalesColDefs]: any = useState([]);
	const [events, setEvents] = useState<any>([]);
	const [startEvents, setStartEvents] = useState(false);

	const { reportId }: any = useParams();

	useEffect(() => {
		if (!startEvents) return;
		if (!reportId) return;
		const accessToken = localStorage.getItem("accessToken");

		if (!accessToken) {
			console.error("No access token found. Please login first.");
			return;
		}

		// Establish a SignalR connection
		const connection = new signalR.HubConnectionBuilder()
			.withUrl(`${backend}/eventHub?reportId=${reportId}`, {
				accessTokenFactory: () => accessToken,
			}) // Pass DeviceId as a query param
			.withAutomaticReconnect()
			.build();

		connection
			.start()
			.then(() => {
				console.log(`Connected to SignalR for Report ${reportId}`);
			})
			.catch((err) => console.error("SignalR connection error:", err));

		// Listen for new events from the backend
		connection.on("NewEvent", (event) => {
			console.log("Received new event:", event);
			setEvents((prevEvents: any) => [...prevEvents, event]);
		});

		// Cleanup on component unmount
		return () => {
			console.log(`No more events for report ID ${reportId}`);

			connection.stop();
		};
	}, [reportId, startEvents]);

	useEffect(() => {
		console.log("New Event", events);
	}, [events]);

	const agGridMasterApiCallSales = (params: any) => {
		const response = salesColDefs;
		setSalesColDefs(response);
		return salesTableRows;
	};

	const agGridMasterApiCall = (params: any) => {
		const response = performanceComparisonTableColDefs;
		setPerfCompColDefs(response);
		return performanceComparisonTable;
	};

	const getRowStyle = useCallback(
		(params: any) => {
			if (params.node.rowIndex === salesTableRows.length - 1) {
				return {
					backgroundColor: "#E6EBE9",
					fontWeight: "bold",
				};
			}
			return undefined;
		},
		[salesTableRows]
	);

	const startEventsFunc = () => {
		setStartEvents(true);
	};

	const gridOptions = {
		getDataPath: (data: any) => {
			return data.path;
		},
		autoGroupColumnDef: {
			headerName: "SKU",
			headerClass: "top-header-1",
			cellRendererParams: {
				suppressCount: false,
			},
			minWidth: 200,
			flex: 1,
			cellStyle: {
				fontSize: "12px",
			},
		},
		defaultColDef: {
			flex: 1,
			minWidth: 180,
		},
	};
	const gridOptionsPerfComp = {
		defaultColDef: {
			flex: 1,
			minWidth: 150,
		},
	};

	return (
		<div className="report-demo">
			{reportId ? (
				<Button
					onClick={() => startEventsFunc()}
					text={"Start Events Webhook"}
					style={{
						backgroundColor: "#e1f0ee",
						opacity: !reportId ? "50%" : "100%",
						pointerEvents: !reportId ? "none" : "",
						marginTop: "24px",
					}}
				/>
			) : (
				<></>
			)}
			<div className="report-demo__header">
				<div className="report-demo__header-top">
					<div className="report-demo__logo" />
					<div className="report-demo__watermark" />
				</div>
				<div className="report-demo__header-bottom">
					<div className="report-demo__summary">
						<div className="report-demo__summary-label">Analyse this</div>
						<ReportNavBubble text={"Spirits"} icon={"analyse"} />
						<ReportNavBubble text={"Gin"} icon={"analyse"} />
						<ReportNavBubble text={"Gin-Australian"} icon={"analyse"} />
						<ReportNavBubble
							text={"Archie Rose Distilling Co"}
							icon={"analyse"}
						/>
					</div>
					<div className="report-demo__summary">
						<div className="report-demo__summary-label">Time</div>
						<ReportNavBubble text={"Week 25 - Week 37"} icon={"time"} />
					</div>
					<div className="report-demo__summary">
						<div className="report-demo__summary-label">to</div>
						<ReportNavBubble text={"Week 25 - Week 37"} icon={"time"} />
					</div>
					<div className="report-demo__summary">
						<div className="report-demo__summary-label">Breakdown</div>
						<ReportNavBubble text={"SKU"} icon={"breakdown"} />
					</div>
				</div>
			</div>
			<div className="report-demo__content">
				<ReportTitleDemo
					text={"Archie Rose Distilling Co"}
					h2={"DEMO ONLY - SANITISED DATA"}
					version={"V08.13"}
					date={"1.10.2024"}
				/>
				<div className="report-demo__content-exec-summary">
					{execSum &&
						execSum.map((para: any, index: any) => {
							return (
								<div key={index}>
									<ExecSumParagraphDemo para={para} key={index} />
								</div>
							);
						})}
					<Sources number={6} />
				</div>

				<ParentAccordion
					onClick={() => setShowAnalysis((prev) => !prev)}
					text="Analysis"
				/>

				{showAnalysis && (
					<div className="report-analysis">
						<SectionTitle title={"Total Sales"} />
						<div className="report-analysis__sales">
							<div className="report-analysis__sales-content">
								<div className="report-analysis__sales-metrics">
									{salesMetricBlocks &&
										salesMetricBlocks.map((block: any, index: any) => {
											const {
												heading,
												subHeading,
												columnWidth,
												variationDirection,
											} = block;
											return (
												<MetricBlock
													key={`met-${index}`}
													heading={heading}
													subHeading={subHeading}
													columnWidth={columnWidth}
													variationDirection={variationDirection}
												/>
											);
										})}
								</div>
								<SalesGpSectionDemo
									heading={
										<>
											Totals sales were $27.7m, down{" "}
											<span
												style={{
													color: "#B73125",
													fontFamily: "AptosFont",
													fontWeight: 700,
												}}
											>
												-$1.8m
											</span>{" "}
											(
											<span
												style={{
													color: "#B73125",
													fontFamily: "AptosFont",
													fontWeight: 700,
												}}
											>
												-6.3%
											</span>
											) YOY
										</>
									}
									bulletPoints={[
										<>
											<span style={{ color: "#B73125" }}>-$4m</span> impact to
											sales from a sales unit decrease of{" "}
											<span style={{ color: "#B73125" }}>-71k</span> to 442k (
											<span style={{ color: "#B73125" }}>-13.8%</span> YOY)
										</>,
										<>
											<span style={{ color: "#238755" }}>+2.2m</span> impact to
											sales from an ASP increase of{" "}
											<span style={{ color: "#238755" }}>+$4.91</span> to $61.62
											(<span style={{ color: "#238755" }}>+8.6%</span>). Driven
											primarily by an RRP increase (mix and discounting effects
											had a smaller impact).
										</>,
									]}
								/>
							</div>
							<ViewDetails
								className="view-details"
								onClick={() => setShowSalesDetails((prev) => !prev)}
								showContent={showSalesDetails}
							/>
							{showSalesDetails && (
								<DetailsContainerDemo
									title={"Sales Breakdown"}
									detailsHeading={"Breakdown of changes to sales"}
									detailsSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									tableHeading={"Sales units"}
									tableSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									dataPointsTop={salesDataPointsTop}
									dataPointsBottom={salesDataPointsBottom}
									chart={"sales"}
								/>
							)}
						</div>
						<SectionTitle title={"Total GP$"} />
						<div className="report-analysis__sales">
							<div className="report-analysis__sales-content">
								<div className="report-analysis__sales-metrics">
									{gpMetricBlocks &&
										gpMetricBlocks.map((block: any, index: number) => {
											const {
												heading,
												subHeading,
												columnWidth,
												variationDirection,
											} = block;
											return (
												<MetricBlock
													key={index}
													heading={heading}
													subHeading={subHeading}
													columnWidth={columnWidth}
													variationDirection={variationDirection}
												/>
											);
										})}
								</div>
								<SalesGpSectionDemo
									heading={
										<>
											GP$ was $4.7m, decreasing{" "}
											<span
												className="span-bold-text"
												style={{
													color: "#B73125",
												}}
											>
												-$474k
											</span>{" "}
											(
											<span
												className="span-bold-text"
												style={{
													color: "#B73125",
												}}
											>
												-9.1%
											</span>
											) YOY
										</>
									}
									bulletPoints={[
										<>
											<span style={{ color: "#B73125" }}>-$329k</span> impact to
											$GP from a sales unit decrease.
										</>,
										<>
											<span style={{ color: "#B73125" }}>-144k</span> impact to
											GP$ from a GP% decrease of
											<span style={{ color: "#B73125" }}> -50 bps</span> to
											17.5%. This was caused by an increase in COGS and change
											in mix (selling more lower margin SKUs). This was also the
											reason GP$ fell faster than sales.
										</>,
									]}
								/>
							</div>
							<ViewDetails
								className="view-details"
								onClick={() => setShowGpDetails((prev) => !prev)}
								showContent={showGpDetails}
							/>
							{showGpDetails && (
								<DetailsContainerDemo
									title={"GP Breakdown"}
									detailsHeading={"Breakdown of changes to GP$"}
									detailsSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									tableHeading={"COGS"}
									tableSubheading={
										"Archie Rose; Australian Gin; focus v comparison period"
									}
									dataPointsTop={gpDataPointsTop}
									dataPointsBottom={gpDataPointsBottom}
									chart={"gp"}
								/>
							)}
						</div>
						<SectionTitle title={"Performance Comparison"} />
						<div className="report-analysis__sales">
							{performanceComparisonPara &&
								performanceComparisonPara.map((sentance: any, index: number) => {
									return <SummarySentanceDemo key={`prf-${index}`} sentance={sentance} />;
								})}

							<AgGridStaticTable
								// gridRef={gridRef}
								columnDefs={perfCompColDefs}
								// defaultColDef={defaultColDef}
								gridOptions={gridOptionsPerfComp}
								domLayout={"autoHeight"}
								transformRows={transformRows}
								rowModelType={"serverSide"}
								pagination={true}
								paginationPageSize={20}
								paginationPageSizeSelector={[10, 20, 50, 100]}
								paginateChildRows={true}
								rowGroupPanelShow={"none"}
								groupDisplayType={"multipleColumns"}
								enableCharts={true}
								enableRangeSelection={true}
								serverSideSortAllLevels={true}
								suppressAggFuncInHeader={true}
								agGridMasterApiCall={agGridMasterApiCall}
							/>
							<ViewDetails
								className="view-details"
								onClick={() =>
									setShowPerformanceComparisonDetails((prev) => !prev)
								}
								showContent={showPerformanceComparisonDetails}
							/>
							{showPerformanceComparisonDetails && (
								<SummarySentanceDemo sentance={"Performance Comparison Details"} />
							)}
						</div>
						<SectionTitle title={"Breakdown and Compare: Articles"} />
						<div className="report-analysis__sales">
							{skuComparisonPara &&
								skuComparisonPara.map((para: any, index: any) => {
									if (para.sentance) {
										return <SummarySentanceDemo key={`brk-${index}`} sentance={para.sentance} />;
									}
									if (para.bullet) {
										return <BulletPoint key={`brk-${index}`} bulletPoint={para.bullet} />;
									}
								})}

							<ViewDetails
								className="view-details"
								onClick={() => setShowSkuComp((prev) => !prev)}
								showContent={showPerformanceComparisonDetails}
							/>
							{showSkuComp && (
								<SummarySentanceDemo sentance={"SKU Comparison Details"} />
							)}
						</div>
						<SectionTitle title={"Performance Drivers"} />
						<div className="report-analysis__sales drivers">
							{performanceDrivers &&
								performanceDrivers.map((driver: any, index: any) => {
									const { title, blocks } = driver;
									return (
										<div key={index} className="report-analysis__sales__section">
											<SectionTitle
												title={title}
												style={{ marginLeft: "-25px", backgroundColor: 'white', borderTop: 0 }}
											/>
											<br />
											<br />
											{blocks.map((block: any, blockIndex: number) => {
												const {
													heading,
													variationDirection,
													paragraph,
													details,
												} = block;

												return (
													<div key={blockIndex}>
														<DriverBlockDemo
															heading={heading}
															paragraph={paragraph}
															variationDirection={variationDirection}
														>
															{details.map((detail: any, index: number) => {
																const { heading, sentance, bullet } = detail;
																return (
																	<div
																		className="driver-block__details"
																		key={index}
																	>
																		<div className="driver-block__heading">
																			{heading}
																		</div>
																		{sentance && (
																			<SummarySentanceDemo sentance={sentance} />
																		)}
																		{bullet && (
																			<BulletPoint bulletPoint={bullet} />
																		)}
																	</div>
																);
															})}
															<AgGridClientSide
																gridOptions={gridOptions}
																// gridRef={gridRef}
																columnDefs={
																	heading === "Promotions"
																		? promotionsTableColDefs
																		: rangingTableColDefs
																}
																rowData={
																	heading === "Promotions"
																		? promotionsTableRows
																		: rangingTableRows
																}
																// defaultColDef={defaultColDef}
																// gridOptions={gridOptions}
																domLayout={"autoHeight"}
																rowModelType={"clientSide"}
																pagination={true}
																paginationPageSize={20}
																paginationPageSizeSelector={[10, 20, 50, 100]}
																paginateChildRows={true}
																rowGroupPanelShow={"none"}
																// groupDisplayType={"multipleColumns"}
																enableCharts={true}
																enableRangeSelection={true}
																serverSideSortAllLevels={true}
																suppressAggFuncInHeader={true}
																treeData={true}
															/>
														</DriverBlockDemo>
													</div>
												);
											})}
										</div>
									);
								})}
						</div>
					</div>
				)}

				<ParentAccordion
					onClick={() => setShowMasterDataTable((prev) => !prev)}
					text="Master Data Table"
				/>
				{showMasterDataTable && <MasterTable />}
			</div>
		</div>
	);
};

export default ReportDemo;
