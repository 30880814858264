import { IReport } from "../Interfaces/reports";
import { ITabularDataInitialData } from "../Interfaces/tabular-data";
import { axiosRequestInterceptor } from "./api";

export const getReportData = async (reportId: string): Promise<IReport> => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.get<IReport>("api/report/" + reportId);
  return response.data;
};

export const getReportChildrenDataApi = async (
  reportId: string,
  parentId: string
): Promise<Array<ITabularDataInitialData>> => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.get<Array<ITabularDataInitialData>>(
    `api/report/children/${reportId}`,
    {
      params: {
        parentId,
      },
    }
  );
  return response.data;
};

export const postMasterTableAgGrid = async (params: any) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post("api/report/aggrid", params);
  return response;
};

export const getReportId = async (params: any) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post(
    "api/report/analyse-databricks",
    params
  );
  return response;
};

export const getReportRequests = async () => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.get("api/report/request");
  return response;
};

export const requestReport = async (params: any) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post("api/report/request", params);
  return response;
};

export const deleteReport = async (reportId: string) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.delete("api/report/" + reportId);
  return response;
};

export const uploadReportFiles = async (reportId: string, formData: any) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post(
    "api/files/upload/" + reportId,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
      onUploadProgress: (progressEvent) => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent!.total!
        );
        console.log(`Upload Progress: ${percentCompleted}%`);
      },
    }
  );
  return response;
};

export const reprocessReport = async (reportId: string) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.patch("api/report/reprocess/" + reportId);
  return response;
};