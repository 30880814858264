import React from "react";
import "./report-nav-bubble-container.scss";

import analyseIcon from "../../assets/icons/pie-chart.svg";
import timeIcon from "../../assets/icons/calendar.svg";
import filterIcon from "../../assets/icons/filter-lines.svg";
import breakdownIcon from "../../assets/icons/bar-line-chart.svg";

const ReportNavBubble = ({ icon, text }: any) => {
	let bubbleIcon =
		icon === "analyse"
			? analyseIcon
			: icon === "time"
			? timeIcon
			: icon === "breakdown"
			? breakdownIcon
			: filterIcon;

	return (
		<div className="bubble">
			<img className="bubble__icon" src={bubbleIcon} />
			<div className="bubble__text">{text}</div>
		</div>
	);
};

export default ReportNavBubble;
