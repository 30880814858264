import { Link } from "react-router-dom";
import NotFoundImage from "../../assets/icons/alert-square.svg"; // Example image
import "./not-found-container.scss";

const NotFound = () => {
	return (
		<div className="not-found-container">
			<img src={NotFoundImage} alt="404" className="icon" />
			<h1 className="text-5xl font-bold mt-4">Oops! 404</h1>
			<p className="text-lg text-gray-600 mt-2">We can't find that page.</p>
			<Link to="/" className="">
				Return Home
			</Link>
		</div>
	);
};

export default NotFound;
