import React from "react";
import "./metric-block-container.scss";

const MetricBlock = ({
	heading,
	subHeading,
	columnWidth,
	variationDirection,
}: any) => {
	return (
		<div
			className={`metric-block ${columnWidth === 2 ? "top" : ""}`}
			style={{
				borderColor:
					variationDirection === "-"
						? "rgba(193, 37, 23, 0.16)"
						: variationDirection === "+" ? "rgba(42, 156, 99, 0.2)"
							: "",
				// backgroundColor:
				// 	variationDirection === "-"
				// 		? "rgba(193, 37, 23, 0.08)"
				// 		: variationDirection === "+" ? "rgba(42, 156, 99, 0.1)"
				// 			: "",
				height: columnWidth === 2 ? "96px" : "80px",
				width: columnWidth === 2 ? "100%" : "50%",
			}}
		>
			<div className="metric-block__heading" dangerouslySetInnerHTML={{ __html: heading }}></div>
			<div className="metric-block__subHeading" dangerouslySetInnerHTML={{ __html: subHeading }}></div>
		</div>
	);
};

export default MetricBlock;
