import React, { useState, useEffect } from "react";
import "./subsection-block-container.scss";

import chevronDownIcon from "../../assets/icons/chevron-down.svg";
import pinIcon from "../../assets/icons/pin-02.svg";
import pinActiveIcon from "../../assets/icons/pin-02-yellow.svg";

const SubsectionBlock = ({
	title,
	subtitle,
	children,
	showDetails,
	onClick,
	id,
	pin,
	pinOnClick,
	style,
}: any) => {
	const formattedSubtitle = subtitle && subtitle != null ? Array.isArray(subtitle)
		? subtitle
			.map((s) =>
				s.value
					.replaceAll("\n", "<br />")
					.replaceAll("<li>", "<li style='list-style-type: none;'>")
			)
			.join("")
		:
		subtitle
			.replaceAll("\n", "<br />")
			.replaceAll("<li>", "<li style='list-style-type: none;'>")
		: null;

	return (
		<div id={id} className="subsection-block">
			<div className="subsection-block__parent" style={style}>
				<img
					src={!pin ? pinIcon : pinActiveIcon}
					className="subsection-block__pin"
					alt={"pin icon"}
					onClick={() => pinOnClick()}
					title="Click to pin and keep this section open"
				/>

				<img
					src={chevronDownIcon}
					alt={"down arrow"}
					className={
						!showDetails
							? "subsection-block__arrow"
							: "subsection-block__arrow active"
					}
					onClick={() => onClick()}
				/>

				<div className="subsection-block__content" onClick={() => onClick()}>
					<div className="subsection-block__heading">{title}</div>
					<div
						className="subsection-block__paragraph"
						dangerouslySetInnerHTML={{
							__html: formattedSubtitle,
						}}
					></div>
				</div>
			</div>
			{showDetails && (
				<div className="subsection-block__details-content">{children}</div>
			)}
		</div>
	);
};

export default SubsectionBlock;
