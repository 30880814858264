import React from "react";
import "./summary-sentance-bullet-container.scss";
const SummarySentanceBullet = ({ sentance }: any) => {
	if (!sentance) return null;

	return (
		<div
			className="summary-sentance-bullet"
			dangerouslySetInnerHTML={{
				__html: sentance
					.replaceAll("\n", "<br />")
					.replaceAll("<li>", "<li style='list-style-type: none;'>"),
			}}
		></div>
	);
};

export default SummarySentanceBullet;
