export interface ITrackingEvent {
	name: string;
	description: string;
	type: number;
}

export enum TrackingEventType {
	Unknown = 0,
	ButtonClicked = 1,
	PageLoad = 10,
}
