export const execSum = [
	{
		title: "Overall Performance",
		sentances: [
			{
				sentance: (
					<>
						Sales were $27.2m. They decreased
						<span style={{ color: "#B73125" }}> -$1.8m </span> (
						<span style={{ color: "#B73125" }}>-6.3%</span>).
					</>
				),
			},
			{
				sentance: (
					<>
						GP$ was $4.7m. It decreased{" "}
						<span style={{ color: "#B73125" }}>-$474</span> (
						<span style={{ color: "#B73125" }}>-9.1%</span>).
					</>
				),
			},
			{
				sentance: (
					<>
						GP% decreased <span style={{ color: "#B73125" }}> -50 bps </span> to
						17.5%. This was primarily from a COGS increase and a sales mix
						change with sales shifting to less profitable Articles.
					</>
				),
			},
		],
	},
	{
		title: "Performance Comparison",
		sentances: [
			{
				sentance:
					"Archie Rose performed worse than the Australian Gin Segment, Gin Sub-Category and Spirits Category. Its underperformance meant its contribution to Segment sales dropped from 51.3% last year to 39.4% this year.",
			},
		],
	},
	{
		title: "Performance Drivers",
		sentances: [
			{
				sentance: (
					<>
						The main reason Archie Rose underperformed was because its
						promotional sales decreased{" "}
						<span style={{ color: "#B73125" }}> -$2.8m</span>. Its products were
						promoted less frequently and at a lower discount % than the previous
						time period.
					</>
				),
			},
			{
				sentance:
					"Two of its six Articles in particular saw lower promotional sales. These were:",
			},
			{
				bullet: (
					<>
						Signature Dry Gin 700ml (promo sales ={" "}
						<span style={{ color: "#B73125" }}>-$1.4m</span>;
						<span style={{ color: "#B73125" }}> -16%</span>)
					</>
				),
			},
			{
				bullet: (
					<>
						Bone Dry Gin 700ml (promo sales ={" "}
						<span style={{ color: "#B73125" }}>-$0.6m</span>;
						<span style={{ color: "#B73125" }}> -100%</span>)
					</>
				),
			},
		],
	},
];

export const salesMetricBlocks = [
	{
		heading: (
			<>
				Total Sales = <b style={{ fontFamily: "AptosSemiBold" }}>$27.2m</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-$1.8m
				</span>{" "}
				(
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-6.3%
				</span>
				) YOY
			</>
		),
		columnWidth: 2,
		variationDirection: "-",
	},
	{
		heading: (
			<>
				Units = <b style={{ fontFamily: "AptosSemiBold" }}>442k</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-71k
				</span>{" "}
				(
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-16%
				</span>
				) YOY
			</>
		),
		columnWidth: 1,
		variationDirection: "-",
	},
	{
		heading: (
			<>
				ASP = <b style={{ fontFamily: "AptosSemiBold" }}>$61.62</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#238755" }}>
					+$4.91
				</span>{" "}
				(
				<span className="span-bold-text" style={{ color: "#238755" }}>
					+8.6%
				</span>
				) YOY
			</>
		),
		columnWidth: 1,
		variationDirection: "+",
	},
];

export const salesDataPointsTop = [
	{
		label: "<strong>Sales</strong><br />Comparison period",
		y: 29100000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "<strong>ASP &#9651; Impact</strong><br />ASP: +$4.9 to $61.6",
		y: 2000000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>Unit &#9651; Impact</strong><br />Units: -71k to 442k",
		y: -4000000,
		indexLabelFontColor: "red",
	},
	{
		label: "<strong>Sales</strong><br />Focus period",
		y: -27200000,
		color: "grey",
		indexLabelFontColor: "white",
	},
	// { label: "Final", isCumulativeSum: true, indexLabel: "{y}", color: "#2196F3" }
];

export const salesDataPointsBottom = [
	{
		label: "",
		y: 29100000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "<strong>Article ASP<br />&#9651; Impact</strong>",
		y: 2430000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>Sales Mix<br />&#9651; Impact</strong>",
		y: -250000,
		indexLabelFontColor: "white",
	},
	{
		label: "",
		y: -4000000,
		color: "",
		indexLabelFontColor: "black",
	},
	{
		label: "",
		y: -27200000,
		color: "grey",
		indexLabelFontColor: "white",
	},
	// { label: "Final", isCumulativeSum: true, indexLabel: "{y}", color: "#2196F3" }
];

const cellStyleColorFunc = (value: any) => {
	if (value.includes("-")) {
		return { color: "red", textAlign: "center", fontSize: "12px" };
	}
	if (value === "0" || value === "0%" || value === "$0" || value === "N/A") {
		return { color: "black", textAlign: "center", fontSize: "12px" };
	}
	return { color: "green", textAlign: "center", fontSize: "12px" };
};
const cellStyleColorCogsFunc = (value: any) => {
	if (value.includes("-")) {
		return { color: "green", textAlign: "center" };
	}
	if (value === "0" || value === "0%" || value === "$0") {
		return { color: "black", textAlign: "center" };
	}
	return { color: "red", textAlign: "center" };
};

const cellStyleDefault = {
	textAlign: "center",
	fontSize: "12px",
};

const cellStyleLeft = {
	textAlign: "left",
	fontSize: "12px",
};

export const salesColDefs = [
	{ field: "sku", headerName: "SKU", cellStyle: cellStyleLeft },
	{
		headerName: "Sales Units",
		headerClass: "top-header-2",
		children: [
			{
				field: "salesUnits",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
			},
			{
				field: "salesUnitsChange",
				headerName: "YoY ∆",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
				headerClass: "header-1",
			},
			{
				field: "salesUnits%Change",
				headerName: "YoY %∆",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
				headerClass: "header-1",
			},
		],
	},
	{
		headerName: "Other Measures",
		headerClass: "top-header-1",

		children: [
			{ field: "sales", cellStyle: cellStyleDefault, headerClass: "header-2" },
			{
				field: "sales%Change",
				headerName: "YoY %∆",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},
			{
				field: "GP$",
				headerName: "GP$",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},

			{
				field: "GP%Change",
				headerName: "YoY %∆",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},
			{
				field: "GP%PerLitre",
				headerClass: "header-2",
				headerName: "GP% per Litre",
				cellStyle: cellStyleDefault,
			},
			{
				field: "GP%PerLitreChange",
				headerClass: "header-2",
				headerName: "YoY ∆",
				cellStyle: cellStyleDefault,
			},
			{
				field: "ASPPerLitre",
				headerClass: "header-2",
				headerName: "ASP per Litre",
				cellStyle: cellStyleDefault,
			},
			{
				field: "ASP%ChangePerLitre",
				headerName: "YoY %∆",
				headerClass: "header-2",
				cellStyle: cellStyleDefault,
			},
		],
	},
];

export const salesTableRows = [
	{
		sku: "Signature Dry Gin 700ml",
		salesUnits: "249,416",
		salesUnitsChange: "-29,986",
		"salesUnits%Change": "-11.20%",
		sales: "$14,874,603",
		"sales%Change": "-4.20%",
		GP$: "$2,496,498",
		"GP%Change": "-10.10%",
		"GP%PerLitre": "17.50%",
		"GP%PerLitreChange": "-1.50%",
		ASPPerLitre: "$59.64",
		"ASP%ChangePerLitre": "8.20%",
	},
	{
		sku: "Straight Dry Gin 700ml",
		salesUnits: "135,816",
		salesUnitsChange: "366",
		"salesUnits%Change": "0%",
		sales: "$8,693,619",
		"sales%Change": "-8.60%",
		GP$: "$1,620,345",
		"GP%Change": "3.50%",
		"GP%PerLitre": "19.30%",
		"GP%PerLitreChange": "-1.70%",
		ASPPerLitre: "$64.01",
		"ASP%ChangePerLitre": "8.50%",
	},
	{
		sku: "Bone Dry Gin 700ml",
		salesUnits: "51,291",
		salesUnitsChange: "-31,509",
		"salesUnits%Change": "-38.70",
		sales: "$3,106,421",
		"sales%Change": "-30.10%",
		GP$: "$454,917",
		"GP%Change": "-20.50%",
		"GP%PerLitre": "15.20%",
		"GP%PerLitreChange": "2.90%",
		ASPPerLitre: "$60.56",
		"ASP%ChangePerLitre": "14.40%",
	},
	{
		sku: "Tailored Gin 700ml",
		salesUnits: "5,646",
		salesUnitsChange: "-779",
		"salesUnits%Change": "-12.60%",
		sales: "$572,582",
		"sales%Change": "-2.80%",
		GP$: "$190,454",
		"GP%Change": "0%",
		"GP%PerLitre": "33.80%",
		"GP%PerLitreChange": "-1.80%",
		ASPPerLitre: "$101.41",
		"ASP%ChangePerLitre": "11.30%",
	},
	{
		sku: "Raw Honey Gin 700ml",
		salesUnits: "7",
		salesUnitsChange: "-8,490",
		"salesUnits%Change": "-100%",
		sales: "$412",
		"sales%Change": "-100%",
		GP$: "$60",
		"GP%Change": "-100%",
		"GP%PerLitre": "14.50%",
		"GP%PerLitreChange": "-9.40%",
		ASPPerLitre: "$58.84",
		"ASP%ChangePerLitre": "9.60%",
	},
	{
		sku: "Distillers Strength Gin 700ml",
		salesUnits: "3",
		salesUnitsChange: "-144",
		"salesUnits%Change": "-98.40%",
		sales: "$190",
		"sales%Change": "-98.40%",
		GP$: "$20",
		"GP%Change": "99.60%",
		"GP%PerLitre": "10.50%",
		"GP%PerLitreChange": "-13.20%",
		ASPPerLitre: "$68.02",
		"ASP%ChangePerLitre": "-1.40%",
	},
	{
		sku: "Total",
		salesUnits: "442,179",
		salesUnitsChange: "-70,517",
		"salesUnits%Change": "-14.20%",
		sales: "$27,247,828",
		"sales%Change": "-6.10%",
		GP$: "$4,762,293",
		"GP%Change": "-9.80%",
		"GP%PerLitre": "17.10%",
		"GP%PerLitreChange": "-1.40%",
		ASPPerLitre: "$61.62",
		"ASP%ChangePerLitre": "9.70%",
	},
];

export const gpMetricBlocks = [
	{
		heading: (
			<>
				Total GP = <b style={{ fontFamily: "AptosSemiBold" }}>$4.7m</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-$474k
				</span>{" "}
				(
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-9.1%
				</span>
				) YOY
			</>
		),
		columnWidth: 2,
		variationDirection: "-",
	},
	{
		heading: (
			<>
				COGS = <b style={{ fontFamily: "AptosSemiBold" }}>$50.85</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					+$4.35
				</span>{" "}
				(
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					+9.4%
				</span>
				) YOY
			</>
		),
		columnWidth: 1,
		variationDirection: "-",
	},
	{
		heading: (
			<>
				% Margin = <b style={{ fontFamily: "AptosSemiBold" }}>17.5%</b>
			</>
		),
		subHeading: (
			<>
				<span className="span-bold-text" style={{ color: "#B73125" }}>
					-50 bps
				</span>{" "}
				YOY
			</>
		),
		columnWidth: 1,
		variationDirection: "-",
	},
];

export const gpDataPointsTop = [
	{
		label: "<strong>GP$</strong><br />Comparison period",
		y: 5200000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "<strong>Sales &#9651; Impact</strong><br/>Sales: -$1.8m",
		y: -330000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>GP% &#9651; Impact</strong><br/>GP%: -50 bps to 17.5%",
		y: -140000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>GP$</strong><br />Focus period",
		y: -4700000,
		color: "grey",
		indexLabelFontColor: "white",
	},
];
export const gpDataPointsBottom = [
	{
		label: "",
		y: 5200000,
		indexLabelFontColor: "white",
		color: "grey",
	},
	{
		label: "",
		y: -330000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>COGS &#9651; Impact</strong>",
		y: -2160000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>ASP &#9651; Impact</strong>",
		y: 2300000,
		indexLabelFontColor: "white",
	},
	{
		label: "<strong>Mix &#9651; Impact</strong>",
		y: -300000,
		indexLabelFontColor: "white",
	},
	{
		label: "",
		y: -4700000,
		color: "grey",
		indexLabelFontColor: "white",
	},
];

export const gpColDefs = [
	{ field: "sku", headerName: "SKU", cellStyle: cellStyleLeft },
	{
		headerName: "COGS",
		headerClass: "top-header-2",

		children: [
			{
				field: "COGSPerLitre",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
			},
			{
				field: "YOY$Change",
				cellStyle: (params: any) => {
					const styles = cellStyleColorCogsFunc(params.value);
					return styles;
				},
				headerName: "YoY $∆",
				headerClass: "header-1",
			},
			{
				field: "YOYChange%",
				cellStyle: (params: any) => {
					const styles = cellStyleColorCogsFunc(params.value);
					return styles;
				},
				headerName: "YoY %∆",
				headerClass: "header-1",
			},
		],
	},
	{
		headerName: "Other Measures",
		headerClass: "top-header-1",
		children: [
			{
				field: "sales",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},
			{
				field: "sales%Change",
				cellStyle: cellStyleDefault,
				headerName: "YoY %∆",
				headerClass: "header-2",
			},
			{ field: "GP$", cellStyle: cellStyleDefault, headerClass: "header-2" },
			{
				field: "GP$YOY%Change",
				cellStyle: cellStyleDefault,
				headerName: "YoY %∆",
				headerClass: "header-2",
			},
			{
				field: "GP%ChangePerLitre",
				cellStyle: cellStyleDefault,
				headerName: "GP% Per Litre",
				headerClass: "header-2",
			},
			{
				field: "GPYOYPerLitreChange%",
				cellStyle: cellStyleDefault,
				headerName: "YoY ∆",
				headerClass: "header-2",
			},
			{
				field: "ASPPerLitre",
				cellStyle: cellStyleDefault,
				headerName: "ASP Litre",
				headerClass: "header-2",
			},
			{
				field: "ASPYOY%",
				cellStyle: cellStyleDefault,
				headerName: "YoY %∆",
				headerClass: "header-2",
			},
		],
	},
];

export const gpTableRows = [
	{
		sku: "Signature Dry Gin 700ml",
		COGSPerLitre: "$49.63",
		YOY$Change: "$4.25",
		"YOYChange%": "9.30%",
		sales: "$14,974,603",
		"sales%Change": "-4.20%",
		GP$: "$2,496,498",
		"GP$YOY%Change": "-10.10%",
		"GP%ChangePerLitre": "17.50%",
		"GPYOYPerLitreChange%": "-1.50%",
		ASPPerLitre: "$59.64",
		"ASPYOY%": "8.20%",
	},
	{
		sku: "Straight Dry Gin 700ml",
		COGSPerLitre: "$52.08",
		YOY$Change: "$4.15",
		"YOYChange%": "9.10%",
		sales: "$8,693,619",
		"sales%Change": "8.60%",
		GP$: "$1,602,345",
		"GP$YOY%Change": "3.50%",
		"GP%ChangePerLitre": "19.30%",
		"GPYOYPerLitreChange%": "-1.7%",
		ASPPerLitre: "$64.01",
		"ASPYOY%": "8.50%",
	},
	{
		sku: "Bone Dry Gin 700ml",
		COGSPerLitre: "$51.70",
		YOY$Change: "$5.27",
		"YOYChange%": "11.60%",
		sales: "$3,106,421",
		"sales%Change": "-30.10%",
		GP$: "$454,917",
		"GP$YOY%Change": "20.50%",
		"GP%ChangePerLitre": "15.20%",
		"GPYOYPerLitreChange%": "2.90%",
		ASPPerLitre: "$60.56",
		"ASPYOY%": "14.40%",
	},
	{
		sku: "Tailored Gin 700ml",
		COGSPerLitre: "$67.68",
		YOY$Change: "$6.26",
		"YOYChange%": "10.40%",
		sales: "$572,582",
		"sales%Change": "-2.80%",
		GP$: "$190,454",
		"GP$YOY%Change": "0%",
		"GP%ChangePerLitre": "33.80%",
		"GPYOYPerLitreChange%": "1.80%",
		ASPPerLitre: "$101.41",
		"ASPYOY%": "11.30%",
	},
	{
		sku: "Raw Honey Gin 700ml",
		COGSPerLitre: "$50.32",
		YOY$Change: "$0.35",
		"YOYChange%": "1.70%",
		sales: "$412",
		"sales%Change": "-100%",
		GP$: "$60",
		"GP$YOY%Change": "-100%",
		"GP%ChangePerLitre": "14.50%",
		"GPYOYPerLitreChange%": "-9.40%",
		ASPPerLitre: "$58.84",
		"ASPYOY%": "-9.60%",
	},
	{
		sku: "Distillers Strength Gin 700ml",
		COGSPerLitre: "$60.92",
		YOY$Change: "$8.18",
		"YOYChange%": "16.10%",
		sales: "$190",
		"sales%Change": "-98.40%",
		GP$: "$20",
		"GP$YOY%Change": "-99.60%",
		"GP%ChangePerLitre": "10.50%",
		"GPYOYPerLitreChange%": "-13.20%",
		ASPPerLitre: "$68.02",
		"ASPYOY%": "-1.40%",
	},
];

export const performanceComparisonPara = [
	"Archie Rose was the biggest supplier in the Australian Gin Segment for both sales and GP$.",
	"But it underperformed the Segment YOY so its contribution to Segment sales and GP$ decreased.",
	<>
		Its sales made up 39.4% of the Segment, down{" "}
		<span style={{ color: "#B73125" }}>-11.9 ppt</span> from last year. Its GP$
		made up 36.4% of Segment GP$, down{" "}
		<span style={{ color: "#B73125" }}>-5.6 ppt</span> from last year.
	</>,
	"It also underperformed the Gin Sub-Category and the Spirits Category. Comparative underperformance is below:",
];

export const performanceComparisonTableColDefs = [
	{ field: "breakdown", headerName: "", cellStyle: cellStyleLeft },
	{
		field: "sales",
		headerName: "Sales (m)",
		cellStyle: cellStyleDefault,
		headerClass: "header-2",
	},
	{
		field: "salesChange",
		headerName: "Change",
		cellStyle: (params: any) => {
			const styles = cellStyleColorFunc(params.value);
			return styles;
		},
		headerClass: "header-2",
	},
	{
		field: "tradeGp",
		headerName: "Trade GP (m)",
		cellStyle: cellStyleDefault,
		headerClass: "header-2",
	},
	{
		field: "tradeGpChange",
		headerName: "Change",
		cellStyle: (params: any) => {
			const styles = cellStyleColorFunc(params.value);
			return styles;
		},
		headerClass: "header-2",
	},
	{
		field: "gp%",
		headerName: "GP%",
		cellStyle: cellStyleDefault,
		headerClass: "header-2",
	},
	{
		field: "change(bps)",
		headerName: "Change (bps)",
		cellStyle: cellStyleDefault,
		headerClass: "header-2",
	},
	{
		field: "unitSales(m)",
		headerName: "Unit Sales (m)",
		cellStyle: cellStyleDefault,
		headerClass: "header-2",
	},
	{
		field: "unitSalesChange",
		headerName: "Change",
		headerClass: "header-2",
		cellStyle: (params: any) => {
			const styles = cellStyleColorFunc(params.value);
			return styles;
		},
	},
];

export const performanceComparisonTable = [
	{
		breakdown: "Archie Rose",
		sales: "$27.20",
		salesChange: "-6%",
		tradeGp: "$4.70",
		tradeGpChange: "-9%",
		"gp%": "17.50%",
		"change(bps)": "-50",
		"unitSales(m)": "0.4",
		unitSalesChange: "-14%",
	},
	{
		breakdown: "Rest of Australian Gin Segment",
		sales: "$41.90",
		salesChange: "5%",
		tradeGp: "$8.20",
		tradeGpChange: "8%",
		"gp%": "19.60%",
		"change(bps)": "54",
		"unitSales(m)": "0.7",
		unitSalesChange: "-3%",
	},
	{
		breakdown: "Gin Sub-Category",
		sales: "$92.80",
		salesChange: "0%",
		tradeGp: "$18.50",
		tradeGpChange: "3%",
		"gp%": "19.90%",
		"change(bps)": "58",
		"unitSales(m)": "1.4",
		unitSalesChange: "-7%",
	},
	{
		breakdown: "Spirits Category",
		sales: "$513",
		salesChange: "-1%",
		tradeGp: "$134",
		tradeGpChange: "0%",
		"gp%": "26.10%",
		"change(bps)": "20",
		"unitSales(m)": "8.8",
		unitSalesChange: "-6%",
	},
];

export const skuComparisonPara = [
	{
		sentance:
			"Archie Rose's underperformance was mainly caused by two of its six Articles: Signature Dry Gin 700ml and Bone Dry Gin 700ml.",
	},
	{
		bullet: (
			<>
				Signature Dry Gin 700ml: sales were $14.9m, decreasing{" "}
				<span style={{ color: "#B73125" }}>-$0.60m</span> (
				<span style={{ color: "#B73125" }}>-4.2%</span>), and GP$ was $2.5m,
				decreasing <span style={{ color: "#B73125" }}>-$0.28m</span> (
				<span style={{ color: "#B73125" }}>-10.10%</span>).
			</>
		),
	},
	{
		bullet: (
			<>
				Bone Dry Gin 700ml: sales were $3.1m, decreasing{" "}
				<span style={{ color: "#B73125" }}>-$1.3m</span> (
				<span style={{ color: "#B73125" }}>-29.5%</span>) YOY, and GP$ was
				$0.45m, decreasing <span style={{ color: "#B73125" }}>-$0.12m</span> (
				<span style={{ color: "#B73125" }}>-20.5%</span>) YOY.
			</>
		),
	},
	{
		sentance:
			"Both Articles decreased mainly because they sold less on promotion. They were promoted less frequently and at a lower discount % than last year. See promotions analysis below.  ",
	},
	{
		sentance:
			"Straight Dry Gin was the only SKU saw positive sales and GP growth. This was mainly because of increased ranging across the store network. See analysis below.",
	},
	{
		bullet: (
			<>
				Straight Dry Gin 700ml: sales were $8.7m, increasing{" "}
				<span style={{ color: "#238755" }}>+0.69m</span> (
				<span style={{ color: "#238755" }}>+8.6%</span>) YOY. GP$ were $1.6m,
				increasing <span style={{ color: "#238755" }}>+0.05m</span> (
				<span style={{ color: "#238755" }}>+3.5%</span>).
			</>
		),
	},
];

export const performanceDrivers = [
	{
		title: "Product and Store",
		blocks: [
			{
				heading: "Promotions",
				variationDirection: "-",
				paragraph: (
					<>
						Promotional sales fell{" "}
						<span style={{ color: "#B73125" }}>-$2.8m</span> (
						<span style={{ color: "#B73125" }}>-20%</span>). Promotional GP$
						fell <span style={{ color: "#B73125" }}>-$629k</span> (
						<span style={{ color: "#B73125" }}>-23%</span>)
					</>
				),
				details: [
					{ heading: "Deep Dive: Promotions" },
					{
						sentance:
							"Promo sales had the largest negative impact on Archie Rose's performance.",
					},
					{
						bullet: (
							<>
								Promotional sales decreased{" "}
								<span style={{ color: "#B73125" }}>-$2.8m</span> (
								<span style={{ color: "#B73125" }}>-20%</span>), with its share
								of total sales decreasing{" "}
								<span style={{ color: "#B73125" }}>-7pp</span>.
							</>
						),
					},
					{
						bullet: (
							<>
								Promotional GP$ decreased{" "}
								<span style={{ color: "#B73125" }}>-$629k</span> (
								<span style={{ color: "#B73125" }}>-23%</span>), with its share
								of total GP$ decreasing{" "}
								<span style={{ color: "#B73125" }}>-7pp</span>.
							</>
						),
					},
					{
						sentance:
							"The frequency of promotions decreased (-1.1 week) and the discount level decreased (-342bps) between the time periods. The biggest impact was on Signature Dry Gin 700ml (sales = $7.2m; -16%) which accounted for 50.3% of the change.",
					},
					{
						sentance: "The table below detail these changes",
					},
				],
			},
			{
				heading: "New/Deleted Articles",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Store Ranging",
				variationDirection: "+",
				paragraph: (
					<>
						Increased store network availability contributed{" "}
						<span style={{ color: "#238755" }}>+$316,403</span> in sales, and
						<span style={{ color: "#238755" }}> $47,590</span> in GP$, to Archie
						Rose's performance.
					</>
				),
				details: [
					{ heading: "Deep Dive: Store Ranging" },
					{
						sentance: (
							<>
								Increased stocking of Archie Rose's products across the store
								network contributed{" "}
								<span style={{ color: "#238755" }}>+316k</span> to its sales.
								Like-for-like sales decreased{" "}
								<span style={{ color: "#B73125" }}>-$2.4m</span>.
							</>
						),
					},
					{
						sentance: "The majority of this impact was in two Articles:",
					},
					{
						bullet: (
							<>
								Straight Dry Gin 700ml: increased stocking contributed{" "}
								<span style={{ color: "#238755" }}>+$418k</span> to its sales.
								It was ranged stocked in{" "}
								<span style={{ color: "#238755" }}>+129</span> more stores YOY.
							</>
						),
					},
					{
						bullet: (
							<>
								Tailored Gin 200ml: increased stocking contributed{" "}
								<span style={{ color: "#238755" }}>+$119k</span> to its sales.
								It was ranged in <span style={{ color: "#238755" }}>+20</span>{" "}
								more stores YOY.
							</>
						),
					},
					{
						sentance:
							"Stocking changes in other stores had a smaller, or negative effect.",
					},
					{
						sentance: "The tables below detail these changes.",
					},
				],
			},
			{
				heading: "New/Closed Stores",
				variationDirection: null,
				paragraph: "Minor positive changes",
				details: [],
			},
		],
	},
	{
		title: "Channel and Customer",
		blocks: [
			{
				heading: "Channel",
				variationDirection: null,
				paragraph: "No major shift between digital and store channels",
				details: [],
			},
			{
				heading: "Customer",
				variationDirection: null,
				paragraph: "No major change to customer generation or affluence",
				details: [],
			},
		],
	},
	{
		title: "Weather, Events and Holidays",
		blocks: [
			{
				heading: "Weather",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Events",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
			{
				heading: "Holidays",
				paragraph: "Minor positive changes",
				variationDirection: null,
				details: [],
			},
		],
	},
];

export const promotionsTableColDefs = [
	{ field: "sku", hide: true },
	{
		headerName: "Other Measures",
		headerClass: "top-header-1",
		children: [
			{
				field: "totalSales",
				headerClass: "header-2",

				headerName: "Total Sales TY (m)",
				cellStyle: cellStyleDefault,
			},
			{
				field: "gp%Ty",
				headerClass: "header-2",

				headerName: "GP% TY",
				cellStyle: cellStyleDefault,
			},
			{
				field: "rrpTy",
				headerClass: "header-2",

				headerName: "RRP TY",
				cellStyle: cellStyleDefault,
			},
		],
	},
	{
		headerName: "PROMO SALES",
		headerClass: "top-header-2",
		children: [
			{
				field: "promoSalesChange$",
				headerName: "Promo Sales ∆ YoY (m)",
				headerClass: "header-1",

				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				field: "promoSalesTy",
				headerName: "Promo Sales TY (m)",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
			},
			{
				field: "%shareTotalSalesTy",
				headerName: "% share of total Sales TY",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
			},
			{
				field: "salesPpChangeInShareYoy",
				headerName: "PP ∆ in share YoY",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
				columnGroupShow: "open",
				headerClass: "header-1",
			},
			{
				field: "promoSalesLy",
				headerName: "Promo Sales LY (m)",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
				headerClass: "header-1",
			},
			{
				field: "%TotalSalesLy",
				headerName: "% of Total Sales LY",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
				headerClass: "header-1",
			},
		],
	},
	{
		headerName: "PROMO GP$",
		headerClass: "top-header-2",
		children: [
			{
				field: "gpChangeYoy",
				headerClass: "header-1",
				headerName: "Promo GP ∆ YoY (m)",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				headerClass: "header-1",
				field: "gpChange$Ty",
				headerName: "Promo GP$ TY (m)",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "%ShareTotalGp$Ty",
				headerName: "% share of total GP$ TY (m)",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "gp$PpChangeinShareYoy",
				headerName: "PP ∆ in share YoY",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
				columnGroupShow: "open",
			},
			{
				headerClass: "header-1",
				field: "promoGp$Ly",
				headerName: "Promo GP$ LY (m)",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
			},
			{
				headerClass: "header-1",
				field: "%TotalGp$Ly",
				headerName: "% of Total GP$ LY",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
			},
		],
	},
	{
		headerClass: "top-header-2",
		headerName: "PROMO GP%",
		children: [
			{
				headerClass: "header-1",
				field: "promoGp%Ty",
				headerName: "Promo GP% TY",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "gp%BpsChangeYoY",
				headerName: "Bps ∆ YoY",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				headerClass: "header-1",
				field: "promoGp%Ly",
				headerName: "Promo GP% LY",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
			},
		],
	},
	{
		headerClass: "top-header-2",
		headerName: "PROMO ASP",
		children: [
			{
				headerClass: "header-1",
				field: "aspTy",
				headerName: "ASP TY",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "asp%ChangeYoy",
				headerName: "% ∆ YoY",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				headerClass: "header-1",
				field: "aspLy",
				headerName: "ASP LY",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
			},
		],
	},

	{
		headerClass: "top-header-2",
		headerName: "DISCOUNT DEPTH",
		children: [
			{
				headerClass: "header-1",
				field: "avg%DiscountTy",
				headerName: "Average % Discount TY",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "discountBpsChangeYoy",
				headerName: "Bps ∆ YoY",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				headerClass: "header-1",
				field: "avg%DiscLy",
				headerName: "Average % Discount LY",
				cellStyle: cellStyleDefault,
				columnGroupShow: "open",
			},
		],
	},
	{
		headerClass: "top-header-2",
		headerName: "PROMO FREQUENCY",
		children: [
			{
				headerClass: "header-1",
				field: "#storesWithPromosales",
				headerName: "# Stores with promo sales",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "storesPromoYoyChange",
				headerName: "YoY ∆",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				headerClass: "header-1",
				field: "weeksWithPromo",
				headerName: "# Weeks with promo sales",
				cellStyle: cellStyleDefault,
			},
			{
				headerClass: "header-1",
				field: "weeksWithPromoYoyChange",
				headerName: "YoY ∆",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
		],
	},
];

export const promotionsTableRows = [
	{
		path: ["All Articles"],
		sku: "All Articles",
		totalSales: "$27.2",
		"gp%Ty": "17%",
		rrpTy: "$60.54",
		promoSalesChange$: "-$2.78",
		promoSalesTy: "$11.00",
		"%shareTotalSalesTy": "40%",
		salesPpChangeInShareYoy: "-7",
		promoSalesLy: "$13.78",
		"%TotalSalesLy": "47%",
		gpChangeYoy: "-$0.63",
		gpChange$Ty: "$2.00",
		"%ShareTotalGp$Ty": "42%",
		gp$PpChangeinShareYoy: "-7",
		promoGp$Ly: "$2.6",
		"%TotalGp$Ly": "49%",
		"promoGp%Ty": "18%",
		"gp%BpsChangeYoY": "118",
		"promoGp%Ly": "17%",
		aspTy: "$52.13",
		"asp%ChangeYoy": "8%",
		aspLy: "$48.05",
		"avg%DiscountTy": "15%",
		discountBpsChangeYoy: "-342",
		"avg%DiscLy": "18%",
		"#storesWithPromosales": "1706",
		storesPromoYoyChange: "1",
		weeksWithPromo: "12.2",
		weeksWithPromoYoyChange: "-1.1",
	},
	{
		path: ["Signature Dry Gin 700ml"],
		sku: "Signature Dry Gin 700ml",
		totalSales: "$14.9",
		"gp%Ty": "17%",
		rrpTy: "$63.21",
		promoSalesChange$: "-$1.40",
		promoSalesTy: "$7.20",
		"%shareTotalSalesTy": "47%",
		salesPpChangeInShareYoy: "-7%",
		promoSalesLy: "$8.60",
		"%TotalSalesLy": "56%",
		gpChangeYoy: "-$0.30",
		gpChange$Ty: "$1.30",
		"%ShareTotalGp$Ty": "52%",
		gp$PpChangeinShareYoy: "-6%",
		promoGp$Ly: "$1.6",
		"%TotalGp$Ly": "58%",
		"promoGp%Ty": "18%",
		"gp%BpsChangeYoY": "106",
		"promoGp%Ly": "17%",
		aspTy: "$53.73",
		"asp%ChangeYoy": "7%",
		aspLy: "$50.01",
		"avg%DiscountTy": "15%",
		discountBpsChangeYoy: "-442",
		"avg%DiscLy": "19%",
		"#storesWithPromosales": "1706",
		storesPromoYoyChange: "26",
		weeksWithPromo: "11.4",
		weeksWithPromoYoyChange: "-1.1",
	},
	{
		path: ["Signature Dry Gin 700ml", "BWS"],
		sku: "Signature Dry Gin 700ml",
		totalSales: "$13.0",
		"gp%Ty": "15%",
		rrpTy: "$65.74",
		promoSalesChange$: "-$1.02",
		promoSalesTy: "$6.40",
		"%shareTotalSalesTy": "49%",
		salesPpChangeInShareYoy: "-8",
		promoSalesLy: "$7.42",
		"%TotalSalesLy": "57%",
		gpChangeYoy: "-$0.23",
		gpChange$Ty: "$1.20",
		"%ShareTotalGp$Ty": "60%",
		gp$PpChangeinShareYoy: "-12%",
		promoGp$Ly: "$1.43",
		"%TotalGp$Ly": "72%",
		"promoGp%Ty": "19%",
		"gp%BpsChangeYoY": "75",
		"promoGp%Ly": "18%",
		aspTy: "$53.91",
		"asp%ChangeYoy": "7%",
		aspLy: "$50.19",
		"avg%DiscountTy": "18%",
		discountBpsChangeYoy: "-502",
		"avg%DiscLy": "23%",
		"#storesWithPromosales": "1444",
		storesPromoYoyChange: "19",
		weeksWithPromo: "12.6",
		weeksWithPromoYoyChange: "-0.2",
	},
	{
		path: ["Signature Dry Gin 700ml", "DM"],
		sku: "Signature Dry Gin 700ml",
		totalSales: "$3.7",
		"gp%Ty": "14%",
		rrpTy: "$55.03",
		promoSalesChange$: "-$0.36",
		promoSalesTy: "$0.80",
		"%shareTotalSalesTy": "22%",
		salesPpChangeInShareYoy: "-7",
		promoSalesLy: "$1.16",
		"%TotalSalesLy": "29%",
		gpChangeYoy: "-$0.07",
		gpChange$Ty: "$0.08",
		"%ShareTotalGp$Ty": "16%",
		gp$PpChangeinShareYoy: "-3%",
		promoGp$Ly: "$0.15",
		"%TotalGp$Ly": "20%",
		"promoGp%Ty": "10%",
		"gp%BpsChangeYoY": "300",
		"promoGp%Ly": "7%",
		aspTy: "$52.28",
		"asp%ChangeYoy": "7%",
		aspLy: "$48.93",
		"avg%DiscountTy": "5%",
		discountBpsChangeYoy: "34",
		"avg%DiscLy": "5%",
		"#storesWithPromosales": "262",
		storesPromoYoyChange: "7",
		weeksWithPromo: "5.0",
		weeksWithPromoYoyChange: "-3.9",
	},
	{
		path: ["Bone Dry Gin 700ml"],
		sku: "Bone Dry Gin 700ml",
		totalSales: "$3.1",
		"gp%Ty": "15%",
		rrpTy: "$76.39",
		promoSalesChange$: "-$0.60",
		promoSalesTy: "$0.60",
		"%shareTotalSalesTy": "19%",
		salesPpChangeInShareYoy: "-8",
		promoSalesLy: "$1.20",
		"%TotalSalesLy": "27%",
		gpChangeYoy: "-$0.09",
		gpChange$Ty: "$0.08",
		"%ShareTotalGp$Ty": "18%",
		gp$PpChangeinShareYoy: "-13%",
		promoGp$Ly: "$0.17",
		"%TotalGp$Ly": "31%",
		"promoGp%Ty": "13%",
		"gp%BpsChangeYoY": "233",
		"promoGp%Ly": "11%",
		aspTy: "$60.35",
		"asp%ChangeYoy": "13%",
		aspLy: "$53.58",
		"avg%DiscountTy": "21%",
		discountBpsChangeYoy: "-10",
		"avg%DiscLy": "21%",
		"#storesWithPromosales": "264",
		storesPromoYoyChange: "9",
		weeksWithPromo: "4.8",
		weeksWithPromoYoyChange: "-3.1",
	},
	{
		path: ["Straight Dry Gin 700ml"],
		sku: "Straight Dry Gin 700ml",
		totalSales: "$8.7",
		"gp%Ty": "19%",
		rrpTy: "$46.41",
		promoSalesChange$: "-$0.60",
		promoSalesTy: "$3.20",
		"%shareTotalSalesTy": "337%",
		salesPpChangeInShareYoy: "-10",
		promoSalesLy: "$3.80",
		"%TotalSalesLy": "47%",
		gpChangeYoy: "-$0.14",
		gpChange$Ty: "$0.66",
		"%ShareTotalGp$Ty": "41%",
		gp$PpChangeinShareYoy: "-7%",
		promoGp$Ly: "$0.80",
		"%TotalGp$Ly": "48%",
		"promoGp%Ty": "21%",
		"gp%BpsChangeYoY": "-43",
		"promoGp%Ly": "21%",
		aspTy: "$40.38",
		"asp%ChangeYoy": "8%",
		aspLy: "$37.54",
		"avg%DiscountTy": "13%",
		discountBpsChangeYoy: "-30",
		"avg%DiscLy": "13%",
		"#storesWithPromosales": "1448",
		storesPromoYoyChange: "-234",
		weeksWithPromo: "12.0",
		weeksWithPromoYoyChange: "0.6",
	},
	{
		path: ["Raw Honey Gin 700ml"],
		sku: "Raw Honey Gin 700ml",
		totalSales: "$0",
		"gp%Ty": "15%",
		rrpTy: "$58.86",
		promoSalesChange$: "-$0.18",
		promoSalesTy: "$0",
		"%shareTotalSalesTy": "0%",
		salesPpChangeInShareYoy: "-36",
		promoSalesLy: "$0.18",
		"%TotalSalesLy": "36%",
		gpChangeYoy: "-$0.04",
		gpChange$Ty: "$0",
		"%ShareTotalGp$Ty": "0%",
		gp$PpChangeinShareYoy: "31%",
		promoGp$Ly: "$0.04",
		"%TotalGp$Ly": "31%",
		"promoGp%Ty": "N/A",
		"gp%BpsChangeYoY": "N/A",
		"promoGp%Ly": "22%",
		aspTy: "N/A",
		"asp%ChangeYoy": "N/A",
		aspLy: "N/A",
		"avg%DiscountTy": "N/A",
		discountBpsChangeYoy: "N/A",
		"avg%DiscLy": "N/A",
		"#storesWithPromosales": "0",
		storesPromoYoyChange: "-1090",
		weeksWithPromo: "0",
		weeksWithPromoYoyChange: "-3.7",
	},
	{
		path: ["Distillers Strength Gin 700ml"],
		sku: "Distillers Strength Gin 700ml",
		totalSales: "$0",
		"gp%Ty": "11%",
		rrpTy: "$63.33",
		promoSalesChange$: "$0",
		promoSalesTy: "$0",
		"%shareTotalSalesTy": "0%",
		salesPpChangeInShareYoy: "0",
		promoSalesLy: "$0.00",
		"%TotalSalesLy": "0%",
		gpChangeYoy: "-$0.00",
		gpChange$Ty: "$0",
		"%ShareTotalGp$Ty": "0%",
		gp$PpChangeinShareYoy: "0%",
		promoGp$Ly: "$0.00",
		"%TotalGp$Ly": "0%",
		"promoGp%Ty": "N/A",
		"gp%BpsChangeYoY": "N/A",
		"promoGp%Ly": "N/A",
		aspTy: "N/A",
		"asp%ChangeYoy": "N/A",
		aspLy: "N/A",
		"avg%DiscountTy": "N/A",
		discountBpsChangeYoy: "N/A",
		"avg%DiscLy": "N/A",
		"#storesWithPromosales": "0",
		storesPromoYoyChange: "0",
		weeksWithPromo: "0",
		weeksWithPromoYoyChange: "0",
	},
	{
		path: ["Tailored Gin 700ml"],
		sku: "Tailored Gin 700ml",
		totalSales: "$0.6",
		"gp%Ty": "33%",
		rrpTy: "$36.60",
		promoSalesChange$: "$0",
		promoSalesTy: "$0",
		"%shareTotalSalesTy": "0%",
		salesPpChangeInShareYoy: "0",
		promoSalesLy: "$0.00",
		"%TotalSalesLy": "0%",
		gpChangeYoy: "-$0.00",
		gpChange$Ty: "$0",
		"%ShareTotalGp$Ty": "0%",
		gp$PpChangeinShareYoy: "0%",
		promoGp$Ly: "$0.00",
		"%TotalGp$Ly": "0%",
		"promoGp%Ty": "N/A",
		"gp%BpsChangeYoY": "N/A",
		"promoGp%Ly": "N/A",
		aspTy: "N/A",
		"asp%ChangeYoy": "N/A",
		aspLy: "N/A",
		"avg%DiscountTy": "N/A",
		discountBpsChangeYoy: "N/A",
		"avg%DiscLy": "N/A",
		"#storesWithPromosales": "0",
		storesPromoYoyChange: "0",
		weeksWithPromo: "0",
		weeksWithPromoYoyChange: "0",
	},
];

export const rangingTableColDefs = [
	{ field: "sku", headerName: "SKU", hide: true },
	{
		headerName: "AVERAGE BY STORE",
		headerClass: "top-header-1",
		children: [
			{
				field: "avgWeeklySalesByStoreTy",
				headerName: "Average Weekly Sales by Store TY",
				headerClass: "header-2",
				cellStyle: cellStyleDefault,
			},
			{
				field: "WeeklySales%ChangeYoy",
				headerName: "% ∆ YoY",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},
			{
				field: "avgWeeklySalesByStoreLy",
				cellStyle: cellStyleDefault,
				headerName: "Average Weekly Sales by Store LY",
				columnGroupShow: "open",
				headerClass: "header-2",
			},
			{
				field: "avgWeeklyUnitsByStore",
				cellStyle: cellStyleDefault,
				headerName: "Average Weekly Units by Store",
				headerClass: "header-2",
			},
			{
				field: "avgWeeklyUnitsChangeYoy",
				cellStyle: cellStyleDefault,
				headerName: "% ∆ YoY",
				headerClass: "header-2",
			},
		],
	},
	{
		headerName: "TOTAL SALES",
		headerClass: "top-header-1",
		children: [
			{
				field: "totalSalesTy",
				headerName: "Total Sales TY",
				cellStyle: cellStyleDefault,
				headerClass: "header-2",
			},
			{
				field: "totalSales%ChangeYoy",
				cellStyle: cellStyleDefault,
				headerName: "% ∆ YoY",
				headerClass: "header-2",
			},
			{
				field: "salesLy",
				cellStyle: cellStyleDefault,
				headerName: "Sales LY",
				columnGroupShow: "open",
				headerClass: "header-2",
			},
		],
	},
	{
		headerName: "STORE RANGING CONTRIBUTION",
		headerClass: "top-header-2",
		children: [
			{
				field: "storeRangingImpact",
				headerName: "Store Ranging Impact",
				headerClass: "header-1",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				field: "totalStoresRangedTy",
				cellStyle: cellStyleDefault,
				headerName: "Total Stores Ranged TY",
				headerClass: "header-1",
			},
			{
				field: "%StoreNetwork",
				cellStyle: cellStyleDefault,
				headerName: "% of Store Network",
				headerClass: "header-1",
			},
			{
				field: "storeNetwork%ChangeYoy",
				cellStyle: cellStyleDefault,
				headerName: "% ∆ YoY",
				headerClass: "header-1",
			},
			{
				field: "#StoresRangeChange",
				headerName: "# Stores Range Change (same Articles, Stores, Week)",
				width: 300,
				columnGroupShow: "open",
				headerClass: "header-1",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				field: "TotalStoresRangedLy",
				cellStyle: cellStyleDefault,
				headerName: "Total Stores Ranged LY",
				headerClass: "header-1",
				columnGroupShow: "open",
			},
			{
				field: "AvgWeekAvailableTy",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
				headerName: "Average Weeks Available TY",
			},
			{
				headerClass: "header-1",
				cellStyle: cellStyleDefault,
				field: "AvgWeekAvailable%ChangeYoy",
				headerName: "% ∆ YoY",
			},
			{
				headerClass: "header-1",
				field: "#WeeksAvailChange",
				headerName: "# Weeks Availability Change",
				columnGroupShow: "open",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				field: "AvgWeeksAvailLy",
				cellStyle: cellStyleDefault,
				headerClass: "header-1",
				headerName: "Average Weeks Available LY",
				columnGroupShow: "open",
			},
		],
	},
	{
		headerName: "OTHER CONTRIBUTIONS",
		headerClass: "top-header-1",
		children: [
			{
				field: "like-For-Like",
				headerName: "Like-for-like",
				headerClass: "header-2",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
			{
				field: "OtherChanges",
				headerName: "Other Changes (new/closed stores)",
				headerClass: "header-2",
				cellStyle: (params: any) => {
					const styles = cellStyleColorFunc(params.value);
					return styles;
				},
			},
		],
	},
];

export const rangingTableRows = [
	{
		path: ["All Articles"],
		sku: "All Articles",
		avgWeeklySalesByStoreTy: "$423",
		"WeeklySales%ChangeYoy": "-6%",
		avgWeeklySalesByStoreLy: "$449",
		avgWeeklyUnitsByStore: "8",
		avgWeeklyUnitsChangeYoy: "-6%",
		totalSalesTy: "$27,247,828",
		"totalSales%ChangeYoy": "-6%",
		salesLy: "$29,077,467",
		storeRangingImpact: "$316,403",
		totalStoresRangedTy: "N/A",
		"%StoreNetwork": "N/A",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "N/A",
		TotalStoresRangedLy: "N/A",
		AvgWeekAvailableTy: "N/A",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "N/A",
		AvgWeeksAvailLy: "N/A",
		"like-For-Like": "-$2,395,418",
		OtherChanges: "$249,375",
	},
	{
		path: ["Straight Dry Gin 700ml"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$436",
		"WeeklySales%ChangeYoy": "1%",
		avgWeeklySalesByStoreLy: "$430",
		avgWeeklyUnitsByStore: "9",
		avgWeeklyUnitsChangeYoy: "2%",
		totalSalesTy: "$8,693,619",
		"totalSales%ChangeYoy": "8%",
		salesLy: "$8,064,973",
		storeRangingImpact: "$417,965",
		totalStoresRangedTy: "1715",
		"%StoreNetwork": "100%",
		"storeNetwork%ChangeYoy": "8%",
		"#StoresRangeChange": "129",
		TotalStoresRangedLy: "1586",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "-2%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "-$143,847",
		OtherChanges: "$66,834",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$414",
		"WeeklySales%ChangeYoy": "26%",
		avgWeeklySalesByStoreLy: "$329",
		avgWeeklyUnitsByStore: "8",
		avgWeeklyUnitsChangeYoy: "27%",
		totalSalesTy: "$5,861,821",
		"totalSales%ChangeYoy": "11%",
		salesLy: "$5,298,423",
		storeRangingImpact: "$414,584",
		totalStoresRangedTy: "1515",
		"%StoreNetwork": "88%",
		"storeNetwork%ChangeYoy": "9%",
		"#StoresRangeChange": "120",
		TotalStoresRangedLy: "1395",
		AvgWeekAvailableTy: "9",
		"AvgWeekAvailable%ChangeYoy": "-19%",
		"#WeeksAvailChange": "-2",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "-$98,153",
		OtherChanges: "$50,661",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$585",
		"WeeklySales%ChangeYoy": "12%",
		avgWeeklySalesByStoreLy: "$523",
		avgWeeklyUnitsByStore: "12",
		avgWeeklyUnitsChangeYoy: "13%",
		totalSalesTy: "$1,710,516",
		"totalSales%ChangeYoy": "38%",
		salesLy: "$1,237,057",
		storeRangingImpact: "$393,854",
		totalStoresRangedTy: "311",
		"%StoreNetwork": "18%",
		"storeNetwork%ChangeYoy": "56%",
		"#StoresRangeChange": "112",
		TotalStoresRangedLy: "199",
		AvgWeekAvailableTy: "9",
		"AvgWeekAvailable%ChangeYoy": "-21%",
		"#WeeksAvailChange": "-3",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "-$22,916",
		OtherChanges: "$56,689",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD", "Cloncurry DBS"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$1,711",
		"WeeklySales%ChangeYoy": "N/A",
		avgWeeklySalesByStoreLy: "$0",
		avgWeeklyUnitsByStore: "34",
		avgWeeklyUnitsChangeYoy: "N/A",
		totalSalesTy: "$11,977",
		"totalSales%ChangeYoy": "N/A",
		salesLy: "$0",
		storeRangingImpact: "$11,977",
		totalStoresRangedTy: "1",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "0",
		AvgWeekAvailableTy: "7",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "7",
		AvgWeeksAvailLy: "0",
		"like-For-Like": "$0",
		OtherChanges: "$0",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD", "Samford"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$1,692",
		"WeeklySales%ChangeYoy": "N/A",
		avgWeeklySalesByStoreLy: "$0",
		avgWeeklyUnitsByStore: "34",
		avgWeeklyUnitsChangeYoy: "N/A",
		totalSalesTy: "$11,842",
		"totalSales%ChangeYoy": "N/A",
		salesLy: "$0",
		storeRangingImpact: "$11,842",
		totalStoresRangedTy: "1",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "0",
		AvgWeekAvailableTy: "7",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "7",
		AvgWeeksAvailLy: "0",
		"like-For-Like": "$0",
		OtherChanges: "$0",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD", "Miami Village"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$1,130",
		"WeeklySales%ChangeYoy": "N/A",
		avgWeeklySalesByStoreLy: "$0",
		avgWeeklyUnitsByStore: "23",
		avgWeeklyUnitsChangeYoy: "N/A",
		totalSalesTy: "$7,910",
		"totalSales%ChangeYoy": "N/A",
		salesLy: "$0",
		storeRangingImpact: "$7,910",
		totalStoresRangedTy: "1",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "0",
		AvgWeekAvailableTy: "7",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "7",
		AvgWeeksAvailLy: "0",
		"like-For-Like": "$0",
		OtherChanges: "$0",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD", "Albion Barn"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$1,353",
		"WeeklySales%ChangeYoy": "N/A",
		avgWeeklySalesByStoreLy: "$0",
		avgWeeklyUnitsByStore: "27",
		avgWeeklyUnitsChangeYoy: "N/A",
		totalSalesTy: "$9,473",
		"totalSales%ChangeYoy": "N/A",
		salesLy: "$0",
		storeRangingImpact: "$9,473",
		totalStoresRangedTy: "1",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "0",
		AvgWeekAvailableTy: "7",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "7",
		AvgWeeksAvailLy: "0",
		"like-For-Like": "$0",
		OtherChanges: "$0",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "QLD", "Coolangatta"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$1,216",
		"WeeklySales%ChangeYoy": "N/A",
		avgWeeklySalesByStoreLy: "$0",
		avgWeeklyUnitsByStore: "47",
		avgWeeklyUnitsChangeYoy: "N/A",
		totalSalesTy: "$8,511",
		"totalSales%ChangeYoy": "N/A",
		salesLy: "$0",
		storeRangingImpact: "$8,511",
		totalStoresRangedTy: "1",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "N/A",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "0",
		AvgWeekAvailableTy: "7",
		"AvgWeekAvailable%ChangeYoy": "N/A",
		"#WeeksAvailChange": "7",
		AvgWeeksAvailLy: "0",
		"like-For-Like": "$0",
		OtherChanges: "$0",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "NSW"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$249",
		"WeeklySales%ChangeYoy": "-1%",
		avgWeeklySalesByStoreLy: "$252",
		avgWeeklyUnitsByStore: "5",
		avgWeeklyUnitsChangeYoy: "-1%",
		totalSalesTy: "$1,593,167",
		"totalSales%ChangeYoy": "-1%",
		salesLy: "$1,602,334",
		storeRangingImpact: "$12,438",
		totalStoresRangedTy: "500",
		"%StoreNetwork": "29%",
		"storeNetwork%ChangeYoy": "1%",
		"#StoresRangeChange": "7",
		TotalStoresRangedLy: "493",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "-1%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "$29,683",
		OtherChanges: "$-51,289",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "VIC"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$241",
		"WeeklySales%ChangeYoy": "2%",
		avgWeeklySalesByStoreLy: "$237",
		avgWeeklyUnitsByStore: "5",
		avgWeeklyUnitsChangeYoy: "-2%",
		totalSalesTy: "$1,165,631",
		"totalSales%ChangeYoy": "1%",
		salesLy: "$1,152,887",
		storeRangingImpact: "$9,701",
		totalStoresRangedTy: "389",
		"%StoreNetwork": "23%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "1",
		TotalStoresRangedLy: "388",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "-1%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "$21,357",
		OtherChanges: "$-18,314",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "WA"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$323",
		"WeeklySales%ChangeYoy": "3%",
		avgWeeklySalesByStoreLy: "$315",
		avgWeeklyUnitsByStore: "7",
		avgWeeklyUnitsChangeYoy: "3%",
		totalSalesTy: "$639,642",
		"totalSales%ChangeYoy": "3%",
		salesLy: "$618,338",
		storeRangingImpact: "$2,073",
		totalStoresRangedTy: "165",
		"%StoreNetwork": "10%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "0",
		TotalStoresRangedLy: "165",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "1%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "$11,455",
		OtherChanges: "$7,777",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "SA"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$348",
		"WeeklySales%ChangeYoy": "13%",
		avgWeeklySalesByStoreLy: "$308",
		avgWeeklyUnitsByStore: "7",
		avgWeeklyUnitsChangeYoy: "14%",
		totalSalesTy: "$458,085",
		"totalSales%ChangeYoy": "13%",
		salesLy: "$405,850",
		storeRangingImpact: "$0",
		totalStoresRangedTy: "105",
		"%StoreNetwork": "6%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "0",
		TotalStoresRangedLy: "105",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "0%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "$7,518",
		OtherChanges: "$44,716",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "TAS"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$410",
		"WeeklySales%ChangeYoy": "-1%",
		avgWeeklySalesByStoreLy: "$413",
		avgWeeklyUnitsByStore: "8",
		avgWeeklyUnitsChangeYoy: "0%",
		totalSalesTy: "$164,234",
		"totalSales%ChangeYoy": "-1%",
		salesLy: "$165,709",
		storeRangingImpact: "$0",
		totalStoresRangedTy: "32",
		"%StoreNetwork": "2%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "0",
		TotalStoresRangedLy: "32",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "0%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "$3,070",
		OtherChanges: "-$4,544",
	},
	{
		path: ["Straight Dry Gin 700ml", "BWS", "NT"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$764",
		"WeeklySales%ChangeYoy": "14%",
		avgWeeklySalesByStoreLy: "$669",
		avgWeeklyUnitsByStore: "15",
		avgWeeklyUnitsChangeYoy: "14%",
		totalSalesTy: "$130,546",
		"totalSales%ChangeYoy": "12%",
		salesLy: "$116,248",
		storeRangingImpact: "-$3,483",
		totalStoresRangedTy: "14",
		"%StoreNetwork": "1%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "0",
		TotalStoresRangedLy: "14",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "-2%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "$2,153",
		OtherChanges: "$15,628",
	},
	{
		path: ["Straight Dry Gin 700ml", "DM"],
		sku: "Straight Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "1,130",
		"WeeklySales%ChangeYoy": "-2%",
		avgWeeklySalesByStoreLy: "$1,156",
		avgWeeklyUnitsByStore: "23",
		avgWeeklyUnitsChangeYoy: "-2%",
		totalSalesTy: "$2,831,797",
		"totalSales%ChangeYoy": "2%",
		salesLy: "$2,766,550",
		storeRangingImpact: "$3,381",
		totalStoresRangedTy: "200",
		"%StoreNetwork": "12%",
		"storeNetwork%ChangeYoy": "5%",
		"#StoresRangeChange": "9",
		TotalStoresRangedLy: "191",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "0%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "$9,765",
		OtherChanges: "$52,102",
	},
	{
		path: ["Tailored Gin 700ml"],
		sku: "Tailored Gin 700ml",
		avgWeeklySalesByStoreTy: "$29",
		"WeeklySales%ChangeYoy": "-1%",
		avgWeeklySalesByStoreLy: "$29",
		avgWeeklyUnitsByStore: "1",
		avgWeeklyUnitsChangeYoy: "1%",
		totalSalesTy: "$572,582",
		"totalSales%ChangeYoy": "-2%",
		salesLy: "$584,841",
		storeRangingImpact: "$118,926",
		totalStoresRangedTy: "1588",
		"%StoreNetwork": "92%",
		"storeNetwork%ChangeYoy": "1%",
		"#StoresRangeChange": "20",
		TotalStoresRangedLy: "1568",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "-4%",
		"#WeeksAvailChange": "-1",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "-$113,232",
		OtherChanges: "$2,046",
	},
	{
		path: ["Distillers Strength Gin 700ml"],
		sku: "Distillers Strength Gin 700ml",
		avgWeeklySalesByStoreTy: "$4",
		"WeeklySales%ChangeYoy": "-94%",
		avgWeeklySalesByStoreLy: "$64",
		avgWeeklyUnitsByStore: "0",
		avgWeeklyUnitsChangeYoy: "-94%",
		totalSalesTy: "$190",
		"totalSales%ChangeYoy": "-98%",
		salesLy: "$8,066",
		storeRangingImpact: "-$736",
		totalStoresRangedTy: "4",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "-60%",
		"#StoresRangeChange": "-6",
		TotalStoresRangedLy: "10",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "0%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "-$721",
		OtherChanges: "-$6,418",
	},
	{
		path: ["Signature Dry Gin 700ml"],
		sku: "Signature Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$707",
		"WeeklySales%ChangeYoy": "0%",
		avgWeeklySalesByStoreLy: "$706",
		avgWeeklyUnitsByStore: "10",
		avgWeeklyUnitsChangeYoy: "0%",
		totalSalesTy: "$14,874,603",
		"totalSales%ChangeYoy": "-4%",
		salesLy: "$15,457,447",
		storeRangingImpact: "-$82,380",
		totalStoresRangedTy: "1709",
		"%StoreNetwork": "99%",
		"storeNetwork%ChangeYoy": "0%",
		"#StoresRangeChange": "2",
		TotalStoresRangedLy: "1707",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "-4%",
		"#WeeksAvailChange": "-1",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "-$651,767",
		OtherChanges: "$151,305",
	},
	{
		path: ["Bone Dry Gin 700ml"],
		sku: "Bone Dry Gin 700ml",
		avgWeeklySalesByStoreTy: "$890",
		"WeeklySales%ChangeYoy": "-29%",
		avgWeeklySalesByStoreLy: "$1,255",
		avgWeeklyUnitsByStore: "12",
		avgWeeklyUnitsChangeYoy: "-29%",
		totalSalesTy: "$3,106,421",
		"totalSales%ChangeYoy": "-30%",
		salesLy: "$4,410,274",
		storeRangingImpact: "-$1,063",
		totalStoresRangedTy: "274",
		"%StoreNetwork": "16%",
		"storeNetwork%ChangeYoy": "-1%",
		"#StoresRangeChange": "-4",
		TotalStoresRangedLy: "278",
		AvgWeekAvailableTy: "13",
		"AvgWeekAvailable%ChangeYoy": "1%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "13",
		"like-For-Like": "-$1,347,090",
		OtherChanges: "$44,300",
	},
	{
		path: ["Raw Honey Gin 700ml"],
		sku: "Raw Honey Gin 700ml",
		avgWeeklySalesByStoreTy: "$7",
		"WeeklySales%ChangeYoy": "-100%",
		avgWeeklySalesByStoreLy: "$3,204",
		avgWeeklyUnitsByStore: "0",
		avgWeeklyUnitsChangeYoy: "-99%",
		totalSalesTy: "$412",
		"totalSales%ChangeYoy": "-100%",
		salesLy: "$551,866",
		storeRangingImpact: "-$136,308",
		totalStoresRangedTy: "5",
		"%StoreNetwork": "0%",
		"storeNetwork%ChangeYoy": "-64%",
		"#StoresRangeChange": "-9",
		TotalStoresRangedLy: "14",
		AvgWeekAvailableTy: "12",
		"AvgWeekAvailable%ChangeYoy": "-1%",
		"#WeeksAvailChange": "0",
		AvgWeeksAvailLy: "12",
		"like-For-Like": "-$406,455",
		OtherChanges: "-$8,691",
	},
];

export const exampleQuestions = [
	"Analyse Vodka performance year to date",
	"Why did red wine underperform last week?",
	"How is beer performing across customer generations?",
	"Analyse Vodka's performance over Easter",
	"Analyse Beer's performance Year To Date",
	"Analyse Red Wine for younger generations Year To Date",
];
