import React from "react";
import Select from "react-select";
import "./dropdown-field-container.scss";

const DropdownField = ({ item, index, handleChange, handleInputChange, loadMoreOptions, translateField, colorStyles, italic }: any) => {
	const {
		options,
		category,
		name,
		default: defaultValue,
		selected,
	} = item;

	const formattedOptions = Object.entries(options).map(([key, value]) => ({
		value: value,
		label: key,
	}));

	const selectedOption = formattedOptions.find(
		(option) => option.label === (selected ?? defaultValue)
	);

	return (
		<div className="analyse__item" key={index}>
			<div className="analyse__title">
				<div className="analyse__name">
					{italic ?
						<i>{translateField(name)}</i>
						:
						translateField(name)
					}
				</div>
			</div>
			<Select
				key={index}
				value={selectedOption}
				onChange={(selectedOption) => handleChange(index, selectedOption, item)}
				options={formattedOptions}
				styles={colorStyles(selected, defaultValue)}
				onMenuScrollToBottom={
					formattedOptions.length % 54 === 0
						? () => loadMoreOptions(item)
						: () => { }
				}
				onInputChange={(e) => handleInputChange(e, item)}
			/>
		</div>
	);
};

export default DropdownField;
