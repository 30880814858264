import { withAITracking } from "@microsoft/applicationinsights-react-js";
import { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { useAuth } from "../context/auth.context";
import {
	reactPlugin,
	useTrackPageViews,
} from "../services/app-insights.service";

import "./routes-container.scss";

import Nav from "../components/Nav/Nav";
import Admin from "./Admin";
import Feedback from "./Admin/Feedback";
import Requests from "./Admin/Requests";
import Users from "./Admin/Users";
import ForgotPassword from "./ForgotPassword";
import Home from "./Home";
import InputPage from "./InputPage";
import NotFound from "./NotFound";
import Report from "./Report/v1";
import ReportV2 from "./Report/v2";
import ReportDemo from "./ReportDemo";
import ResetPassword from "./ResetPassword";
import SigninPage from "./SigninPage/SigninPage";
import TestSignalR from "./TestSignalR";
import ThankYou from "./ThankYou";

// ProtectedRoute component for v6
const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	if (!isAuthenticated) {
		return <Navigate to="/login" state={{ from: location }} replace />;
	}

	return children;
};

const AppRoutes = () => {
	const { isAuthenticated } = useAuth();
	const location = useLocation();

	const [collapseNav, setCollapseNav] = useState(true);
	const [chatGPTModel, setChatGPTModel]: any = useState("gpt4o");

	useTrackPageViews();

	const publicRoutes = ["/login", "/reset-password", "/forgot-password"];
	const isPublicRoute = publicRoutes.includes(location.pathname);

	const handleNavChange = (change: any) => {
		setCollapseNav(!change);
	};

	return (
		<>
			{!isPublicRoute && (
				<Nav setCollapseNav={handleNavChange} collapseNav={collapseNav} />
			)}
			<div
				className={`routes ${isPublicRoute ? "public-route" : ""}
          ${!isPublicRoute && collapseNav ? "collapsed-nav" : ""} 
          ${!isPublicRoute && !collapseNav ? "expanded-nav" : ""}`}
			>
				<Routes>
					{/* Public Routes */}
					<Route path="/not-found" element={<NotFound />} />
					<Route path="/login" element={<SigninPage />} />
					<Route path="/reset-password" element={<ResetPassword />} />
					<Route path="/forgot-password" element={<ForgotPassword />} />

					{/* Private Routes */}
					<Route
						path="/"
						element={
							<ProtectedRoute>
								<InputPage
									chatGPTModel={chatGPTModel}
									setChatGPTModel={setChatGPTModel}
								/>
							</ProtectedRoute>
						}
					/>
					<Route
						path="/report"
						element={
							<ProtectedRoute>
								<ReportDemo />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/reports/:reportId"
						element={
							<ProtectedRoute>
								<Report />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/reports/v2/:reportId"
						element={
							<ProtectedRoute>
								<ReportV2 />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/home"
						element={
							<ProtectedRoute>
								<Home />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin"
						element={
							<ProtectedRoute>
								<Admin />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin/users"
						element={
							<ProtectedRoute>
								<Users />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin/requests"
						element={
							<ProtectedRoute>
								<Requests />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/admin/feedback"
						element={
							<ProtectedRoute>
								<Feedback />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/webhook"
						element={
							<ProtectedRoute>
								<TestSignalR />
							</ProtectedRoute>
						}
					/>
					<Route
						path="/thank-you"
						element={
							<ProtectedRoute>
								<ThankYou />
							</ProtectedRoute>
						}
					/>
				</Routes>
			</div>
		</>
	);
};

export default withAITracking(reactPlugin, AppRoutes);
