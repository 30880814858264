import "./report-title-container.scss";

const ReportTitle = (props: any) => {
	const { text, subtitle } = props;
	return (
		<>
			<div className="report-title">
				<h1 className="report-title__h1" dangerouslySetInnerHTML={{ __html: text }}></h1>
			</div>
			<div className="report-subtitle">
				{subtitle?.split("\n").map((line: any, index: any) => (
					<p key={index} dangerouslySetInnerHTML={{ __html: line }}>
					</p>
				))}
			</div>
		</>
	);
};

export default ReportTitle;
