import Modal from "../Modal";
import "./sources-container.scss";

const Sources = ({ onClick, isModalOpen, title, onModalClose, text, style }: any) => {
	return (
		<div className="sources" style={style}>
			<div className="sources__text" onClick={onClick}>
				Sources and Definitions <span className="icon">&rsaquo;</span>
			</div>
			<Modal
				isOpen={isModalOpen}
				onClose={() => onModalClose()}
				title={title}
				defaultFullScreen={false}
				text={text}
			>
				{""}
			</Modal>
		</div>
	);
};

export default Sources;
