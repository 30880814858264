import { IColumnDefinition } from "../../Interfaces/reports";
import { ITabularData, ITransformedRow } from "../../Interfaces/tabular-data.d";
import {
	alignmentFunc,
	decimalFunc,
	fontSizeFunc,
	groupHeaderColumnsFunc,
	headerColumnsFunc,
	sortFunc,
	valueColorCodingFunc,
} from "../metadata-transformers";

export const transformColumnDefinitions = (
	data: ITabularData | null
): Array<IColumnDefinition> => {
	if (!data) return [];

	const { metadata, name } = data;

	const {
		Alignment: alignText,
		Ascending: ascending,
		"Column Group 1 Heading": columnGroup1Heading,
		"Column Group 1 Heading Colour": columnGroup1HeadingColor,
		"Column Group 1 Heading Font Size": columnGroup1HeadingFontSize,
		"Column Heading Alias": columnHeadingAlias,
		"Column Heading Alias Colour": columnHeadingAliasColor,
		"Column Heading Alias Font Size": columnHeadingAliasFontSize,
		"Column Order": columnOrder,
		"Decimal Place": decimalPlace,
		"Font Size": fontSize,
		"Group By": groupBy,
		"Reverse Colour Coding": reverseColorCoding,
		"Sort By": sortBy,
		Unit: unit,
		"Value Colour Coding": valueColorCoding,
		"Visible On Load": visibleOnLoad,
	} = metadata;

	let cleanedArr = columnHeadingAlias.map((item) => item.replace(/\./g, "")); // Removing all '.' because ag-grid would not display when the property have '.' in the name

	const columns: Array<IColumnDefinition> = columnOrder.map(
		(order: number, orderIndex: number) => {
			order = order - 1;
			return {
				field: cleanedArr[order],

				hide: typeof groupBy[order] === "number" ? true : !visibleOnLoad[order],
				columnGroupShow: !visibleOnLoad[order] ? "open" : "",
			};
		}
	);

	const columnsAlignment = alignmentFunc(alignText, columns);
	const columnsFontSize = fontSizeFunc(fontSize, columnsAlignment);
	const columnsColorCoding = valueColorCodingFunc(
		valueColorCoding,
		reverseColorCoding,
		columnsFontSize
	);

	const columnsDecimals = decimalFunc(decimalPlace, unit, columnsColorCoding);

	// sorting occurs in the server -> server returns data in the order that the params.request returns it
	const columnsSorting = sortFunc(sortBy, ascending, columnsDecimals);

	const headerColumns = headerColumnsFunc(
		columnHeadingAliasColor,
		columnHeadingAliasFontSize,
		columnsSorting,
		name
	);

	const groupHeaderColumns = groupHeaderColumnsFunc(
		columnGroup1Heading,
		columnGroup1HeadingColor,
		columnGroup1HeadingFontSize,
		headerColumns,
		name
	);

	groupHeaderColumns.forEach((x) => {
		if (x.children && x.children != null) {
			x.children.forEach((y) => {
				y.hide = false;
			});
		}
	});

	return groupHeaderColumns;
};

export const testDataRowTransformation = (data: any) => {
	const { headers, sortBy, groupedBy, data: rows, metadata } = data;

	const transformNode = (node: any) => {
		const currentNode = headers.reduce((acc: any, key: any, index: any) => {
			acc[key] = node.values[index] ?? null;
			if (acc.Segment_Name === null) {
				return { ...acc, hidden: true };
			}
			return acc;
		}, {});

		currentNode.path = groupedBy
			.map((key: string) =>
				currentNode[key] ? currentNode[key].toString() : null
			)
			.filter((item: any) => item !== null);

		const childNodes = node.children.flatMap(transformNode);

		return [currentNode, ...childNodes];
	};

	return rows.flatMap(transformNode);
};

export const transformDataGab = (
	nodes: any[],
	groupedBy: string[],
	headers: string[]
): any[] => {
	return nodes.map((node) => {
		const transformedNode: Record<string, any> = {};
		groupedBy.forEach((group, index) => {
			transformedNode[group] = node.values[index];
		});
		headers.forEach((header, index) => {
			if (index >= groupedBy.length) {
				transformedNode[header] = node.values[index];
			}
		});
		if (node.children) {
			transformedNode.children = transformDataGab(
				node.children,
				groupedBy,
				headers
			);
		}
		return transformedNode;
	});
};

export const transformRows = (
	data: ITabularData | null
): Array<ITransformedRow> => {
	if (!data) return [];

	const { initialData, metadata } = data;

	const headingAlias = metadata["Column Heading Alias"];
	if (!Array.isArray(headingAlias)) {
		console.error("Error: headingAlias is not an array", headingAlias);
		return [];
	}

	const groupBy = metadata["Group By"];

	const transformRowAndChildren = (row: any): ITransformedRow => {
		const values = row.values;
		const transformedRow: ITransformedRow = {
			children: row.children || 0,
			id: row.id,
			group: false,
		};

		let isGroup = false;

		headingAlias.forEach((column, colIndex) => {
			transformedRow[column.replace(/\./g, "")] = // Removing all '.' because ag-grid would not display when the property have '.' in the name
				values[colIndex] !== undefined ? values[colIndex] : null;

			if (groupBy.includes(colIndex)) {
				isGroup = true;
			}
		});

		if (isGroup) {
			transformedRow.group = true;
		}

		if (row.childrenValues && row.childrenValues.length > 0) {
			transformedRow.hasPreLoadedChildren = true;
			transformedRow.childrenData = row.childrenValues.map(
				transformRowAndChildren
			);

			if (row.childrenValues.length > 0) {
				transformedRow.group = true;
				transformedRow.children = row.childrenValues.length;
			}
		}

		return transformedRow;
	};

	const transformedRows = initialData.map(transformRowAndChildren);

	return transformedRows;
};

export const transformRowsDemo = (data: any) => {
	const { columns, rows } = data;

	if (!data || !data.rows || data.rows.length === 0) {
		return { rows: [], lastRow: null };
	}

	let lastRowIndex = 0;

	const createRowObjects = rows.map((row: any, index: number) => {
		lastRowIndex = index;
		// return columns.reduce((obj: any, column: any, index: any) => {
		//   obj[column.name] = row[index];
		//   return obj;
		return columns.reduce((obj: any, column: any, index: any) => {
			obj[column.name] =
				row[index] !== undefined && row[index] !== null
					? Number(row[index])
					: null;
			return obj;
		}, {});
	});

	return {
		rows: createRowObjects,
		lastRow: lastRowIndex + 1,
	};
};
