import React from "react";
import "./summary-sentance-container.scss";
const SummarySentance = ({ sentance }: any) => {
	return (sentance ?
		<div
			className="summary-sentance"
			dangerouslySetInnerHTML={{
				__html: sentance
					.replaceAll("\n", "<br />&nbsp;")
					.replaceAll("<li>", "<li style='list-style-type: none;'>"),
			}}
		></div> : <></>
	);
};

export default SummarySentance;
