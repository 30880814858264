import { useState } from "react";
import Button from "../Button";
import ExpandingTextareaDemo from "../ExpandingTextareaDemo";
import "./feedback-container.scss";

const FeedbackComponent = (props: any) => {
	const { parentStyling, onClick, className, sectionName } = props;

	const [text, setText] = useState("");
	const [clicked, setClicked] = useState(false);

	return (
		<div className={className} style={parentStyling}>
			<div className="feedback__header">
				<span className="feedback__text">Feedback</span>
			</div>
			<ExpandingTextareaDemo
				value={text}
				onChange={setText}
				placeholder=""
				style={{}}
				rows={3}
				maxLength={1000}
				disabled={clicked ? true : false}
				onKeyDown={() => {}}
				id={""}
			>
				<></>
			</ExpandingTextareaDemo>
			<Button
				onClick={() => {
					setClicked(true);
					onClick({ section: sectionName, feedback: text });
				}}
				style={{
					backgroundColor: clicked ? "#E0E0E0" : "#A5D6A7",
					cursor: "pointer",
					marginLeft: "88%",
					paddingTop: "4px",
					paddingBottom: "4px",
					fontSize: "14px",
					width: "110px",
					pointerEvents: clicked ? "none" : "auto",
					marginBottom: "24px",
				}}
				text={clicked ? "Submitted" : "Submit"}
			/>
		</div>
	);
};

export default FeedbackComponent;
