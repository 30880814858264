import React from "react";
import "./exec-sum-para-container.scss";

const ExecSumParagraph = (props: any) => {
	const { para } = props;
	if (para.value.includes("<li>")) {
		para.value = para.value.replaceAll(
			"<li>",
			"<li style='list-style-type: none;'>"
		);
	}

	return (
		<div className="exec-sum-para">
			<div
				className="exec-sum-para__title"
				dangerouslySetInnerHTML={{ __html: para.key }}
			></div>
			<div>
				<div
					className="exec-sum-para__sentance"
					dangerouslySetInnerHTML={{
						__html: para.value?.replaceAll("\n", "<br />"),
					}}
				></div>
			</div>
		</div>
	);
};

export default ExecSumParagraph;
