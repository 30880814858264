import React from "react";
import "./section-title-container.scss";
import pinIcon from "../../assets/icons/pin-02.svg";
import pinActiveIcon from "../../assets/icons/pin-02-yellow.svg";

const SectionTitle = ({ title, style, pinDisplay, onClick, pin, id }: any) => {
	return (
		<div className="section-title" style={style} id={id}>
			<div className="section-title__text">{title}</div>
			<img
				src={pin ? pinActiveIcon : pinIcon}
				className="section-title__pin"
				style={{ display: pinDisplay }}
				onClick={onClick}
				title="Click to pin and keep this section open"
			/>
		</div>
	);
};

export default SectionTitle;
