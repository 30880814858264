import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import Loader from "../../../components/Loader";
import {
	deleteReport,
	getReportRequests,
	reprocessReport,
	uploadReportFiles,
} from "../../../services/report.service";
import "./requests-container.scss";

const Requests = () => {
	const [requests, setRequests]: any[] = useState();
	const [trigger, setTrigger] = useState(0);
	const [expandedRows, setExpandedRows] = useState<Set<string>>(new Set());

	useEffect(() => {
		getReportRequests().then((res: any) => {
			setRequests(res.data);
		});
	}, [trigger]);

	// Toggle row expansion
	const toggleRowExpansion = (id: string) => {
		const newExpandedRows = new Set(expandedRows);
		if (newExpandedRows.has(id)) {
			newExpandedRows.delete(id);
		} else {
			newExpandedRows.add(id);
		}
		setExpandedRows(newExpandedRows);
	};

	// Handle File Upload
	const handleFileUpload = async (event: React.ChangeEvent<HTMLInputElement>, reportId: string) => {
		const files = event.target.files;
		if (!files || files.length === 0) return;

		const formData = new FormData();
		Array.from(files).forEach((file) => {
			formData.append("files", file);
		});

		setRequests((req: any) =>
			req.map((section: any, index: any) =>
				section.id === reportId ? { ...section, loading: true } : section
			)
		);

		try {
			const response = await uploadReportFiles(reportId, formData);
			if (response.status === 200) {
			} else {
			}
		} catch (error) {
			console.error("Upload error:", error);
			alert("An error occurred while uploading.");
		} finally {
			setRequests((req: any) =>
				req.map((section: any, index: any) =>
					section.id === reportId ? { ...section, loading: false } : section
				)
			);
			setTrigger((prev) => prev + 1);
		}
	};

	const handleOpenTab = (reportId: string) => {
		window.open(`/reports/v2/${reportId}`, "_blank", "noopener,noreferrer");
	};

	const handleDelete = async (reportId: string) => {
		Swal.fire({
			title: "Are you sure?",
			text: "This action cannot be undone!",
			icon: "warning",
			showCancelButton: true,
			confirmButtonColor: "#d33",
			cancelButtonColor: "#3085d6",
			confirmButtonText: "Yes, delete it!",
			showLoaderOnConfirm: true,
			allowOutsideClick: false,
			preConfirm: async () => {
				const response = await deleteReport(reportId);
				if (response.status === 200) {
					setTrigger((prev) => prev + 1);
					Swal.fire("Deleted!", "Your item has been deleted.", "success");
				} else {
					Swal.fire("Error!", "Something went wrong.", "error");
				}
			},
		});
	};

	const handleRetry = async (reportId: string) => {
		await reprocessReport(reportId);
		setRequests((req: any) =>
			req.map((section: any, index: any) =>
				section.id === reportId ? { ...section, status: "InProgress" } : section
			)
		);
	};

	const navigate = useNavigate();

	return (
		<>
			<div className="table-container">
				<button className="back-btn" onClick={() => navigate("/admin")}>
					Back
				</button>

				<table className="styled-table">
					<thead>
						<tr>
							<th></th>
							<th>Report Id</th>
							<th>Params</th>
							<th>User</th>
							<th>Created On</th>
							<th>Actions</th>
						</tr>
					</thead>
					<tbody>
						{requests
							?.sort(
								(a: any, b: any) =>
									new Date(a.createdOn).getTime() - new Date(b.createdOn).getTime()
							)
							.map((row: any) => (
								<React.Fragment key={row.id}>
									<tr>
										<td>
											<button className="expand-button" onClick={() => toggleRowExpansion(row.id)}>
												{expandedRows.has(row.id) ? "-" : "+"}
											</button>
										</td>
										<td>{row.id}</td>
										<td>
											{row.userParams
												.filter((x: any) => x.selected != "All")
												.filter(
													(item: any) =>
														![
															"to_week",
															"ref_to_week",
															"from_week",
															"ref_from_week",
															"year",
															"ref_year",
														].includes(item.name)
												)
												.map((item: any) => `${item.name}: ${item.selected}`)
												.join(", ")}
										</td>
										<td>
											{row.userFirstName} {row.userLastName}
										</td>
										<td>{new Date(row.createdOn + "Z").toLocaleString()}</td>
										<td className="action-td">
											{row.loading ? (
												<Loader></Loader>
											) : row.status == "NotStarted" ? (
												<>
													<input
														type="file"
														multiple
														onChange={(e) => handleFileUpload(e, row.id)}
														style={{ display: "none" }}
														id={`upload-${row.id}`}
													/>
													<label htmlFor={`upload-${row.id}`} className="upload-button">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
															<polyline points="17 8 12 3 7 8"></polyline>
															<line x1="12" y1="3" x2="12" y2="15"></line>
														</svg>
													</label>
													<button onClick={() => handleDelete(row.id)} className="btn-danger">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<polyline points="3 6 5 6 21 6"></polyline>
															<path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
															<path d="M10 11v6"></path>
															<path d="M14 11v6"></path>
															<path d="M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"></path>
														</svg>
													</button>
												</>
											) : row.status == "Completed" ? (
												<>
													<input
														type="file"
														multiple
														onChange={(e) => handleFileUpload(e, row.id)}
														style={{ display: "none" }}
														id={`upload-${row.id}`}
													/>
													<label htmlFor={`upload-${row.id}`} className="upload-button">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
															<polyline points="17 8 12 3 7 8"></polyline>
															<line x1="12" y1="3" x2="12" y2="15"></line>
														</svg>
													</label>
													<button onClick={() => handleOpenTab(row.id)} className="btn-success">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="#fff"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<path d="M4 22V4a2 2 0 0 1 2-2h9l-1 5 1 5H6"></path>
														</svg>
													</button>
													<button onClick={() => handleDelete(row.id)} className="btn-danger">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<polyline points="3 6 5 6 21 6"></polyline>
															<path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
															<path d="M10 11v6"></path>
															<path d="M14 11v6"></path>
															<path d="M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"></path>
														</svg>
													</button>
													<button onClick={() => handleRetry(row.id)} className="btn-retry">
														⭯
													</button>
												</>
											) : row.status == "InProgress" ? (
												<label>Processing ...</label>
											) : row.status == "Failed" ? (
												<>
													<label>Failed!</label>&emsp;
													<input
														type="file"
														multiple
														onChange={(e) => handleFileUpload(e, row.id)}
														style={{ display: "none" }}
														id={`upload-${row.id}`}
													/>
													<label htmlFor={`upload-${row.id}`} className="upload-button">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
															<polyline points="17 8 12 3 7 8"></polyline>
															<line x1="12" y1="3" x2="12" y2="15"></line>
														</svg>
													</label>
													<button onClick={() => handleDelete(row.id)} className="btn-danger">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															width="24"
															height="24"
															fill="none"
															stroke="currentColor"
															strokeWidth="2"
															strokeLinecap="round"
															strokeLinejoin="round"
															viewBox="0 0 24 24"
														>
															<polyline points="3 6 5 6 21 6"></polyline>
															<path d="M19 6l-1 14a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2L5 6"></path>
															<path d="M10 11v6"></path>
															<path d="M14 11v6"></path>
															<path d="M9 6V4a1 1 0 0 1 1-1h4a1 1 0 0 1 1 1v2"></path>
														</svg>
													</button>
													<button onClick={() => handleRetry(row.id)} className="btn-retry">
														⭯
													</button>
												</>
											) : (
												row.status
											)}
										</td>
									</tr>
									{expandedRows.has(row.id) && (
										<tr className="expandable-row">
											<td colSpan={6}>
												<div>
													<h4>User Params:</h4>
													<ul>
														{row.userParams.map((param: any, index: any) => (
															<li key={index}>
																<strong>{param.name}:</strong> {param.selected}
															</li>
														))}
													</ul>
													<br />
													<h4>Chat:</h4>
													<ul>
														{row.chat.map((chat: any, index: any) => (
															<li key={index}>
																<strong>{chat.role}:</strong> {chat.content}
															</li>
														))}
													</ul>
												</div>
											</td>
										</tr>
									)}
								</React.Fragment>
							))}
					</tbody>
				</table>
			</div>
		</>
	);
};

export default Requests;
