import { TrackingEventType } from "../../Interfaces/events.d";
import BlockText from "../BlockText";
import DetailsContainer from "../DetailsContainer";
import FeedbackComponent from "../FeedbackComponent";
import Sources from "../Sources";
import SsrmTreeDataTable from "../SsrmTreeDataTable/SsrmTreeDataTable";
import SubsectionBlock from "../SubsectionBlock";

import "./block-content-container.scss";

const renderBlockText = (
	sectionIndex: number,
	itemIndex: number,
	blockIndex: number,
	block: any,
	x: any,
	xIndex: number,
	section: any,
	updateStateFunction: any,
	recordEvent: any
) => {
	return (
		<BlockText
			key={`text-${sectionIndex}-${itemIndex}-${blockIndex}`}
			block={block}
			x={x}
			sectionIndex={sectionIndex}
			itemIndex={itemIndex}
			blockIndex={blockIndex}
			xIndex={xIndex}
			section={section}
			updateStateFunction={updateStateFunction}
			recordEvent={recordEvent}
		/>
	);
};

const renderTable = (
	sectionIndex: number,
	itemIndex: number,
	blockIndex: number,
	xIndex: number,
	section: any,
	x: any,
	block: any,
	getReportChildrenData: any,
	recordEvent: any
) => {
	return (
		<SsrmTreeDataTable
			key={`table-${sectionIndex}-${itemIndex}-${blockIndex}-${xIndex}`}
			title={x.title}
			textSection={{
				title: block.title,
				description: block.content.filter((x: any) => x.type == "text")[0]?.data,
			}}
			columnDefs={x.data?.transformedColumns}
			metadata={x.metadata}
			rowData={x.data?.transformedRows}
			domLayout={"autoHeight"}
			rowModelType={"serverSide"}
			loadChildrenApiCall={getReportChildrenData}
			rowGroupPanelShow={"none"}
			enableCharts={true}
			enableRangeSelection={true}
			serverSideSortAllLevels={true}
			suppressAggFuncInHeader={true}
			treeData={true}
			groupDisplayType={"singleColumn"}
			onBeforeModalOpen={() =>
				recordEvent({
					name: "TABLE EXPAND",
					description: `Expand table clicked on ${x.title} table in block ${block.title} under section ${section.title}`,
					type: TrackingEventType.ButtonClicked,
				})
			}
			onBeforeModalClose={() =>
				recordEvent({
					name: "TABLE CLOSE",
					description: `Close expand table clicked on ${x.title} table in block ${block.title} under section ${section.title}`,
					type: TrackingEventType.ButtonClicked,
				})
			}
		/>
	);
};

const BlockContent = ({
	sectionIndex,
	itemIndex,
	blockIndex,
	block,
	section,
	updateStateFunction,
	recordEvent,
	getReportChildrenData,
	reportId,
	handleWaterfallBarClick,
	item,
	expandFunction,
	scrollToElementWithOffset,
	handleFeedbackSubmit,
}: any) => {
	return (
		<div>
			{item.title === "Performance Overview" ? (
				<>
					{block.content.map((x: any, xIndex: number) => {
						console.log(x);

						return x.type == "text" ? (
							renderBlockText(
								sectionIndex,
								itemIndex,
								blockIndex,
								block,
								x,
								xIndex,
								section,
								updateStateFunction,
								recordEvent
							)
						) : x.type === "table" ? (
							<div className="performance-table-container">
								{renderTable(
									sectionIndex,
									itemIndex,
									blockIndex,
									xIndex,
									section,
									x,
									block,
									getReportChildrenData,
									recordEvent
								)}
							</div>
						) : null;
					})}
				</>
			) : (
				<SubsectionBlock
					title={block.title}
					subtitle={block.subtitle}
					key={`section-${sectionIndex}-${itemIndex}-${blockIndex}`}
					style={{
						backgroundColor: block.isHighlighted ? "rgba(255, 200, 0, 0.35)" : "",
					}}
					onClick={() => {
						recordEvent({
							name: (block.showDetails ? "COLLAPSE" : "EXPAND") + " BUTTON CLICK",
							description: `${block.showDetails ? "Collapse" : "Expand"} click on ${
								block.title
							} button under section ${section.title}`,
							type: TrackingEventType.ButtonClicked,
						});
						expandFunction(!block.showDetails, sectionIndex, itemIndex, blockIndex);
						const element = document.getElementById(
							`section-${sectionIndex}-${itemIndex}-${blockIndex}`
						);
						scrollToElementWithOffset(element);
					}}
					pin={block.pin}
					id={`section-${sectionIndex}-${itemIndex}-${blockIndex}`}
					showDetails={block.showDetails}
					pinOnClick={() => {
						recordEvent({
							name: (block.pin ? "UNPIN" : "PIN") + " BUTTON CLICK",
							description: `${block.pin ? "Unpin" : "Pin"} click on ${
								block.title
							} button under section ${section.title}`,
							type: TrackingEventType.ButtonClicked,
						});
						updateStateFunction("pin", !block.pin, sectionIndex, itemIndex, blockIndex);
						const element = document.getElementById(
							`section-${sectionIndex}-${itemIndex}-${blockIndex}`
						);
						scrollToElementWithOffset(element);
					}}
				>
					{block.content.map((x: any, xIndex: number) => {
						return x.type == "text" ? (
							renderBlockText(
								sectionIndex,
								itemIndex,
								blockIndex,
								block,
								x,
								xIndex,
								section,
								updateStateFunction,
								recordEvent
							)
						) : x.type === "waterfallChart" ? (
							<div key={`${sectionIndex}-${itemIndex}-${blockIndex}-${xIndex}`}>
								{block.showDetails && (
									<DetailsContainer
										title={item.dataTitle}
										titleStyle={{
											backgroundColor: "#F6F7F7",
										}}
										reportId={reportId}
										detailsHeading={x.title}
										detailsSubheading={""}
										dataPointsTop={x.topData}
										dataPointsBottom={x.bottomData}
										chart={x.chart}
										tableTitle={item.dataTitle}
										multicolumnTitle={block.multicolumnTitle}
										multicolumnSubtitle={block.multicolumnSubtitle}
										waterfallBarClick={handleWaterfallBarClick}
									>
										<Sources
											onClick={() =>
												updateStateFunction(
													"modal",
													!block.showModal,
													sectionIndex,
													itemIndex,
													blockIndex
												)
											}
											onModalClose={() =>
												updateStateFunction(
													"modal",
													!block.showModal,
													sectionIndex,
													itemIndex,
													blockIndex
												)
											}
											isModalOpen={block.showModal}
											title={"Sources and Definitions"}
											text={block.sourcesAndDefinitions?.value}
										/>
									</DetailsContainer>
								)}
							</div>
						) : x.type === "table" ? (
							<>
								{renderTable(
									sectionIndex,
									itemIndex,
									blockIndex,
									xIndex,
									section,
									x,
									block,
									getReportChildrenData,
									recordEvent
								)}
							</>
						) : null;
					})}
					<FeedbackComponent
						key={block.title}
						sectionName={block.title}
						onClick={handleFeedbackSubmit}
					/>
				</SubsectionBlock>
			)}
		</div>
	);
};

export default BlockContent;
