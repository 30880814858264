import "./report-title-demo-container.scss";

const ReportTitleDemo = (props: any) => {
	const { text, h2, version, date } = props;
	return (
		<div className="report-title-demo">
			<h1 className="report-title-demo__h1">{text}</h1>
			<div className="report-title-demo__info">
				<h2 className="report-title-demo__h2">{h2}</h2>
				<div className="report-title-demo__version">{version}</div>
				<div className="report-title-demo__date">{date}</div>
			</div>
		</div>
	);
};

export default ReportTitleDemo;
