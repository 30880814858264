import { ICellClassParams, IColumnDefinition } from "../Interfaces/reports";

export const alignmentFunc = (
  alignment: string[],
  columns: IColumnDefinition[]
): Array<IColumnDefinition> => {
  const addAlignment = columns.map(
    (column: IColumnDefinition, index: number) => {
      const alignmentValue =
        alignment[index]?.toLowerCase() === "centre"
          ? "center"
          : alignment[index]?.toLowerCase();

      return alignmentValue !== "left"
        ? {
            ...column,
            cellStyle: {
              textAlign: alignmentValue,
            },
          }
        : { ...column };
    }
  );

  return addAlignment;
};

export const fontSizeFunc = (
  fontSize: number[],
  columns: IColumnDefinition[]
): Array<IColumnDefinition> => {
  return columns.map((column: IColumnDefinition, index: number) => {
    const fontSizeValue = fontSize[index];
    return {
      ...column,
      cellStyle: {
        ...column.cellStyle,
        fontSize: fontSizeValue ? `${fontSizeValue}px` : "inherit",
      },
    };
  });
};

export const valueColorCodingFunc = (
  colorCoding: boolean[],
  reverseColorCoding: boolean[],
  columns: IColumnDefinition[]
): Array<IColumnDefinition> => {
  return columns.map((column: IColumnDefinition, index: number) => {
    const colorCodingValue = colorCoding[index];
    const reverseColor = reverseColorCoding[index];

    if (colorCodingValue && reverseColor) {
      return {
        ...column,
        cellClass: (params: ICellClassParams) => {
          return params.value > 0
            ? "negative-value-class"
            : params.value < 0
            ? "positive-value-class"
            : "";
        },
      };
    } else if (colorCodingValue) {
      return {
        ...column,
        cellClass: (params: ICellClassParams) => {
          return params.value < 0
            ? "negative-value-class"
            : params.value > 0
            ? "positive-value-class"
            : "";
        },
      };
    }

    return { ...column };
  });
};

export const decimalFunc = (
  decimals: number[],
  units: string[],
  columns: IColumnDefinition[]
): Array<IColumnDefinition> => {
  return columns.map((column: IColumnDefinition, index: number) => {
    const decimalPlace = decimals[index];
    const unit = units[index];

    const stringFormatter = (params: ICellClassParams) => {
      const value = params.value;
      if (!value) return;
      return value;
    };

    const currencyFormatter = (params: ICellClassParams) => {
      const value = params.value;
      if (!value) return;
      const fixedValue = value.toFixed(decimalPlace);
      const formattedValue = fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");

      return `$${formattedValue}`;
    };

    const percentageFormatter = (params: ICellClassParams) => {
      const value = params.value;
      if (!value) return;
      return `${(value * 100).toFixed(decimalPlace)}%`;
    };

    const pptFormatter = (params: ICellClassParams) => {
      const value = params.value;
      if (!value) return;
      return `${(value * 100).toFixed(decimalPlace)}ppt`;
    };

    const numberFormatter = (params: ICellClassParams) => {
      const value = params.value;
      if (!value) return;
      const fixedValue = value.toFixed(decimalPlace);
      const formattedValue = fixedValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return formattedValue;
    };

    let formatterFunc =
      unit === "string" || unit === "String"
        ? stringFormatter
        : unit === "$"
        ? currencyFormatter
        : unit === "%"
        ? percentageFormatter
        : unit === "number"
        ? numberFormatter
        : unit === "ppt"
        ? pptFormatter
        : stringFormatter;

    return {
      ...column,
      valueFormatter: formatterFunc,
    };
  });
};

export const sortFunc = (
  sorting: boolean[],
  ascending: boolean[],
  columns: IColumnDefinition[]
): Array<IColumnDefinition> => {
  let sortIndex = 0;
  return columns.map((column: IColumnDefinition, index: number) => {
    const sortingCol = sorting[index];
    const ascCol = ascending[index];

    if (sortingCol) {
      return {
        ...column,
        sort: ascCol ? "asc" : "desc",
        sortIndex: sortIndex++,
        sortable: true,
      };
    }

    return {
      ...column,
      sortable: true,
    };
  });
};

export const headerColumnsFunc = (
  headerColumnsColors: string[],
  headerColumnFontSize: number[],
  columns: IColumnDefinition[],
  name: string
): Array<IColumnDefinition> => {
  const uniqueTableId = name;
  const styleId = `dynamic-header-styles-${uniqueTableId}`;
  generateDynamicStyles(
    headerColumnsColors,
    styleId,
    headerColumnFontSize,
    uniqueTableId
  );

  return columns.map((column: IColumnDefinition, index: number) => {
    const headerClassName = `header-class-${index}-${name}`;
    return {
      ...column,
      headerClass: headerClassName,
    };
  });
};

export const groupHeaderColumnsFunc = (
  headerCols: string[],
  colours: string[],
  fontSize: number[],
  columns: IColumnDefinition[],
  tableName: string
): Array<IColumnDefinition> => {
  const uniqueTableId = tableName;
  const styleId = `dynamic-group-header-styles-${uniqueTableId}`;

  generateDynamicStyles(colours, styleId, fontSize, uniqueTableId);

  const groupedColumns: Array<IColumnDefinition> = [];

  headerCols.forEach((heading: string, index: number) => {
    if (heading === null) {
      groupedColumns.push(columns[index]);
      return;
    }

    let parent = groupedColumns.find((group: IColumnDefinition) => {
      return group.headerName === heading;
    });

    const groupHeaderClassName = `group-header-class-${index}-${uniqueTableId}`;

    if (!parent) {
      parent = {
        headerName: heading,
        children: [],
        headerClass: groupHeaderClassName,
      };
      groupedColumns.push(parent);
    }

    parent.children?.push(columns[index]);
  });

  return groupedColumns;
};

const generateDynamicStyles = (
  colours: string[],
  styleElementId: string,
  fontSize: number[],
  name: string
): void => {
  const darkenColor = (hex: string, percent: number): string => {
    if (!hex) return "null";
    const num = parseInt(hex.replace("#", ""), 16);
    const r = Math.max(0, Math.min(255, (num >> 16) + (255 * percent) / 100));
    const g = Math.max(
      0,
      Math.min(255, ((num >> 8) & 0x00ff) + (255 * percent) / 100)
    );
    const b = Math.max(
      0,
      Math.min(255, (num & 0x0000ff) + (255 * percent) / 100)
    );
    return `rgb(${r}, ${g}, ${b})`;
  };

  let styleElement = document.getElementById(
    styleElementId
  ) as HTMLStyleElement;

  if (!styleElement) {
    styleElement = document.createElement("style");
    styleElement.id = styleElementId;
    document.head.appendChild(styleElement);
  }

  styleElement.innerHTML = "";

  colours.forEach((color: string, index: number) => {
    if (!color || !fontSize[index]) return;
    const darkerColor = darkenColor(color, -10);

    let fontColor = color == "#808AA0" ? "white" : "black";

    styleElementId.includes("group")
      ? (styleElement.innerHTML += `
			.group-header-class-${index}-${name} {
				background-color: ${color} !important;
				font-size: ${fontSize[index]}px !important;
        color: ${fontColor} !important;
			}
			.group-header-class-${index}-${name}:hover {
							background-color: ${darkerColor} !important;
							}

		
		`)
      : (styleElement.innerHTML += `
			.header-class-${index}-${name} {
				background-color: ${color} !important;
				font-size: ${fontSize[index]}px !important;
        color: ${fontColor} !important;
			}
			.header-class-${index}-${name}:hover {
							background-color: ${darkerColor} !important;
							}

		
		`);
  });
};
