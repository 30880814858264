import * as d3 from "d3";
import { useEffect, useRef } from "react";

import "./d3-container.scss";

const onBarClick = (d: any) => {
	console.log(d);
};

const D3Waterfall = (props: any) => {
	const {
		handleWaterfallBarClick,
		dataPoints: dataProp,
		multicolumnTitle,
		multicolumnSubtitle,
	} = props;
	const svgRef: any = useRef();

	useEffect(() => {
		if (!dataProp) return;
		if (!svgRef.current) return;

		// Clear any existing SVG content
		d3.select(svgRef.current).selectAll("*").remove();

		// Create tooltip div if it doesn't exist
		let tooltip: any = d3.select("body").select(".tooltip");
		if (tooltip.empty()) {
			tooltip = d3
				.select("body")
				.append("div")
				.attr("class", "tooltip")
				.style("opacity", 0)
				.style("position", "absolute")
				.style("background-color", "white")
				.style("border", "1px solid #ddd")
				.style("border-radius", "4px")
				.style("padding", "8px")
				.style("pointer-events", "none")
				.style("font-size", "12px")
				.style("box-shadow", "0 2px 4px rgba(0,0,0,0.1)");
		}

		// Set up dimensions with more top margin for labels
		const margin = { top: 180, right: 30, bottom: 20, left: 65 };
		const width = 880 - margin.left - margin.right;
		const height = 550 - margin.top - margin.bottom;

		// Create SVG
		const svg = d3
			.select(svgRef.current)
			.attr("width", width + margin.left + margin.right)
			.attr("height", height + margin.top + margin.bottom)
			.append("g")
			.attr("transform", `translate(${margin.left},${margin.top + 35})`);

		// Calculate cumulative values
		let cumulative = dataProp[0].y; // Start with first value
		const data = dataProp.map((d: any, i: number) => {
			const start = i === 0 ? 0 : cumulative;

			if (i > 0) {
				// For all items except the first
				if (i === dataProp.length - 1) {
					// For the last item, use it as the final value
					cumulative -= d.y;
				} else {
					// For middle items, add to cumulative
					cumulative += d.y;
				}
			}

			return {
				name:
					i === dataProp.length - 1
						? `${
								multicolumnTitle.includes("GP$") ? "GP$ (initial + scanback" : "Sales (Excl. GST)"
						  }`
						: d.title.replace(/<[^>]*>/g, ""),
				subtitle: d.subtitle,
				start: start,
				end: cumulative,
				amount: d.y,
				color: d.color || (d.y >= 0 ? "#2E7D32" : "#C62828"),
				indexLabel: d.indexLabel,
			};
		});

		// Calculate x scale offset
		const lastColumn = data[data.length - 1];
		const maxColumn = Math.min(data[0].amount, lastColumn.amount);
		const negativeSum = data
			.slice(1, -1)
			.reduce((acc: any, dp: any) => acc + Math.min(dp.amount, 0), 0);
		const offsetSum = maxColumn + negativeSum;
		const finalOffsetCalc = offsetSum * 0.95;

		// Find the maximum y value from the data
		const maxY = Math.max(...data.map((d: any) => Math.max(d.start, d.end)));

		// Calculate y-axis domain using finalOffsetCalc as minimum
		const yDomain = [
			finalOffsetCalc, // Use finalOffsetCalc as minimum
			maxY, // Use maximum value from data
		];

		// Background colors for sections
		let backgroundColors: string[] = [];

		if (data.length === 6) {
			backgroundColors = ["#F9FBFA", "#FDFEF0", "#FDFEF0", "#F0F9FE", "#FEF5F0", "#F9FBFA"];
		} else {
			backgroundColors = [
				"#F9FBFA",
				"#F0F9FE",
				"#F0F9FE",
				"#F0F9FE",
				"#F0F9FE",
				"#FDFEF0",
				"#FEF5F0",
				"#F9FBFA",
			];
		}

		// Set up scales
		const x: any = d3
			.scaleBand()
			.range([0, width])
			.domain(data.map((d: any) => d.name))
			.padding(0.5);

		// Add background rectangles
		svg
			.selectAll("rect.background")
			.data(data)
			.enter()
			.append("rect")
			.attr("class", "background")
			.attr("x", (d: any) => x(d.name) - x.bandwidth() * 0.5)
			.attr("y", -120)
			.attr("width", x.bandwidth() * 2)
			.attr("height", height + margin.top + margin.bottom)
			.attr("fill", (d, i) => backgroundColors[i]);

		const y = d3.scaleLinear().range([height, 0]).domain(yDomain).nice();

		// Calculate the approximate number of ticks we want
		const desiredNumberOfTicks = 8;

		// Let D3 generate nice tick values based on the domain
		const tickValues = y.ticks(desiredNumberOfTicks);

		// Check if we need more precision (when consecutive values would show the same formatted value)
		const needsMorePrecision = tickValues.some((d, i) => {
			if (i === 0) return false;
			const curr = Math.abs(d) >= 1000000 ? (d / 1000000).toFixed(1) : (d / 1000).toFixed(1);
			const prev =
				Math.abs(tickValues[i - 1]) >= 1000000
					? (tickValues[i - 1] / 1000000).toFixed(1)
					: (tickValues[i - 1] / 1000).toFixed(1);
			return curr === prev;
		});

		const yAxis = d3
			.axisLeft(y)
			.tickFormat((d: any) => {
				if (Math.abs(d) >= 1000000) {
					// For values >= 1M
					return `$${(d / 1000000).toFixed(needsMorePrecision ? 2 : 1)}M`;
				} else {
					// For values < 1M
					return `$${(d / 1000).toFixed(needsMorePrecision ? 2 : 1)}K`;
				}
			})
			.tickValues(tickValues);

		svg.append("g").call(yAxis).attr("class", "y-axis");

		// Add the bars
		svg
			.selectAll("rect.bar")
			.data(data)
			.enter()
			.append("rect")
			.attr("class", "bar")
			.attr("x", (d: any) => x(d.name))
			.attr("y", (d: any) => {
				const height = Math.abs(y(d.start) - y(d.end));
				return height < 2 && d.amount !== 0
					? y(Math.max(d.start, d.end)) - 0.5
					: y(Math.max(d.start, d.end));
			})
			.attr("height", (d: any) => {
				const height = Math.abs(y(d.start) - y(d.end));
				return height < 2 && d.amount !== 0 ? 1 : height;
			})
			.attr("width", x.bandwidth())
			.attr("fill", (d: any) => d.color || "#808080")
			// .style("cursor", "pointer")
			.on("mouseover", (event, d: any) => {
				tooltip
					.style("opacity", 1)
					.html(d.indexLabel)
					.style("left", event.pageX + 10 + "px")
					.style("top", event.pageY - 10 + "px");
			})
			.on("mousemove", (event) => {
				tooltip.style("left", event.pageX + 10 + "px").style("top", event.pageY - 10 + "px");
			})
			.on("mouseout", () => {
				tooltip.style("opacity", 0);
			})
			.on("click", (event, d) => {
				if (handleWaterfallBarClick) {
					handleWaterfallBarClick(d);
				}
			});

		// Add zero-value lines
		svg
			.selectAll(".zero-line")
			.data(data.filter((d: any) => d.amount === 0))
			.enter()
			.append("line")
			.attr("class", "zero-line")
			.attr("x1", (d: any) => x(d.name))
			.attr("x2", (d: any) => x(d.name) + x.bandwidth())
			.attr("y1", (d: any) => y(d.start))
			.attr("y2", (d: any) => y(d.start))
			.attr("stroke", "#000")
			.attr("stroke-width", 1);

		// Add connecting lines
		svg
			.selectAll("line.connector")
			.data(data.slice(0, -1))
			.enter()
			.append("line")
			.attr("class", "connector")
			.attr("x1", (d: any) => x(d.name) + x.bandwidth())
			.attr("x2", (d: any, i) => x(data[i + 1].name))
			.attr("y1", (d: any) => y(d.end))
			.attr("y2", (d: any) => y(d.end))
			.attr("stroke", "#000")
			.attr("stroke-dasharray", "6,3");

		// Add value labels
		svg
			.selectAll(".value-label")
			.data(data)
			.enter()
			.append("text")
			.attr("class", (d, i) => `value-label ${i > 0 && i < data.length - 1 ? "middle-label" : ""}`)
			.attr("x", (d: any) => x(d.name) + x.bandwidth() / 2)
			.attr("y", (d: any) => y(Math.max(d.start, d.end)) - 8)
			.attr("text-anchor", "middle")
			.style("font-weight", (d, i) => (i > 0 && i < data.length - 1 ? "normal" : "bold"))
			.style("font-size", (d, i) => (i > 0 && i < data.length - 1 ? "11px" : "12px"))
			.text((d: any) => d.indexLabel);

		// Add column labels at the top
		const columnLabelGroup = svg
			.selectAll(".column-label-group")
			.data(data)
			.enter()
			.append("g")
			.attr("class", "column-label-group")
			.attr("transform", (d: any) => `translate(${x(d.name) + x.bandwidth() / 2}, -90)`);

		// Add main labels (split into multiple lines if needed)
		columnLabelGroup.each(function (d: any) {
			const group = d3.select(this);
			const words = d.name.split(" ");
			let lines = [];
			let currentLine: any = [];

			// Group words into lines
			words.forEach((word: string) => {
				if (currentLine.length === 0 || (currentLine.join(" ") + " " + word).length <= 13) {
					currentLine.push(word);
				} else {
					lines.push(currentLine.join(" "));
					currentLine = [word];
				}
			});
			if (currentLine.length > 0) {
				lines.push(currentLine.join(" "));
			}

			// Add each line
			lines.forEach((line, i) => {
				group
					.append("text")
					.attr("class", "column-label")
					.attr("y", i * 15)
					.attr("text-anchor", "middle")
					.text(line);
			});

			// Add subtitle if exists
			if (d.subtitle) {
				const subtitleWords = d.subtitle.split(" ");
				let subtitleLines = [];
				let currentSubLine: any = [];

				subtitleWords.forEach((word: string) => {
					if (currentSubLine.length === 0 || (currentSubLine.join(" ") + " " + word).length <= 20) {
						currentSubLine.push(word);
					} else {
						subtitleLines.push(currentSubLine.join(" "));
						currentSubLine = [word];
					}
				});
				if (currentSubLine.length > 0) {
					subtitleLines.push(currentSubLine.join(" "));
				}

				subtitleLines.forEach((line, i) => {
					group
						.append("text")
						.attr("class", "column-subtitle")
						.attr("y", lines.length * 15 + 5 + i * 12)
						.attr("text-anchor", "middle")
						.text(line);
				});
			}
		});

		// Add title at the top
		const titleX =
			data.length === 6
				? x(data[2].name) - 25 // For salesData2: between rectangle 1-2, shifted 20px left
				: (x(data[2].name) + x(data[3].name)) / 2 + 30; // For gpData2: between rectangle 1-4, shifted 20px right

		// Function to wrap text
		const wrapTitle = (text: string, width: any) => {
			const words = text.split(" ");
			const lines = [];
			let currentLine: any = [];

			words.forEach((word) => {
				if (currentLine.length === 0 || (currentLine.join(" ") + " " + word).length <= width) {
					currentLine.push(word);
				} else {
					lines.push(currentLine.join(" "));
					currentLine = [word];
				}
			});
			if (currentLine.length > 0) {
				lines.push(currentLine.join(" "));
			}
			return lines;
		};

		// Create title with potential wrapping
		const titleGroup = svg
			.append("g")
			.attr("class", "title-group")
			.attr("transform", `translate(${titleX}, ${data.length === 8 ? 30 : 0})`);

		const titleLines =
			data.length === 6
				? wrapTitle(multicolumnTitle.replace(/<[^>]*>/g, ""), 25) // Wrap sales title to fit 2 columns
				: [multicolumnTitle.replace(/<[^>]*>/g, "")]; // Don't wrap GP title

		// Add title lines
		titleLines.forEach((line, i) => {
			titleGroup
				.append("text")
				.attr("class", "chart-title")
				.attr("y", -margin.bottom + -170 + i * 15) // Add spacing between lines
				.attr("text-anchor", "middle")
				.text(line);
		});

		// Add subtitle - position relative to last line of title
		titleGroup
			.append("text")
			.attr("class", "chart-subtitle")
			.attr("y", -margin.bottom + -170 + (titleLines.length - 1) * 15 + 20) // 20px gap after last title line
			.attr("text-anchor", "middle")
			.text(multicolumnSubtitle);

		// Cleanup function to remove tooltip when component unmounts
		return () => {
			d3.select("body").select(".tooltip").remove();
		};
	}, [dataProp, onBarClick]);

	return (
		<div className="d3-container">
			<svg ref={svgRef}></svg>
		</div>
	);
};

export default D3Waterfall;
