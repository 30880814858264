import axios from "axios";
import { authRefreshToken } from "../services/auth.service";

const backend = process.env.REACT_APP_BE_URL || "";

export const saveOriginalRequest = (url: string) => {
  if (url && !url.includes("/login")) {
    sessionStorage.setItem("redirectAfterLogin", url);
  }
};

export const getRedirectUrl = () => {
  return sessionStorage.getItem("redirectAfterLogin") || "/";
};

export const clearRedirectUrl = () => {
  sessionStorage.removeItem("redirectAfterLogin");
};

const isTokenExpired = () => {
  let localStorageExpirationTime = localStorage.getItem("tokenExpiration");

  if (!localStorageExpirationTime) {
    console.log("Token does not exist in local storage");
    return true;
  } else {
    const expirationTime = new Date(localStorageExpirationTime).toISOString();
    const currentUtcTime = new Date().toISOString();
    return currentUtcTime > expirationTime;
  }
};

const refreshAccessToken = async () => {
  const refreshToken = localStorage.getItem("refreshToken");
  try {
    const response = await authRefreshToken(refreshToken);
    const accessToken = response.data.accessToken;
    const expirationTime = response.data.expiresOn; // Token expiration time
    const idToken = response.data.idToken;
    const newRefreshToken = response.data.refreshToken;

    localStorage.setItem("accessToken", accessToken);
    localStorage.setItem("idToken", idToken);
    localStorage.setItem("refreshToken", newRefreshToken);
    localStorage.setItem("tokenExpiration", expirationTime);

    return accessToken; // Return the refreshed token
  } catch (error) {
    console.error("Token refresh failed:", error);
    saveOriginalRequest(window.location.pathname);
    window.location.href = "/login";
    throw error;
  }
};

export const axiosRequestInterceptor = () => {
  const api = axios.create({
    baseURL: backend, // API base URL
    withCredentials: true,
  });

  api.interceptors.request.use(
    async (config) => {
      if (isTokenExpired()) {
        try {
          const newAccessToken = await refreshAccessToken(); // Wait for token refresh

          config.headers.Authorization = `Bearer ${newAccessToken}`; // Use the new token
        } catch (error) {
          return Promise.reject(error); // If refresh fails, reject the request
        }
      } else {
        const accessToken = localStorage.getItem("accessToken");
        if (accessToken) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
      }
      config.headers.Accept = "application/json"; // Set other headers

      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  return api;
};
