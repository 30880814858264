import React, { useEffect, useState } from "react";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import ExecSumParagraph from "../../../components/ExecSumParagraph";
import FeedbackComponent from "../../../components/FeedbackComponent";
import Loader from "../../../components/Loader";
import ParentAccordion from "../../../components/ParentAccordion";
import ReportTitle from "../../../components/ReportTitle";
import Sources from "../../../components/Sources";

import { ITrackingEvent, TrackingEventType } from "../../../Interfaces/events.d";
import { IReportData, ISummarySection } from "../../../Interfaces/reports";

import BlockContent from "../../../components/BlockContent";
import SectionTitle from "../../../components/SectionTitle";
import { ITransformedTableData } from "../../../Interfaces/tabular-data";
import { addEvent } from "../../../services/event.service";
import { addReportFeedback } from "../../../services/feedback.service";
import { getReportChildrenDataApi, getReportData } from "../../../services/report.service";
import {
	transformColumnDefinitions,
	transformRows,
} from "../../../utils/ag-grid/ag-grid-transformers";
import "./report-container.scss";

const ReportV2 = () => {
	const { reportId } = useParams();
	const navigate = useNavigate();

	const [loading, setLoading] = useState(true);
	const [error, setError] = useState(null);
	const [titleSection, setTitleSection] = useState<IReportData | null>(null);
	const [summarySection, setSummarySection] = useState<ISummarySection | null>(null);
	const [elements, setElements] = useState<any>(null);

	useEffect(() => {
		const fetchData = async () => {
			try {
				recordEvent({
					name: "PAGE LOAD",
					description: `Load Report page for report id ${reportId}`,
					type: TrackingEventType.PageLoad,
				});

				if (!reportId) {
					navigate("/");
					return;
				}

				const response = await getReportData(reportId);
				const result = response.reportData;

				setTitleSection(getSection(result, "Title"));
				setSummarySection({
					section: {
						...getSection(result, "SummarySection"),
					},
					sourcesAndDefinitions: getTextContentSection(
						getSection(result, "SourcesAndDefinitions"),
						"summary_section"
					),
					showModal: false,
				});

				buildElements(result);
			} catch (err: any) {
				setError(err.message);
			} finally {
				setLoading(false);
			}
		};

		fetchData();
	}, [reportId, navigate]);

	const getReportChildrenData = async (parentId: string) => {
		try {
			recordEvent({
				name: "GET CHILDREN BUTTON CLICK",
				description: `Fetching children for parent ${parentId}`,
				type: TrackingEventType.ButtonClicked,
			});
			return await getReportChildrenDataApi(reportId!, parentId);
		} catch (error) {
			console.error("Error fetching children:", error);
			return [];
		}
	};

	const handleFeedbackSubmit = async ({
		section,
		feedback,
	}: {
		section: string;
		feedback: string;
	}) => {
		await addReportFeedback({
			reportId: reportId!,
			sectionName: section,
			feedbackValue: feedback,
		});
		toast.success("Feedback submitted successfully!");
	};

	const getSection = (result: IReportData[], sectionName: string) => {
		return result?.find((x) => x.name === sectionName) || null;
	};

	const getTextContentSection = (item: any, section: string) => {
		return item?.textContent?.filter((x: any) => x.key == section)[0];
	};

	const getContentValue = (data: IReportData[], sectionName: string, key: string) =>
		getTextContentSection(getSection(data, sectionName), key)?.value;

	const buildElements = (result: IReportData[]) => {
		const sourcesAndDefinitions = getSection(result, "SourcesAndDefinitions");

		const performanceOverview = {
			title: "Performance Overview",
			showDetails: false,
			items: [
				{
					title: "Performance Overview",
					items: [
						{
							title: "Upward Comparison & Breakdown",
							showDetails: false,
							showModal: false,
							subtitle: getContentValue(result, "Breakdown", "Headline"),
							isHighlighted:
								getContentValue(result, "Breakdown", "Highlight Headline")?.toUpperCase() ===
								"TRUE",
							content: [
								{
									type: "text",
									data: getContentValue(result, "Breakdown", "Body"),
								},
								{
									type: "table",
									title: getContentValue(result, "breakdown_section", "TableTitle"),
									metadata: getSection(result, "breakdown_section")?.metadata,
									data: buildTableData(result, "breakdown_section"),
								},
							],
							sourcesAndDefinitions: getTextContentSection(sourcesAndDefinitions, "breakdown"),
						},
					],
				},
			],
		};

		const salesGPBreakdown = {
			title: "Sales and GP$ Waterfall",
			showDetails: false,
			items: [buildPVMSection(result)],
		};

		const promotionsProductStore = {
			title: "Product and Store Impacts",
			showDetails: false,
			items: buildPerformanceDriversSection(result),
		};

		const channelCustomerShifts = {
			title: "Channel and Customer Impacts",
			showDetails: false,
			items: [
				{
					title: "Channel and Customer shifts",
					items: [
						buildPerformanceDriversBlock(
							result,
							"ChannelDeepdive",
							"channel",
							"Channel",
							false,
							false,
							sourcesAndDefinitions,
							"channel"
						),
						buildPerformanceDriversBlock(
							result,
							"AgeDeepdive",
							"generation",
							"Customer",
							false,
							false,
							sourcesAndDefinitions,
							"customer"
						),
					],
				},
			],
		};

		const builtElements = [
			performanceOverview,
			salesGPBreakdown,
			promotionsProductStore,
			channelCustomerShifts,
		];

		setElements(builtElements);
	};

	const buildPVMSection = (result: IReportData[]) => {
		const section = getSection(result, "PVMSection");
		const salesData = JSON.parse(
			getTextContentSection(section, "Sales KPIs Data")?.value.replaceAll("'", '"')
		);
		const gpData = JSON.parse(
			getTextContentSection(section, "GP KPIs Data")?.value.replaceAll("'", '"')
		);

		const salesSection = {
			title: "Sales",
			subtitle: getTextContentSection(section, "Sales KPIs Headline")?.value,
			dataTitle: salesData.title,
			showDetails: false,
			pin: false,
			showModal: false,
			multicolumnTitle: salesData.asp.title,
			multicolumnSubtitle: salesData.asp.subtitle,
			sourcesAndDefinitions: getTextContentSection(
				getSection(result, "SourcesAndDefinitions"),
				"sales_waterfall"
			),
			content: [
				{
					type: "metrics",
					data: [
						{
							...getTextContentSection(section, "Sales KPIs[0]"),
							variation: salesData.left.value - salesData.right.value < 0 ? "+" : "-",
						},
						{
							...getTextContentSection(section, "Sales KPIs[1]"),
							variation: salesData.asp.value > 0 ? "+" : "-",
						},
						{
							...getTextContentSection(section, "Sales KPIs[2]"),
							variation: salesData.volume.value > 0 ? "+" : "-",
						},
					],
				},
				{
					type: "bullets",
					data: [getTextContentSection(section, "Sales KPIs Body")?.value],
				},
				{
					type: "waterfallChart",
					title: "Sales Breakdown (comparison period to focus period)",
					chart: "sales",
					// topData: [
					// 	buildPVMChartBar(salesData.left, "white", "grey"),
					// 	buildPVMChartBar(salesData.asp),
					// 	buildPVMChartBar(salesData.volume, "red"),
					// 	buildPVMChartBar(salesData.right, "white", "grey"),
					// ].filter((item) => item.y !== undefined),
					bottomData: [
						buildPVMChartBar(salesData.left, "white", "grey"),
						buildPVMChartBar(
							salesData.asp.breakdown.price,
							"white",
							null,
							getSection(result, "waterfall_sales_price")
						),
						buildPVMChartBar(
							salesData.asp.breakdown.mix,
							"white",
							null,
							getSection(result, "waterfall_sales_mix")
						),
						buildPVMChartBar(
							salesData.volume,
							"black",
							null,
							getSection(result, "waterfall_sales_volume")
						),
						buildPVMChartBar(
							salesData.residual,
							"black",
							null,
							getSection(result, "waterfall_sales_residual")
						),
						buildPVMChartBar(salesData.right, "white", "grey"),
					].filter((item) => item.y !== undefined),
				},
			],
		};

		const gpSection = {
			title: "GP",
			subtitle: getTextContentSection(section, "GP KPIs Headline")?.value,
			dataTitle: gpData.title,
			showDetails: false,
			pin: false,
			showModal: false,
			multicolumnTitle: gpData.margin.title,
			multicolumnSubtitle: gpData.margin.subtitle,
			sourcesAndDefinitions: getTextContentSection(
				getSection(result, "SourcesAndDefinitions"),
				"gp_waterfall"
			),

			content: [
				{
					type: "metrics",
					data: [
						{
							...getTextContentSection(section, "GP KPIs[0]"),
							variation: gpData.left.value - salesData.right.value < 0 ? "+" : "-",
						},
						{
							...getTextContentSection(section, "GP KPIs[1]"),
							variation: gpData.margin.breakdown.cogs.value > 0 ? "+" : "-",
						},
						{
							...getTextContentSection(section, "GP KPIs[2]"),
							variation: gpData.margin.value > 0 ? "+" : "-",
						},
					],
				},
				{
					type: "bullets",
					data: [getTextContentSection(section, "GP KPIs Body")?.value],
				},
				{
					type: "waterfallChart",
					title: "GP$ Breakdown (comparison period to focus period)",
					// topData: [
					// 	buildPVMChartBar(gpData.left, "white", "grey"),
					// 	buildPVMChartBar(gpData.sales),
					// 	buildPVMChartBar(gpData.margin),
					// 	buildPVMChartBar(gpData.right, "white", "grey"),
					// ].filter((item) => item.y !== undefined),
					data: gpData,
					chart: "gp",
					bottomData: [
						buildPVMChartBar(gpData.left, "white", "grey"),
						buildPVMChartBar(
							gpData.margin.breakdown.scanback,
							"white",
							null,
							getSection(result, "waterfall_gp_scanback")
						),
						buildPVMChartBar(
							gpData.margin.breakdown.cogs,
							"white",
							null,
							getSection(result, "waterfall_gp_cogs")
						),
						buildPVMChartBar(
							gpData.margin.breakdown.price,
							"black",
							null,
							getSection(result, "waterfall_gp_price")
						),
						buildPVMChartBar(
							gpData.margin.breakdown.mix,
							"black",
							null,
							getSection(result, "waterfall_gp_mix")
						),
						buildPVMChartBar(gpData.sales, "black", null, getSection(result, "waterfall_gp_sales")),
						buildPVMChartBar(
							gpData.residual,
							"black",
							null,
							getSection(result, "waterfall_gp_residual")
						),
						buildPVMChartBar(gpData.right, "white", "grey"),
					].filter((item) => item.y !== undefined),
				},
			],
		};

		return {
			title: "Sales and GP$ Breakdown",
			items: [salesSection, gpSection],
		};
	};

	const buildPVMChartBar = (
		chartBar: any,
		indexLabelFontColor: string | null = null,
		color: string | null = null,
		details: any = null
	) => ({
		title: chartBar?.title,
		subtitle: chartBar?.subtitle,
		y: chartBar?.value,
		indexLabel: chartBar?.value_formatted,
		indexLabelFontColor: indexLabelFontColor ?? "white",
		color,
		// details,
		tableTitle: getTextContentSection(details, "TableTitle")?.value,
	});

	const buildPerformanceDriversSection = (result: any) => {
		const sourcesAndDefinitions = getSection(result, "SourcesAndDefinitions");

		return [
			{
				title: "Promotions",
				items: [
					buildPerformanceDriversBlock(
						result,
						"PromoDeepdive",
						"promotion",
						"Promotions",
						false,
						false,
						sourcesAndDefinitions,
						"promotion"
					),
				],
			},
			{
				title: "Product and Store",
				items: [
					buildPerformanceDriversBlock(
						result,
						"NewSKUsRangeDeepDive",
						"newdeleted_skus",
						"New/Deleted SKUs",
						false,
						false,
						sourcesAndDefinitions,
						"new_deleted_skus"
					),
					buildPerformanceDriversBlock(
						result,
						"RangeChangeRangeDeepDive",
						"store_ranging",
						"Store Ranging",
						false,
						false,
						sourcesAndDefinitions,
						"store_ranging"
					),
					buildPerformanceDriversBlock(
						result,
						"NewStoreRangeDeepRangeDive",
						"new_closed_stores",
						"New/Closed Stores",
						false,
						false,
						sourcesAndDefinitions,
						"new_closed_stores"
					),
					buildPerformanceDriversBlock(
						result,
						"StockDeepDive",
						"stock",
						"Stock",
						false,
						false,
						sourcesAndDefinitions,
						"stock"
					),
				],
			},
		];
	};

	const handleWaterfallBarClick = (event: any) => {
		recordEvent({
			name: "WATERFALL CHART BAR CLICK",
			description: `Click on ${event.dataPoint.indexLabel} under ${event.dataPoint.title} in table ${event.dataPoint.tableTitle}`,
			type: TrackingEventType.ButtonClicked,
		});
	};

	const buildPerformanceDriversBlock = (
		result: any,
		textSectionName: string,
		dataSectionName: string,
		title: any = null,
		showDetails: boolean,
		showModal: boolean,
		sourcesAndDefinitions: any = null,
		sourcesName: string
	) => ({
		title,
		showDetails,
		showModal,
		subtitle: getContentValue(result, textSectionName, "Headline"),
		isHighlighted:
			getContentValue(result, textSectionName, "Highlight Headline")?.toUpperCase() === "TRUE",
		content: [
			{
				type: "text",
				data: getContentValue(result, textSectionName, "Body"),
			},
			{
				type: "table",
				title: getContentValue(result, dataSectionName, "TableTitle"),
				metadata: getSection(result, dataSectionName)?.metadata,
				data: buildTableData(result, dataSectionName),
			},
		],
		sourcesAndDefinitions: getTextContentSection(sourcesAndDefinitions, sourcesName),
	});

	const updateStateFunction = (
		key: string,
		switchState: boolean,
		sectionIndex: number | null = null,
		itemIndex: number | null = null,
		blockIndex: number | null = null
	) => {
		setElements((elm: any) => {
			return elm.map((prevSections: any, prevIndex: number) => {
				return itemIndex == null && blockIndex == null && prevIndex === sectionIndex
					? {
							...prevSections,
							pin: key === "pin" ? switchState : prevSections.pin,
							showModal: key === "modal" ? switchState : prevSections.showModal,
					  }
					: {
							...prevSections,
							items: prevSections.items.map((section: any, stateSectionIndex: any) => {
								return blockIndex == null &&
									itemIndex === stateSectionIndex &&
									prevIndex === sectionIndex
									? {
											...section,
											pin: key === "pin" ? switchState : prevSections.pin,
											showModal: key === "modal" ? switchState : prevSections.showModal,
									  }
									: {
											...section,
											items: section?.items?.map((subSection: any, subIndex: any) =>
												blockIndex === subIndex &&
												itemIndex === stateSectionIndex &&
												prevIndex === sectionIndex
													? {
															...subSection,
															pin: key === "pin" ? switchState : prevSections.pin,
															showModal: key === "modal" ? switchState : prevSections.showModal,
													  }
													: subSection
											),
									  };
							}),
					  };
			});
		});
	};

	const modalFunctionSummary = (state: any) => {
		state((prevState: any) => {
			return {
				...prevState,
				showModal: !prevState.showModal,
			};
		});
	};

	const expandFunction = (
		switchState: boolean,
		sectionIndex: number | null = null,
		itemIndex: number | null = null,
		blockIndex: number | null = null
	) => {
		collapseReport();

		setElements((elm: any) => {
			return elm.map((prevSections: any, prevIndex: number) => {
				return itemIndex == null && blockIndex == null && prevIndex === sectionIndex
					? {
							...prevSections,
							showDetails: switchState,
					  }
					: {
							...prevSections,
							items: prevSections.items.map((section: any, stateSectionIndex: any) => {
								return blockIndex == null &&
									itemIndex === stateSectionIndex &&
									prevIndex === sectionIndex
									? {
											...section,
											showDetails: switchState,
									  }
									: {
											...section,
											items: section?.items?.map((subSection: any, subIndex: any) =>
												blockIndex === subIndex &&
												itemIndex === stateSectionIndex &&
												prevIndex === sectionIndex
													? {
															...subSection,
															showDetails: switchState,
													  }
													: subSection
											),
									  };
							}),
					  };
			});
		});
	};

	const collapseReport = () => {
		setElements((elm: any) => {
			return elm.map((prevSections: any) => {
				return {
					...prevSections,
					showDetails: prevSections.pin ? prevSections.showDetails : false,
					items:
						prevSections.items?.map((section: any) => ({
							...section,
							showDetails: section.pin ? section.showDetails : false,
							items:
								section.items?.map((subSection: any) => ({
									...subSection,
									showDetails: subSection.pin ? subSection.showDetails : false,
								})) || [],
						})) || [],
				};
			});
		});
	};

	const buildTableData = (result: IReportData[], section: string): ITransformedTableData | null => {
		let data = getSection(result, section);
		return data == null
			? null
			: {
					transformedRows: transformRows(data),
					transformedColumns: transformColumnDefinitions(data),
			  };
	};

	const scrollToElementWithOffset = (element: any) => {
		if (!element) return;
		setTimeout(() => {
			const elementPosition = element.getBoundingClientRect().top + window.scrollY;
			const offsetPosition = elementPosition - 110;
			requestAnimationFrame(() => {
				window.scrollTo({
					top: offsetPosition,
					behavior: "smooth",
				});
			});
		}, 1);
	};

	const recordEvent = (event: ITrackingEvent) => {
		addEvent({ ...event, reportId }).then((_) => _);
	};

	return (
		<div className="report">
			{loading ? (
				<div className="report__loader-container">
					<Loader size={100}></Loader>
				</div>
			) : (
				<>
					<div className="report__header">
						<div className="report-demo__header-top">
							<div className="report__logo" />
							<div className="report__watermark" />
						</div>
						<div className="report__header-bottom"></div>
					</div>
					<div className="report__content">
						{titleSection && titleSection != null ? (
							<ReportTitle
								text={getTextContentSection(titleSection, "Title").value}
								subtitle={getTextContentSection(titleSection, "Subtitle").value}
							/>
						) : (
							""
						)}
						<div className="report__content-exec-summary">
							{summarySection && summarySection != null && summarySection.section?.textContent
								? summarySection.section?.textContent
										.filter((x: any) => x.key != null || x.value != null)
										.map((para: any, index: any) => {
											return (
												<div key={"sum-" + index}>
													<ExecSumParagraph para={para} key={"sum-par-" + index} />
												</div>
											);
										})
								: ""}
							<Sources
								onClick={() => modalFunctionSummary(setSummarySection)}
								onModalClose={() => modalFunctionSummary(setSummarySection)}
								isModalOpen={summarySection?.showModal}
								title={"Sources and Definitions"}
								text={summarySection?.sourcesAndDefinitions?.value}
							/>

							{summarySection && (
								<FeedbackComponent
									sectionName={summarySection?.section?.name}
									onClick={handleFeedbackSubmit}
								/>
							)}
						</div>

						{elements && elements != null
							? elements.map((section: any, sectionIndex: number) => {
									return (
										<ParentAccordion
											key={`section-${sectionIndex}`}
											onClick={() => {
												recordEvent({
													name: "EXPAND BUTTON CLICK",
													description: `Click on ${section.title} button`,
													type: TrackingEventType.ButtonClicked,
												});
												const element = document.getElementById(`heading-${sectionIndex}`);
												scrollToElementWithOffset(element);
											}}
											id={`heading-${sectionIndex}`}
											text={section.title}
										>
											{section.items.map((item: any, itemIndex: number) => {
												return (
													<div
														className="report-analysis"
														key={`item-${sectionIndex}-${itemIndex}`}
													>
														{item.title !== "Performance Overview" && (
															<SectionTitle title={item.title} pinDisplay={"none"} />
														)}
														<div
															className={`report-analysis__section ${
																item.title === "Performance Overview" ? "performance" : ""
															}`}
														>
															{item.items.map((block: any, blockIndex: number) => {
																return (
																	<React.Fragment
																		key={`block-${sectionIndex}-${itemIndex}-${blockIndex}`}
																	>
																		<BlockContent
																			sectionIndex={sectionIndex}
																			itemIndex={itemIndex}
																			blockIndex={blockIndex}
																			block={block}
																			section={section}
																			updateStateFunction={updateStateFunction}
																			recordEvent={recordEvent}
																			getReportChildrenData={getReportChildrenData}
																			reportId={reportId}
																			handleWaterfallBarClick={handleWaterfallBarClick}
																			item={item}
																			expandFunction={expandFunction}
																			scrollToElementWithOffset={scrollToElementWithOffset}
																			handleFeedbackSubmit={handleFeedbackSubmit}
																		/>
																	</React.Fragment>
																);
															})}
															<FeedbackComponent
																key={item.title}
																sectionName={item.title}
																onClick={handleFeedbackSubmit}
															/>
														</div>
													</div>
												);
											})}
										</ParentAccordion>
									);
							  })
							: ""}

						<div className="report__testing">TESTING ONLY</div>
					</div>
				</>
			)}
		</div>
	);
};

export default ReportV2;
