import axios from "axios";
import { axiosRequestInterceptor } from "./api";

const backend = process.env.REACT_APP_BE_URL || "";
const authEndpoint = `${backend}/api/auth`;

export const authLogin = async (credentials: any): Promise<any> => {
	const response = await axios.post(`${authEndpoint}/login`, credentials);
	return response;
};

export const authRefreshToken = async (refreshToken: any): Promise<any> => {
	const response = await axios.post(`${authEndpoint}/refresh`, {
		refreshToken,
	});
	return response;
};

export const createPassword = async (credentials: any): Promise<any> => {
	return await axios.post(`${backend}/api/auth/reset-password`, credentials);
};

export const authLogout = async (): Promise<any> => {
	const apiClient = axiosRequestInterceptor();
	const response = await apiClient.post(`${authEndpoint}/logout`, null);
	return response;
};

export const resetPassword = async (credentials: any): Promise<any> => {
	const response = await axios.post(
		`${authEndpoint}/reset-password`,
		credentials
	);
	return response;
};

export const forgotPassword = async (emailAddress: string): Promise<any> => {
	const response = await axios.post(`${authEndpoint}/forgot-password`, {
		email: emailAddress,
	});
	return response;
};
