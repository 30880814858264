import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import "./App.scss";

import AppRoutes from "./routes/routes";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import {
  reactPlugin,
  useTrackPageViews,
} from "./services/app-insights.service";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider, useAuth } from "./context/auth.context";
import SigninPage from "./routes/SigninPage";
import ResetPassword from "./routes/ResetPassword";

import "ag-grid-enterprise";
import "@ag-grid-community/styles/ag-grid.css";
import "@ag-grid-community/styles/ag-theme-quartz.css";
import CustomToaster from "./components/CustomToaster";

const FrontendContent = () => {
  return <AppRoutes />;
};

const App = (props: any) => {
  const { instance } = props;

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Router>
        <AuthProvider>
          <div className="App">
            <CustomToaster />
            <FrontendContent />
          </div>
        </AuthProvider>
      </Router>
    </AppInsightsContext.Provider>
  );
};

export default App;
