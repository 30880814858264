import { useEffect, useRef } from "react";
import "./waterfall-chart-component.scss";
// @ts-ignore
import CanvasJSReact from "@canvasjs/react-charts";

var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const WaterfallChart = ({
	handleWaterfallBarClick,
	dataPoints,
	chart,
	multicolumnTitle,
	multicolumnSubtitle,
}: any) => {
	// y axis offset calculation
	const lastColumn = dataPoints[dataPoints.length - 1];
	const maxColumn = Math.min(dataPoints[0].y, lastColumn.y);
	const negativeSum = dataPoints
		.slice(1, -1)
		.reduce((acc: any, dp: any) => acc + Math.min(dp.y, 0), 0);
	const offsetSum = maxColumn + negativeSum;
	const finalOffsetCalc = offsetSum * 0.95;

	// inject emoji into label
	if (chart === "salesBottom" || chart === "gpBottom") {
		const middleElements = dataPoints.slice(1, -1);
		middleElements.forEach((element: any) => {
			let emoji = !element.details ? "" : " ☰";
			if (!element.indexLabel.endsWith(emoji)) {
				element.indexLabel = element.indexLabel + emoji;
			}
		});
	}

	// Format the data points to include conditional index labels

	let formattedDataPoints: any;
	formattedDataPoints = dataPoints.map((point: any, index: number) => {
		const isGreyBar = index === 0 || index === dataPoints.length - 1;

		let xPosition = index;
		let xOffset = 0;

		if (chart === "gpTop") {
			if (index === 2) {
				xPosition = 2;
				xOffset = 1;
			} else if (index === 3) {
				xPosition = 5;
			}
		}

		if (chart === "salesTop") {
			if (index === 1) {
				xPosition = 1;
				xOffset = 0.5;
			} else if (index === 2) {
				xPosition = 3;
			} else if (index === 3) {
				xPosition = 4;
			}
		}
		return {
			...point,
			indexLabel: point.indexLabel,
			toolTipContent: `{label}: <strong>{indexLabel}</strong>`,
			indexLabelFontColor: "#fff",
			//indexLabelBackgroundColor: getHexFromText(point.indexLabelFontColor),
			indexLabelBackgroundColor: isGreyBar
				? "rgba(128, 128, 128, 0.5)"
				: point.y >= 0
				? "rgba(13, 135, 48, 0.5)"
				: "rgba(183, 49, 37, 0.5)",
			y: dataPoints.length - 1 === index ? -point.y : point.y,
			x: xPosition + xOffset,
			label: point.title,
		};
	});

	const stripLinesConfig: Record<
		string,
		{
			length: number;
			lines: { startValue: number; endValue: number; color: string }[];
		}
	> = {
		"salesTop-4": {
			length: 4,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 2.5, color: "#FDFEF0" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#F9FBFA" },
			],
		},
		"salesBottom-5": {
			length: 5,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#FDFEF0" },
				{ startValue: 1.5, endValue: 2.5, color: "#FDFEF0" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#F9FBFA" },
			],
		},
		"gpTop-4": {
			length: 4,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#FDFEF0" },
				{ startValue: 1.5, endValue: 4.5, color: "#F0F9FE" },
				{ startValue: 4.5, endValue: 5.5, color: "#F9FBFA" },
			],
		},
		"gpBottom-6": {
			length: 6,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#FDFEF0" },
				{ startValue: 1.5, endValue: 2.5, color: "#F0F9FE" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#F0F9FE" },
				{ startValue: 4.5, endValue: 5.5, color: "#F9FBFA" },
			],
		},
		"salesBottom-6": {
			length: 6,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#FDFEF0" },
				{ startValue: 1.5, endValue: 2.5, color: "#FDFEF0" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#fef5f0" },
			],
		},
		"gpBottom-7": {
			length: 7,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#F0F9FE" },
				{ startValue: 1.5, endValue: 2.5, color: "#F0F9FE" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#FDFEF0" },
				{ startValue: 4.5, endValue: 5.5, color: "#FDFEF0" },
			],
		},
		"gpBottom-8": {
			length: 8,
			lines: [
				{ startValue: -0.5, endValue: 0.5, color: "#F9FBFA" },
				{ startValue: 0.5, endValue: 1.5, color: "#F0F9FE" },
				{ startValue: 1.5, endValue: 2.5, color: "#F0F9FE" },
				{ startValue: 2.5, endValue: 3.5, color: "#F0F9FE" },
				{ startValue: 3.5, endValue: 4.5, color: "#F0F9FE" },
				{ startValue: 4.5, endValue: 5.5, color: "#FDFEF0" },
				{ startValue: 5.5, endValue: 6.5, color: "#fef5f0" },
			],
		},
	};

	// Generate the lookup key
	const key = `${chart}-${dataPoints?.length}`;
	const stripLines = stripLinesConfig[key]?.lines || [];

	const handleBarClick = (event: any) => {
		const { dataPoint } = event;
		// alert(`You clicked on value: ${dataPoint.y}`);
		handleWaterfallBarClick(event);
	};

	const options = {
		theme: "light2",
		backgroundColor: "#f9fbfa",
		title: {
			// text: "GoodWork Waterfall Chart"
		},
		animationEnabled: true,
		axisX: {
			interval: 1,
			labelFontSize: 0,
			labelFontColor: "#000",
			stripLines: stripLines,
		},
		axisY: {
			valueFormatString: "$#,###,##0.",
			gridThickness: 0,
			// minimum: 0,
			minimum: finalOffsetCalc,
			// interval: 50000000,
		},
		data: [
			{
				type: "waterfall",
				click: handleBarClick,
				yValueFormatString: "$#,##0,,.0m",
				yValueFontColor: "#FF5733",
				indexLabel: "{y}",
				// indexLabelFontSize: 12,
				indexLabelPlacement: "inside",
				risingColor: "#0D8730",
				fallingColor: "#B73125",
				dataPoints: formattedDataPoints,
			},
		],
	};

	const chartContainer: any = useRef(null);

	// useEffect(() => {
	//   chartContainer.current?.render();
	// }, []);

	useEffect(() => {
		// Adjust the headers' width dynamically based on the chart's width
		const chartWidth = chartContainer.current?.clientWidth;
		const headers = document.querySelectorAll(".chart-header");
		if (chartWidth && headers.length) {
			const sectionWidth = chartWidth / options.data[0].dataPoints.length;
			headers.forEach((header, index) => {
				(header as HTMLElement).style.width = `${sectionWidth}px`;
				(header as HTMLElement).style.left = `${index * sectionWidth}px`;
			});
		}
	}, []);

	return (
		<div className="waterfall">
			<div className={`waterfall_multicolumn-container ${chart}`}>
				<div
					className="waterfall__multicolumn-title"
					dangerouslySetInnerHTML={{
						__html: multicolumnTitle,
					}}
				/>
				<div
					className="waterfall__multicolumn-subtitle"
					dangerouslySetInnerHTML={{
						__html: multicolumnSubtitle,
					}}
				/>
			</div>
			<div className={`x-axis-labels ${chart}`}>
				<>
					{dataPoints.map((a: any, index: number) => {
						let widthMultiplier = 1;
						if (chart === "gpTop") {
							if (index === 2) {
								widthMultiplier = 3;
							}
						}
						if (chart === "salesTop") {
							if (index === 1) {
								widthMultiplier = 2;
							}
						}

						return (
							<span
								key={index}
								dangerouslySetInnerHTML={{
									__html: `${a.title}<br/><span style="font-size: 12px">${a.subtitle}</span>`,
								}}
								style={{
									width: `calc((100% / ${dataPoints.length}) * ${widthMultiplier} - 1px )`,
									backgroundColor: stripLines[index % stripLines.length]?.color,
								}}
							></span>
						);
					})}
				</>
			</div>
			<CanvasJSChart options={options} ref={chartContainer} />
		</div>
	);
};

export default WaterfallChart;
