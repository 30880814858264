import { useCallback, useEffect, useState } from "react";
import { IColumnDefinition } from "../../Interfaces/reports";
import { ITransformedRow } from "../../Interfaces/tabular-data";
import { salesTableRows } from "../../routes/Report/reportData";
import { getReportChildrenDataApi } from "../../services/report.service";
import {
	transformColumnDefinitions,
	transformRows,
} from "../../utils/ag-grid/ag-grid-transformers";
import D3Waterfall from "../D3Waterfall";
import SsrmTreeDataTable from "../SsrmTreeDataTable/SsrmTreeDataTable";
import WaterfallChart from "../WaterfallChart";
import "./details-container.scss";

const DetailsContainer = ({
	waterfallBarClick = () => {}, // Default function to avoid errors,
	title,
	titleStyle,
	detailsHeading,
	detailsSubheading,
	dataPointsTop,
	dataPointsBottom,
	chart,
	reportId,
	renderFeedback,
	children,
	multicolumnTitle,
	multicolumnSubtitle,
}: any) => {
	const [showTable, setShowTable] = useState<boolean>(false);
	const [colDefs, setColDefs]: any = useState([]);
	const [tableTitle, setTableTitle] = useState<string>("");

	const [clickedDataPoint, setClickedDataPoint] = useState<any>(null);
	const [clickedDataPointId, setClickedDataPointId] = useState<string>("");
	const [transformedRows, setTransformedRows] = useState<ITransformedRow[]>([]);
	const [transformedColumns, setTransformedColumns] = useState<IColumnDefinition[]>([]);
	const [isDataReady, setIsDataReady] = useState<boolean>(false);

	const getDataPointId = (dataPoint: any): string => {
		if (!dataPoint) return "";

		try {
			const metadataKeys = Object.keys(dataPoint.metadata || {}).sort();
			const firstRowValues =
				dataPoint.initialData && dataPoint.initialData.length > 0
					? JSON.stringify(dataPoint.initialData[0].values || [])
					: "";

			return (
				`${dataPoint.name || ""}-${dataPoint.type || ""}-` +
				`${metadataKeys.join("-")}-${firstRowValues.substring(0, 50)}`
			);
		} catch (error) {
			return `${dataPoint.name || ""}-${Date.now()}`;
		}
	};

	useEffect(() => {
		if (clickedDataPoint) {
			try {
				setIsDataReady(false);

				const newTransformedRows = transformRows(clickedDataPoint);
				const newTransformedColumns = transformColumnDefinitions(clickedDataPoint);

				setTransformedRows(newTransformedRows);
				setTransformedColumns(newTransformedColumns);

				setTimeout(() => {
					setIsDataReady(true);
				}, 50);
			} catch (error) {
				setIsDataReady(false);
			}
		} else {
			setIsDataReady(false);
		}
	}, [clickedDataPoint]);

	const handleWaterfallBarClick = (event: any) => {
		waterfallBarClick(event);

		if (!event.dataPoint.details) {
			return;
		}

		const newClickedDataPoint = event.dataPoint.details;
		const newTableTitle = event.dataPoint.tableTitle;
		const newDataPointId = getDataPointId(newClickedDataPoint);

		if (showTable && newDataPointId === clickedDataPointId && newDataPointId !== "") {
			setShowTable(false);
			setClickedDataPoint(null);
			setClickedDataPointId("");
		} else {
			setTableTitle(newTableTitle);
			setClickedDataPoint(newClickedDataPoint);
			setClickedDataPointId(newDataPointId);
			setShowTable(true);
		}
	};

	const getReportChildrenData = async (parentId: string) => {
		try {
			const response = await getReportChildrenDataApi(reportId, parentId);
			return response;
		} catch (error) {
			return [];
		}
	};

	const getRowStyle = useCallback(
		(params: any) => {
			if (params.node.rowIndex === salesTableRows.length - 1) {
				return {
					backgroundColor: "#E6EBE9",
					fontWeight: "bold",
				};
			}
			return undefined;
		},
		[salesTableRows]
	);
	return (
		<div className="details-container">
			<div className="details-container__content">
				<div className="details-container__heading">{detailsHeading}</div>
				{detailsSubheading ? (
					<div className="details-container__subheading">{detailsSubheading}</div>
				) : (
					<></>
				)}

				{dataPointsTop && dataPointsTop.length > 0 && (
					<WaterfallChart
						handleWaterfallBarClick={handleWaterfallBarClick}
						dataPoints={dataPointsTop}
						chart={`${chart}Top`}
					/>
				)}
				{/* <WaterfallChart
					handleWaterfallBarClick={handleWaterfallBarClick}
					dataPoints={dataPointsBottom}
					chart={`${chart}Bottom`}
					multicolumnTitle={multicolumnTitle}
					multicolumnSubtitle={multicolumnSubtitle}
				/> */}

				<D3Waterfall
					wateterfallBarClick={handleWaterfallBarClick}
					dataPoints={dataPointsBottom}
					multicolumnTitle={multicolumnTitle}
					multicolumnSubtitle={multicolumnSubtitle}
				/>
				{children}
				{showTable &&
					clickedDataPoint &&
					isDataReady &&
					transformedRows.length > 0 &&
					transformedColumns.length > 0 && (
						<div className="table-container">
							<SsrmTreeDataTable
								title={tableTitle}
								columnDefs={transformedColumns}
								rowData={transformedRows}
								domLayout={"autoHeight"}
								rowModelType={"serverSide"}
								rowGroupPanelShow={"none"}
								enableCharts={true}
								enableRangeSelection={true}
								serverSideSortAllLevels={true}
								suppressAggFuncInHeader={true}
								treeData={true}
								groupDisplayType={"singleColumn"}
								metadata={clickedDataPoint.metadata}
								loadChildrenApiCall={getReportChildrenData}
							/>
						</div>
					)}
			</div>
		</div>
	);
};

export default DetailsContainer;
