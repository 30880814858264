import { Toaster } from 'react-hot-toast';

const CustomToaster = () => {
    return (
        <Toaster
            position="top-right"
            toastOptions={{
                duration: 3000,
                style: {
                    borderRadius: '4px',
                },
                success: {
                    style: {
                        background: '#b3ef40',
                        color: '#0D1005',
                    },
                },
                error: {
                    style: {
                        background: '#ef4444',
                        color: 'white',
                    },
                },
            }}
        />
    );
};

export default CustomToaster; 