import React from "react";
import "./exec-sum-para-demo-container.scss";

const ExecSumParagraph = (props: any) => {
	const { para } = props;
	return (
		<div className="exec-sum-para-demo">
			<div className="exec-sum-para-demo__title">{para.title}</div>
			{para.sentances.map((sentance: any, index: number) => {
				return (
					<div key={index}>
						<div className="exec-sum-para-demo__sentance">
							{sentance.sentance}
							{sentance.bullet && <li>{sentance.bullet}</li>}
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default ExecSumParagraph;
