import { IFeedback } from "../Interfaces/reports";
import { axiosRequestInterceptor } from "./api";

export const getFeedback = async (userId: string | null): Promise<any> => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.get("api/feedback", { params: { userId } });
  return response;
};

export const addReportFeedback = async (params: IFeedback) => {
  const apiClient = axiosRequestInterceptor();
  const response = await apiClient.post("api/feedback", params);
  return response;
};