import React, { useCallback, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-charts-enterprise";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "./client-side.scss";

const AgGridClientSide = (props: any) => {
	const {
		gridOptions,
		gridRef,
		domLayout,
		pagination,
		paginationPageSize,
		paginationPageSizeSelector,
		columnDefs,
		defaultColDef,
		rowModelType,
		rowGroupPanelShow,
		groupDisplayType,
		serverSideSortAllLevels,
		suppressAggFuncInHeader,
		enableCharts,
		paginateChildRows,
		enableRangeSelection,
		postMasterTableAgGrid,
		transformRows,
		sideBar,
		agGridMasterApiCall,
		getRowStyle,
		rowData,
		treeData,
		getDataPath,
	} = props;

	return (
		<div style={{ width: "100%", marginTop: "32px", marginBottom: "32px" }}>
			<div style={{ width: "100%" }} className={"ag-theme-quartz"}>
				<AgGridReact
					rowData={rowData}
					gridOptions={gridOptions}
					ref={gridRef}
					domLayout={domLayout}
					pagination={pagination}
					paginationPageSize={paginationPageSize}
					paginationPageSizeSelector={paginationPageSizeSelector}
					columnDefs={columnDefs}
					defaultColDef={defaultColDef}
					rowModelType={rowModelType}
					rowGroupPanelShow={rowGroupPanelShow}
					groupDisplayType={groupDisplayType}
					serverSideSortAllLevels={serverSideSortAllLevels}
					suppressAggFuncInHeader={suppressAggFuncInHeader}
					enableCharts={enableCharts}
					paginateChildRows={paginateChildRows}
					enableRangeSelection={enableRangeSelection}
					sideBar={sideBar}
					getRowStyle={getRowStyle}
					treeData={treeData}
					getDataPath={getDataPath}
				/>
			</div>
		</div>
	);
};

export default AgGridClientSide;
