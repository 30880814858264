import React, { useEffect, useRef, useState } from "react";
import { IoClose, IoContract, IoExpand } from "react-icons/io5";
import "./Modal.scss";

interface ModalProps {
	isOpen: boolean;
	onClose: () => void;
	children: React.ReactNode;
	title?: string;
	initialWidth?: number;
	initialHeight?: number;
	minWidth?: number;
	minHeight?: number;
	defaultFullScreen?: boolean;
	text?: string;
}

const Modal: React.FC<ModalProps> = ({
	isOpen,
	onClose,
	children,
	title = "",
	initialWidth = 600,
	initialHeight = 400,
	minWidth = 300,
	minHeight = 200,
	defaultFullScreen = true,
	text,
}) => {
	const [isFullScreen, setIsFullScreen] = useState(defaultFullScreen);
	const [dimensions, setDimensions] = useState({
		width: initialWidth,
		height: initialHeight,
	});
	const modalRef = useRef<HTMLDivElement>(null);
	const resizingRef = useRef(false);
	const startPositionRef = useRef({ x: 0, y: 0 });
	const startDimensionsRef = useRef({ width: 0, height: 0 });

	useEffect(() => {
		if (isOpen) {
			document.body.style.overflow = "hidden";
		} else {
			document.body.style.overflow = "unset";
			setIsFullScreen(defaultFullScreen);
			setDimensions({ width: initialWidth, height: initialHeight });
		}

		return () => {
			document.body.style.overflow = "unset";
		};
	}, [isOpen, initialWidth, initialHeight, defaultFullScreen]);

	const handleMouseDown = (e: React.MouseEvent, direction: string) => {
		e.preventDefault();
		resizingRef.current = true;
		startPositionRef.current = { x: e.clientX, y: e.clientY };
		startDimensionsRef.current = { ...dimensions };

		const handleMouseMove = (e: MouseEvent) => {
			if (!resizingRef.current) return;

			const deltaX = e.clientX - startPositionRef.current.x;
			const deltaY = e.clientY - startPositionRef.current.y;

			const newWidth = Math.max(startDimensionsRef.current.width + deltaX, minWidth);
			const newHeight = Math.max(startDimensionsRef.current.height + deltaY, minHeight);

			setDimensions({
				width: direction.includes("e") ? newWidth : dimensions.width,
				height: direction.includes("s") ? newHeight : dimensions.height,
			});
		};

		const handleMouseUp = () => {
			resizingRef.current = false;
			document.removeEventListener("mousemove", handleMouseMove);
			document.removeEventListener("mouseup", handleMouseUp);
		};

		document.addEventListener("mousemove", handleMouseMove);
		document.addEventListener("mouseup", handleMouseUp);
	};

	const toggleFullScreen = () => {
		setIsFullScreen(!isFullScreen);
	};

	if (!isOpen) return null;

	return (
		<div className="modal-overlay" onClick={onClose}>
			<div
				ref={modalRef}
				className={`modal ${isFullScreen ? "fullscreen" : ""}`}
				style={
					!isFullScreen
						? {
								width: dimensions.width,
								height: dimensions.height,
						  }
						: undefined
				}
				onClick={(e) => e.stopPropagation()}
			>
				<div className="modal-header">
					<h3>{title}</h3>
					<div className="modal-controls">
						<button onClick={toggleFullScreen}>
							{isFullScreen ? <IoContract /> : <IoExpand />}
						</button>
						<button onClick={onClose}>
							<IoClose />
						</button>
					</div>
				</div>
				<div className="modal-content">
					<div
						dangerouslySetInnerHTML={{
							__html: (text ?? "").replaceAll("\n", "<br />&nbsp;"),
						}}
					/>
					{children}
				</div>
				{!isFullScreen && (
					<>
						<div className="resize-handle e" onMouseDown={(e) => handleMouseDown(e, "e")} />
						<div className="resize-handle s" onMouseDown={(e) => handleMouseDown(e, "s")} />
						<div className="resize-handle se" onMouseDown={(e) => handleMouseDown(e, "se")} />
					</>
				)}
			</div>
		</div>
	);
};

export default Modal;
